import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../base/Button'
import GrayLogo from '../../../../assets/images/gray-logo.png'

import { MainContainer, Top, Status, Message, Image } from './PageError.style'

const PageError = ({ buttonHref, buttonText, logo, message, status }) => {
    return (
        <MainContainer>
            <Top>
                <Image src={logo} />
                <Status>{status} Error</Status>
            </Top>
            <Message>{message}</Message>
            <Button theme={'primary'} href={buttonHref}>
                {buttonText}
            </Button>
        </MainContainer>
    )
}

PageError.propTypes = {
    logo: PropTypes.node,
    buttonHref: PropTypes.string,
    buttonText: PropTypes.string,
    status: PropTypes.number,
    message: PropTypes.string,
}

PageError.defaultProps = {
    logo: GrayLogo,
    buttonHref: '/',
    buttonText: 'Go Back to Home',
    status: 404,
    message: "OOPS! The page you're looking for isn't here...",
}

export default PageError

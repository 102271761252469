import React, { forwardRef } from 'react'
import TextField from '../TextField'
import { COMMON_TEXTFIELD_PROPS, DEFAULT_TEXTFIELD_PROPS } from '../constants'

const InputStepperField = forwardRef((props, ref) => {
    const { step, min, max, ...restProps } = props

    DEFAULT_TEXTFIELD_PROPS.InputProps.inputProps.step = step
    DEFAULT_TEXTFIELD_PROPS.InputProps.inputProps.min = min
    DEFAULT_TEXTFIELD_PROPS.InputProps.inputProps.max = max

    return (
        <TextField
            ref={ref}
            type="number"
            {...restProps}
            {...DEFAULT_TEXTFIELD_PROPS}
            {...COMMON_TEXTFIELD_PROPS}
        />
    )
})

export default InputStepperField

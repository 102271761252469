import {
    FilterOperators,
    FilterTypeValue,
    FilterInterface,
    FieldFiltersInterface,
    FilterFieldType,
} from 'types';
import { nonenumerable } from 'helpers';
import { Filter } from './Filter';
import { defaultFilters } from '../data/filters';

export class FieldFilters implements FieldFiltersInterface {
    /** The name of the field this belongs to */
    field: string;

    /** An array of filters to apply */
    filters: Array<FilterInterface> = [];

    /** And/Or operator to use */
    filterOperator: FilterOperators = 'or';

    constructor(
        field: string,
        filters?: Array<FilterInterface>,
        filterOperator?: FilterOperators,
        filterType: FilterFieldType = 'text',
    ) {
        this.field = field;
        this.filters = filters
            ? [...filters]
            : [new Filter(defaultFilters?.[filterType])];
        this.filterOperator = filterOperator || 'or';
        nonenumerable(this, 'clone');
        nonenumerable(this, 'setField');
        nonenumerable(this, 'updateFilter');
        nonenumerable(this, 'updateFilterType');
        nonenumerable(this, 'addFilter');
        nonenumerable(this, 'updateFilterValue');
        nonenumerable(this, 'setOperator');
        nonenumerable(this, 'removeFilter');
    }

    /**
     * Instructions to make a new copy of this object
     * @param field
     * @param filters
     * @param filterOperator
     */
    clone = (
        field?: string,
        filters?: Array<FilterInterface>,
        filterOperator?: FilterOperators,
    ) =>
        new FieldFilters(
            field === undefined ? this.field : field,
            filters === undefined ? this.filters : filters,
            filterOperator === undefined ? this.filterOperator : filterOperator,
        );

    /**
     * Update the field this applies to.  This should not be commonly used
     * @param newField
     */
    setField = (newField: string) => this.clone(newField);

    /**
     * update a single filter
     * @param index
     * @param filter
     */
    updateFilter = (index: number, filter: FilterInterface) =>
        this.clone(undefined, [
            ...this.filters.slice(0, index),
            filter,
            ...this.filters.slice(index + 1),
        ]);

    /**
     * Update an existing filters type, can be used to add a new one
     * @param index
     * @param filterType
     */
    updateFilterType = (index: number, filterType: FilterTypeValue) => {
        // If there are no filters, create a new one
        if (this.filters.length === 0) {
            return this.clone(undefined, [new Filter(filterType)]);
        }
        // If the filter with this index doesn't exist, add a new one
        if (this.filters.length - 1 < index) {
            return this.clone(undefined, [
                ...this.filters,
                new Filter(filterType),
            ]);
        }
        const existingFilter = this.filters[index];
        // Otherwise update the existing filter
        return this.updateFilter(index, existingFilter.updateType(filterType));
    };

    /**
     * Update a specific filter's value
     * @param index
     * @param value
     */
    updateFilterValue = (index: number, value: string) => {
        // If there are no filters, create a new one
        if (this.filters.length === 0) {
            return this.clone(undefined, [new Filter('equal', value)]);
        }
        // If the filter with this index doesn't exist, add a new one
        if (this.filters.length - 1 < index) {
            return this.clone(undefined, [
                ...this.filters,
                new Filter('equal', value),
            ]);
        }
        const existingFilter = this.filters[index];
        // Otherwise update the existing filter
        return this.updateFilter(index, existingFilter.updateValue(value));
    };

    /**
     * Update the filter operator
     * @param filterOperator
     */
    setOperator = (filterOperator: FilterOperators) =>
        this.clone(undefined, undefined, filterOperator);

    /**
     * Add a new filter
     * @param filter
     */
    addFilter = (filter: FilterInterface) =>
        this.clone(undefined, [...this.filters, filter]);

    /**
     * Remove a filter with a specific index
     * @param index
     */
    removeFilter = (index: number) => {
        // If filter doesn't exist
        if (this.filters.length <= index - 1) {
            // Return copy of this filter
            return this.clone();
        }

        return this.clone(undefined, [
            ...this.filters.slice(0, index),
            ...this.filters.slice(index + 1),
        ]);
    };
}

import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridDefaultTextFilter extends AgGridBaseFilter {
    getFilter() {
        return AgGridFilterConstants.FILTER_AG_TEXT
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_AG_TEXT
    }
}

export default AgGridDefaultTextFilter

/**
 * AgGridDefaultTextFilter Params
 * To use pass AgGridFilterConstants.FILTER_AG_TEXT to the filter prop of the column definition
 *
 * This is AgGrids default text filter.
 * https://www.ag-grid.com/react-data-grid/filter-text/
 *
 */

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    FormLabel,
    RadioGroup as MaterialRadioGroup,
    FormHelperText,
} from '@mui/material'
import Radio from '../Radio/Radio'

const RadioGroup = forwardRef((props, ref) => {
    const {
        label,
        options,
        onChange,
        value,
        row,
        field,
        name,
        isThemed,
        error,
        helperText,
    } = props

    const onChangeRadio = (event) => {
        onChange && onChange(event.target.value)
    }

    if (!isThemed) {
        return <MaterialRadioGroup {...props} ref={ref} />
    }

    return (
        <Box>
            {!!label && (
                <FormLabel tek-label-for="radiogroup">{label}</FormLabel>
            )}

            <MaterialRadioGroup
                ref={ref}
                onChange={onChangeRadio}
                value={value}
                row={row}
                {...field}
                name={name}
            >
                {options.map((option) => (
                    <Radio
                        key={option.value}
                        value={option.value}
                        label={option.label}
                        tooltip={option.tooltip}
                        tooltipButton={option.tooltipButton}
                        disabled={!!option.disabled}
                        isThemed
                    />
                ))}
            </MaterialRadioGroup>
            {error && (
                <FormHelperText error={error}>{helperText}</FormHelperText>
            )}
        </Box>
    )
})

RadioGroup.propTypes = {
    label: PropTypes.node,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            checked: PropTypes.bool,
        })
    ),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    row: PropTypes.bool,
    field: PropTypes.object,
    isThemed: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
}

RadioGroup.defaultProps = {
    label: undefined,
    options: [],
    value: '',
    row: false,
    field: {},
    isThemed: false,
    error: false,
    helperText: '',
}

export default RadioGroup

import React from 'react'
import Highcharts from 'highcharts/highcharts-gantt'
import HighchartsReact from 'highcharts-react-official'
import { HighchartsTheme } from '../../themes'
import HighchartsConfig from '../HighchartsConfig'

HighchartsTheme(Highcharts)
HighchartsConfig(Highcharts)

function HighchartsGantt(props) {
    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={'ganttChart'}
            {...props}
        />
    )
}

export default HighchartsGantt

import ExcelCell from './ExcelCell'

class ExcelRow {
    constructor() {
        this.cells = []
        this.collapsed = false
        this.hidden = false
        this.height = null
        this.outlineLevel = null
    }

    addCell(cell) {
        this.cells.push(cell)
    }

    addCellWithValue(value) {
        let cell = new ExcelCell(value)
        this.addCell(cell)
    }

    addEmptyCell() {
        let cell = new ExcelCell(null)
        this.addCell(cell)
    }
}

export default ExcelRow

import React from 'react'
import { AppDrawer, HeaderLogo, Tabs, Menu } from './Components'
import { SearchComponent } from './Components/Search'
import { Profile } from './Components/Profile'
import AppHeaderDivider from './Components/Divider'

const ComponentFactory = ({ type, ...props }) => {
    switch (type) {
        case 'app_switcher':
            return <AppDrawer {...props} />
        case 'header_logo':
            return <HeaderLogo {...props} />
        case 'tabs':
            return <Tabs {...props} />
        case 'menu':
            return <Menu {...props} />
        case 'global_search':
            return <SearchComponent {...props} />
        case 'user_profile':
            return <Profile {...props} />
        case 'divider':
            return <AppHeaderDivider {...props} />
        default:
            return null
    }
}

export default ComponentFactory

import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import {
    VisuallyHiddenInput,
    Container,
    Dropzone,
    Content,
    P,
    MaxSize,
    FileList,
    ErrorContainer,
} from './styles'
import { FontAwesomeIcon, fas, far } from '../../fontAwesome'
import FileRow from './FileRow'

const UploadSection = forwardRef((props, ref) => {
    const {
        disabled,
        value: files,
        onChange,
        maxSize,
        field,
        accept,
        withoutSelect,
    } = props
    const [errors, setErrors] = useState({})
    const fileTypes = accept.length ? accept.join() : ''

    const handleOnChange = (newFiles) => {
        if (disabled) return
        if (!maxSize) {
            onChange([...files, ...newFiles])
            return
        }
        const filteredFiles = []
        let error = false
        newFiles.forEach((file) => {
            const filesize = (file.size / 1024 / 1024).toFixed(4) // MB
            if (filesize < maxSize) {
                filteredFiles.push(file)
            } else {
                console.error(`File ${file.name} exceeded the maximum size`)
                error = true
            }
        })
        setErrors({ ...errors, fileSize: error })
        onChange([...files, ...filteredFiles])
    }

    const removeFile = (index) => {
        const filteredList = Array.from(files).filter(
            (_, filterIndex) => filterIndex !== index
        )
        onChange(filteredList.length ? filteredList : [])
    }
    // this is use in case of styling on drag
    const [_, setDragIsOver] = useState(false)

    // Define the event handlers
    const handleDragOver = (event) => {
        event.preventDefault()
        setDragIsOver(true)
    }

    const handleDragLeave = (event) => {
        event.preventDefault()
        setDragIsOver(false)
    }

    const handleDrop = (event) => {
        event.preventDefault()
        setDragIsOver(false)
        // Add to files
        handleOnChange([...event.dataTransfer.files])
    }

    return (
        <div>
            <Container
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <Dropzone>
                    <Content>
                        <FontAwesomeIcon
                            style={{ fontSize: '24px' }}
                            color={'#007899'}
                            icon={fas.faFileArrowUp}
                        />
                        <P>Drag and drop files here</P>
                        {!withoutSelect && (
                            <>
                                <P>or</P>
                                <Button
                                    {...field}
                                    ref={ref}
                                    component="label"
                                    variant="contained"
                                    disabled={disabled}
                                    sx={{
                                        padding: '8px 16px',
                                        textTransform: 'none',
                                    }}
                                >
                                    Select file
                                    <VisuallyHiddenInput
                                        type="file"
                                        id="files-upload"
                                        multiple
                                        accept={fileTypes}
                                        onChange={(event) => {
                                            handleOnChange([
                                                ...event.target.files,
                                            ])
                                        }}
                                    />
                                </Button>
                            </>
                        )}
                        {maxSize ? (
                            <MaxSize>Maximum file size is {maxSize}MB</MaxSize>
                        ) : null}
                    </Content>
                </Dropzone>
                <FileList>
                    {files.map((f, index) => (
                        <FileRow
                            key={index}
                            file={f}
                            index={index}
                            onDelete={removeFile}
                            onError={setErrors}
                        />
                    ))}
                </FileList>
                {errors.fileSize && (
                    <ErrorContainer>
                        <P style={{ fontWeight: 'normal', color: '#D73848' }}>
                            Error: File size capacity exceeded
                        </P>
                    </ErrorContainer>
                )}
            </Container>
        </div>
    )
})

UploadSection.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    maxSize: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    accept: PropTypes.arrayOf(PropTypes.string),
    withoutSelect: PropTypes.bool,
}

UploadSection.defaultProps = {
    value: [],
    maxSize: undefined,
    disabled: false,
    accept: [
        '.pdf',
        '.docx',
        '.doc',
        '.xlsx',
        '.csv',
        '.xlsm',
        '.xls',
        '.xltm',
    ],
    withoutSelect: false,
}

export default UploadSection

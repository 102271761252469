/**
 * @param text
 * @param numberOfWords
 * @returns {string}
 */
const firstNWords = (text, numberOfWords) => {
    const wordArray = text.split(' ')
    const slicedWordArray = wordArray.slice(0, numberOfWords)

    return slicedWordArray.join(' ')
}

export default firstNWords

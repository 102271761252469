import React, { useContext, useState } from 'react'
import { Grid, IconButton, Divider, Avatar, Link } from '@mui/material'
import AppDrawer from '../AppDrawer'
import SearchIcon from '@mui/icons-material/Search'
import { mockNavLinks, mockAppLinks, mockHelpLinks } from '../AppHeader-Data'
import UserProfile from '../UserProfile'
import QuickAddMenu from './../MenuButtonDropdown/QuickAddMenu'
import { useAppHeader, useUser } from '../data-provider'

function SmallScreenView(props) {
    const { companyLogo } = useAppHeader()
    const {
        openModal,
        setOpenModal,
        handleCloseModal,
        handleOpenModal,
        searchTerm,
        setSearchTerm,
        open,
        openProfile,
        addMenuObject,
        anchorEl,
        anchorProfileEl,
        anchorElSearch,
        setAnchorElSearch,
        openSearchModal,
        setOpenSearchModal,
        handleCloseSearch,
        handleCloseSearchModal,
        handleClick,
        id,
        handleClose,
        numberData,
        navLinks,
        appLinks,
        helpLinks,
        handleProfileMenuOpen,
        user,
        initials,
    } = props

    const { isExternalUser } = useUser()

    const [currentModal, setCurrentModal] = useState(null)
    const onInputChange = (event) => {
        setSearchTerm(event.target.value)
    }
    return (
        <Grid container alignItems="center">
            <Grid item xs={1}>
                <AppDrawer
                    navLinks={navLinks || mockNavLinks}
                    appLinks={appLinks || mockAppLinks}
                    helpLinks={helpLinks || mockHelpLinks}
                    handleCloseSearch={handleCloseSearch}
                />
            </Grid>
            <Grid item xs={2}>
                <Link href={'/'} title="Home">
                    <img
                        src={companyLogo}
                        style={{ width: '100%', height: '100%' }}
                        alt="SPI Logo"
                    />
                </Link>
            </Grid>
            <Grid item xs={3} alignItems="center" justifyContent="flex-end">
                <IconButton>
                    <SearchIcon />
                </IconButton>
            </Grid>

            <Grid
                item
                xs={6}
                container
                alignItems="center"
                justifyContent="flex-end"
            >
                <Grid
                    item
                    xs={4}
                    onMouseLeave={() => {
                        handleClick(false)
                    }}
                >
                    {!isExternalUser && (
                        <QuickAddMenu
                            id={id}
                            addMenuObject={addMenuObject}
                            open={open}
                            anchorEl={anchorEl}
                            handleClick={handleClick}
                        ></QuickAddMenu>
                    )}
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Grid
                    item
                    xs={6}
                    sx={{
                        direction: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        sx={{ color: '#444' }}
                    >
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                fontSize: '14px',
                            }}
                        >
                            {initials}
                        </Avatar>
                    </IconButton>
                    <UserProfile
                        id={id}
                        anchorProfileEl={anchorProfileEl}
                        openProfile={openProfile}
                        handleClose={handleClose}
                        user={user}
                        initials={initials}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

SmallScreenView.propTypes = {}

export default SmallScreenView

import styled from 'styled-components'

export const MainContainer = styled.div`
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-size: 2em;
    font-weight: 600;
    color: #445460;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const Top = styled.div`
    display: flex;
    align-items: center;
`

export const Status = styled.h1`
    margin: 0 0 0 0.5em;
    line-height: 0;
`

export const Message = styled.div`
    max-width: 640px;
    font-size: 0.75em;
    margin-top: 0.75em;
    margin-bottom: 1em;
    text-align: center;
`

export const Image = styled.img`
    width: 100%;
    height: 100%;
    max-width: 142px;
    max-height: 142px;
`

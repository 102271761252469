import React, { useEffect, useRef, useState } from 'react'

import MenuColumn from './MenuColumn'
import { Button, Paper, Popper } from '../../../../../../base'
import { FontAwesomeIcon, fas } from '../../../../../../fontAwesome'
import {
    APP_COLORS,
    COLOR_NAMES,
    HOVER_COLOR_NAMES,
} from '../../../../../../themes'
import { useFunction, useRedirect } from '../../../projects'

const IconMap = {
    plus_icon: fas.faPlusCircle,
    question_mark_icon: fas.faCircleQuestion,
    default: fas.faPlusCircle,
}

const MenuDropdownButton = ({
    title,
    columns,
    icon,
    open,
    handleOpen,
    handleClose,
    type,
    columnSize,
    isSingle,
}) => {
    const [hoverButton, setHoverButton] = useState(false)
    const [hoverMenu, setHoverMenu] = useState(false)

    useEffect(() => {
        if (open && !hoverButton && !hoverMenu) handleClose()
    }, [hoverButton, hoverMenu, open])

    const anchorRef = useRef(null)
    const singleStyles = {
        minWidth: '32px',
    }
    const Icon = IconMap[icon] || IconMap.default
    const popperWidth = columnSize * columns.length + 32
    return (
        <>
            <Button
                ref={anchorRef}
                id={`menu-button-${title}`}
                aria-controls={open ? `menu-button-${title}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onMouseLeave={() => {
                    setHoverButton(false)
                }}
                onMouseEnter={() => {
                    handleOpen(title)
                    setHoverButton(true)
                }}
                sx={[
                    {
                        borderRadius: '0',
                        padding: '8px 16px',
                        borderBottom: `2px solid ${COLOR_NAMES.white}`,
                        color: '#333333',
                        textTransform: 'capitalize',
                        height: '56px',
                        fontSize: '16px',
                    },
                    open && {
                        backgroundColor: HOVER_COLOR_NAMES.secondaryGray,
                        borderBottom: `2px solid ${APP_COLORS.primary_border}`,
                        color: APP_COLORS.primary_border,
                    },
                    isSingle && singleStyles,
                ]}
            >
                {type === 'text' ? title : <FontAwesomeIcon icon={Icon} />}
            </Button>
            <Popper
                placement="bottom-start"
                sx={{
                    position: 'fixed',
                    zIndex: 1300,
                }}
                id={`menu-button-${title}`}
                open={open}
                anchorEl={anchorRef.current}
                onMouseLeave={() => {
                    setHoverMenu(false)
                }}
                onMouseEnter={() => {
                    setHoverMenu(true)
                }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        padding: '10px 16px',
                        width: `${popperWidth}px`,
                        background: COLOR_NAMES.white,
                        borderRadius: '0',
                        boxShadow: '0 0 8px 0 rgba(51, 51, 51, 0.2)',
                    }}
                >
                    {columns.map((column, index) => (
                        <MenuColumn
                            key={`column-${index}`}
                            content={column}
                            columnSize={columnSize}
                        />
                    ))}
                </Paper>
            </Popper>
        </>
    )
}

const MenuTab = (props) => {
    const {
        title,
        icon,
        type = 'text',
        columns = [],
        columnSize = 100,
        handleOpen,
        handleClose,
        open = false,
        isSingle = false,
        href,
        onClick,
    } = props
    const redirect = useRedirect()
    const { execute } = useFunction(onClick)
    const handeClick = (event) => {
        if (onClick) {
            return execute(event, props)
        }
        return redirect(href)
    }

    if (type === 'button') {
        return (
            <Button
                onClick={handeClick}
                sx={{
                    borderRadius: '0',
                    padding: '8px 16px',
                    borderBottom: `2px solid ${COLOR_NAMES.white}`,
                    color: COLOR_NAMES.black,
                    textTransform: 'capitalize',
                    height: '56px',
                    fontSize: '16px',
                }}
            >
                {title}
            </Button>
        )
    }

    return (
        <MenuDropdownButton
            title={title}
            columns={columns}
            icon={icon}
            columnSize={columnSize}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            isSingle={isSingle}
            type={type}
        />
    )
}

export default MenuTab

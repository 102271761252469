import { APP_PALETTE } from './SsgTheme'

function HighchartsTheme(Highcharts) {
    Highcharts.theme = {
        colors: APP_PALETTE.ssg,
    }

    Highcharts.setOptions(Highcharts.theme)
}

export default HighchartsTheme

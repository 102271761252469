import React, { Component } from 'react'
import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridFilterModelBuilder from '../../utils/models/AgGridFilterModelBuilder'
import { InputTextField } from '../../../../base'

class TextFilter extends Component {
    constructor(props) {
        super(props)

        this.debounce = props.debounceMs ?? 500
        this.timeout = null
        this.placeholder = props.placeholder ?? ''

        this.state = {
            value: '',
        }
    }

    isFilterActive = () => {
        const filterActive =
            this.state.value !== null &&
            this.state.value !== undefined &&
            this.state.value !== ''

        return filterActive
    }

    doesFilterPass = (params) => {
        const filterPassed =
            this.state.value === null ||
            this.state.value === undefined ||
            this.state.value === '' ||
            params.value === this.state.value

        return this.state.selectedValue === params.value
    }

    getModel = () => {
        if (this.state.value === '') return undefined

        const modelBuilder = new AgGridFilterModelBuilder()

        modelBuilder.setFilterType(AgGridFilterConstants.CONDITION_CONTAINS)
        modelBuilder.setType(AgGridFilterConstants.FILTER_TYPE_TEXT)
        modelBuilder.setFilter(this.state.value)

        return modelBuilder.build()
    }

    setModel = (model, param1, param2) => {
        if (model === null) {
            this.setValue('')
            return
        }
    }

    setValue(value) {
        this.setState(
            {
                value,
            },
            () => this.props.filterChangedCallback()
        )
    }

    onChange = (event) => {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            this.setValue(event.target.value)
        }, this.debounce)
    }

    render = () => {
        return (
            <InputTextField
                onChange={this.onChange}
                placeholder={this.placeholder}
            />
        )
    }
}

export default TextFilter

import { Comparer, Sorter, FiltersType, Filterer, SortType } from 'types';
import { checkPasses } from './checkPasses';

export type BuildComparerResponse = [Filterer, Sorter];

/**
 * Builds a filter/sort function using filters/sort inputs
 *
 * @param filters
 * @param sort
 * @param customSorter
 * @param getComparisonValue
 */
export const buildComparer = (
    filters: FiltersType,
    sort: SortType,
    customSorter?: Sorter,
    getComparisonValue?: Comparer,
): BuildComparerResponse => {
    const comparison = (valueType, record) => {
        if (getComparisonValue) {
            return getComparisonValue(valueType, record);
        }

        return Object.hasOwnProperty.call(record, valueType)
            ? record[valueType]
            : undefined;
    };

    const sortData = (recordA, recordB) => {
        const { by, order } = sort;
        const comparisonA = comparison(by, recordA)?.toString() || '';
        const comparisonB = comparison(by, recordB)?.toString() || '';

        return order === 'asc'
            ? comparisonA.localeCompare(comparisonB)
            : comparisonB.localeCompare(comparisonA);
    };

    const filterData = (record: any) => {
        for (const filterKey in filters) {
            if (!Object.hasOwnProperty.call(filters, filterKey)) {
                // eslint-disable-next-line no-continue
                continue;
            }

            const { filterOperator, filters: theseFilters } = filters[
                filterKey
            ];
            const comparisonValue = comparison(filterKey, record);

            let somePass = false;
            for (const thisFilter of theseFilters) {
                const passes = checkPasses(comparisonValue, thisFilter);
                if (passes) {
                    somePass = true;
                } else if (filterOperator === 'and') {
                    // not passes and filter is and, then it doesn't pass
                    return false;
                }
            }
            if (filterOperator === 'or' && !somePass) {
                return false;
            }
        }

        return true;
    };

    return [filterData, customSorter || sortData];
};

import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { APP_THEME } from './SsgTheme'

const SsgThemeProvider = ({ children, theme }) => {
    const defaultTheme = theme ? theme : APP_THEME

    return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}

export default SsgThemeProvider

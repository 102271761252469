import PropTypes from 'prop-types'

// Person
export const departmentPropTypes = PropTypes.shape({
    DeptID: PropTypes.number.isRequired,
    Dept_Name: PropTypes.string.isRequired,
    dept_shortname: PropTypes.string.isRequired,
})

const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

export const strategyPropTypes = PropTypes.shape({
    coinvest_email: PropTypes.string,
    secondaries_email: PropTypes.string,
    sort_index: PropTypes.number,
    strat_full_alt: PropTypes.string,
    strat_full_desc: PropTypes.string.isRequired,
    strat_id: PropTypes.number.isRequired,
    strat_short: PropTypes.string.isRequired,
    strat_short_alt: PropTypes.string.isRequired,
})

export const lookupResearchDistributionPropType = PropTypes.shape({
    research_dist: PropTypes.string,
    research_dist_id: PropTypes.number.isRequired,
    sort_order: PropTypes.number,
    strat_id: PropTypes.number,
})

export const careerPropTypes = PropTypes.shape({
    department: departmentPropTypes,
    email_addr: PropTypes.string,
    job_title: PropTypes.string,
    back_office: PropTypes.bool,
    created_by: PropTypes.number,
    created_date_gmt: PropTypes.string,
    current_career: PropTypes.oneOf([0, 1, 2]),
    dept_desc: PropTypes.string,
    dept_id: PropTypes.number,
    end_date: PropTypes.string,
    fax_direct: PropTypes.string,
    gp_id: PropTypes.number,
    gp_office_id: PropTypes.number,
    insider: PropTypes.number,
    inst_id: PropTypes.number,
    inst_office_id: stringOrNumber,
    institution: PropTypes.object,
    institution_office: PropTypes.object,
    is_primary: PropTypes.bool,
    lookup_timezone: PropTypes.object,
    modified_by: PropTypes.number,
    modified_date_gmt: PropTypes.string,
    other_org: PropTypes.string,
    partner: PropTypes.bool,
    person: PropTypes.object,
    person_career_id: PropTypes.number,
    person_id: PropTypes.number,
    phone_direct: PropTypes.string,
    research_dist: PropTypes.array,
    research_dists: PropTypes.arrayOf(lookupResearchDistributionPropType),
    start_date: PropTypes.string,
    strategies: PropTypes.arrayOf(strategyPropTypes),
    tz_id: PropTypes.number,
})

export const personPropTypes = PropTypes.shape({
    person_id: PropTypes.number,
    current_career: PropTypes.arrayOf(careerPropTypes),
    prefix: PropTypes.string,
    first_name: PropTypes.string,
    alias: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    suffix: PropTypes.string,
    gender: PropTypes.string,
    birth_year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    education: PropTypes.string,
    prior_position: PropTypes.string,
})

export const MISSING_THEME_PARAM = `Missing theme param. 
Provide one of the following values through the "theme" param:
- primary
- secondary
`

export const INVALID_THEME_VALUE = `Invalid theme value.`

export const VALID_THEMES = ['primary', 'secondary']

export const MORE_THAN_ONE_ACTIONABLE_ITEMS =
    'More than one actionable was provided'

export const MISSING_ACTIONABLE_ITEMS = `Missing actionable method param. 
Provide one of the following params
- label
- icon
`

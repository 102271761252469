import { Typography, Avatar, Link, MenuItem } from '@mui/material'
import styled from 'styled-components'

export const CommentHeaderStyled = styled(Typography)`
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-size: 16px !important;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
`

export const CommentsWrapper = styled.div`
    padding: 12px 24px;
    max-height: 500px;

    // START: Invisible scroll bar
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }
    // END: Invisible scroll bar
`

export const CommentsHeader = styled.div`
    display: flex;
    height: 48px;
    text-align: center;
    align-items: center;
    padding: 0px 24px;
    border-bottom: 1px solid #e1e1e1;
    justify-content: space-between;
`

export const CommenterAvatar = styled(Avatar)`
    color: #007899;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    height: 30px;
    width: 30px;
    font-size: 12px;
`

export const Comment = styled.div`
    display: grid;
    grid-template-columns: 1fr 80% 1fr;
    align-items: center;

    &:not(:last-of-type) {
        margin-bottom: 1em;
    }

    // Timestamp & Comment
    > p {
        grid-column: 2 / 3;
        white-space: pre-line;
    }

    // Timestamp
    > p:first-of-type {
        margin-bottom: 0.25em;
    }

    // Commenter link, timestamp, comment
    > a,
    > p {
        margin-left: 0.5em;
    }
`

export const CommenterLink = styled(Link)`
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    text-decoration: none;
    color: #007899;
    font-size: 12px;
`

export const MenuItemStyled = styled(MenuItem)`
    font-size: 12px;
`

export const StyledAvatar = styled(Avatar)`
    height: 30px;
    width: 30px;
    font-size: 12px;
    background-color: #007899;
`

export const CommentBoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;

    // Avatar
    > div:nth-child(1) {
        margin-right: 0.5em;
    }

    // Comment Box
    > div:nth-child(2) {
        flex-grow: 1;
    }
`

export const CommentBoxButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2.5em;

    > button:first-child {
        margin-right: 0.5em;
    }
`

import React from 'react'

import { Grid } from '../../../../../../base'
import MenuSection from './MenuSection'

const MenuColumn = ({ content, columnSize }) => {
    if (content.length <= 0) {
        // if there is no content
        return null
    }

    return (
        <Grid
            container
            item
            direction="column"
            sx={{ width: `${columnSize}px` }}
        >
            {content.map(({ title, links }, index) => (
                <Grid container item direction="row" key={`section-${index}`}>
                    <MenuSection title={title} content={links} />
                </Grid>
            ))}
        </Grid>
    )
}

export default MenuColumn

import React from 'react'
import PropTypes from 'prop-types'
import { generalPartnerPropType } from './GpTypes'
import { Grid } from '@mui/material'
import {
    EntityTypeText,
    LinkWrapper,
    EntityDescText,
    LinkStyled,
} from '../styles'

function GpGridDisplay({ gp, includeType, gpParent }) {
    if (!gp) {
        return <></>
    }
    const {
        asset_class: lookUpassetClass,
        gp_invest_country: gpInvestCountry,
    } = gp
    const { Asset_Class: assetClass } = lookUpassetClass
    const { country } = gpInvestCountry[0] || {}
    const { CountryCode: countryCode, Country: countryName } = country || {}
    const flagPath = `/images/flag/${countryCode}.png`

    let gpId = null
    let gpName = null
    if (gpParent) {
        gpId = gp.GeneralPartnerID
        gpName = gpParent.gp_parent_name
    } else {
        gpId = gp.GeneralPartnerID
        gpName = gp.GP_Name
    }

    return (
        <Grid container>
            <LinkWrapper item container xs={9}>
                {includeType && <EntityTypeText>GP: </EntityTypeText>}
                <LinkStyled
                    href={`/gp_profile.php?id=${gpId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Go to GP Profile (GP ID ${gpId})`}
                >
                    {gpName}
                </LinkStyled>
            </LinkWrapper>
            <Grid item container xs={3} justifyContent="flex-end">
                <div style={{ display: 'flex' }}>
                    <img
                        alt=""
                        src={flagPath}
                        title={countryName}
                        style={{ width: '20px' }}
                    />
                </div>
                <EntityDescText> ({assetClass})</EntityDescText>
            </Grid>
        </Grid>
    )
}

GpGridDisplay.propTypes = {
    gp: generalPartnerPropType,
    includeFlag: PropTypes.bool,
    includeType: PropTypes.bool,
    includeAssetClass: PropTypes.bool,
}

GpGridDisplay.defaultProps = {
    gp: null,
    includeFlag: true,
    includeType: true,
    includeAssetClass: true,
}

export default GpGridDisplay

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Radio as MaterialRadio, FormControlLabel } from '@mui/material'
import Tooltip from '../../base/Tooltip'
import TooltipDialog from '../../stepstone/TooltipDialog/TooltipDialog'
import { FontAwesomeIcon, fas } from '../../fontAwesome'
import { COLOR_NAMES } from '../../themes/SsgTheme'

const Radio = forwardRef((props, ref) => {
    const {
        checked,
        disabled,
        onChange,
        label,
        value,
        isThemed,
        tooltip,
        tooltipButton,
    } = props

    if (!isThemed) {
        return <MaterialRadio {...props} ref={ref} />
    }

    let LabelToolTip
    if (tooltip) {
        LabelToolTip = (
            <Tooltip title={tooltip} placement="right-start">
                <FontAwesomeIcon
                    icon={fas.faCircleInfo}
                    color={COLOR_NAMES.blue}
                    style={{
                        fontSize: '14px',
                        marginLeft: '4px',
                        verticalAlign: 'sub',
                    }}
                />
            </Tooltip>
        )
    } else if (tooltipButton) {
        LabelToolTip = (
            <TooltipDialog
                title={tooltipButton.title}
                icon={tooltipButton.icon}
                size={tooltipButton.size}
                buttons={tooltipButton.buttons}
            >
                {tooltipButton.bodyContent}
            </TooltipDialog>
        )
    }

    return (
        <FormControlLabel
            tek-label-for="radio"
            ref={ref}
            value={value}
            label={
                <>
                    {label} {LabelToolTip}
                </>
            }
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            control={<MaterialRadio />}
        />
    )
})

Radio.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.node,
    value: PropTypes.string,
    isThemed: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipButton: PropTypes.object,
}

Radio.defaultProps = {
    checked: undefined,
    disabled: undefined,
    onChange: undefined,
    label: undefined,
    value: undefined,
    isThemed: false,
    tooltip: undefined,
    tooltipButton: undefined,
}

export default Radio

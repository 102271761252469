import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { Button } from '../../base'
import LoadingContainer from '../layouts/LoadingContainer/LoadingContainer'
import {
    DiaglogContentStyled,
    DialogActionsStyled,
    DialogContentTextStyled,
    DialogTitleStyled,
    DiaglogPaperStyle,
} from './styles'
import { ConfirmationModalPropTypes } from './types'

const ConfirmationModal = (props) => {
    const {
        open,
        onClose,
        title,
        description,
        onSubmit,
        diaglogProps,
        dialogContentProps,
        diaglogTitleProps,
        diaglogContentTextProps,
        dialogActionsProps,
        secondaryButtonText,
        primaryButtonText,
    } = props

    const [loading, setLoading] = useState(false)

    const handleOnSubmit = async () => {
        setLoading(true)
        try {
            await onSubmit()
            setLoading(false)
            onClose()
        } catch {
            setLoading(false)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: DiaglogPaperStyle,
            }}
            {...diaglogProps}
        >
            <LoadingContainer loading={loading}>
                <DiaglogContentStyled {...dialogContentProps}>
                    {title && (
                        <DialogTitleStyled {...diaglogTitleProps}>
                            {title}
                        </DialogTitleStyled>
                    )}
                    <DialogContentTextStyled {...diaglogContentTextProps}>
                        {description}
                    </DialogContentTextStyled>
                </DiaglogContentStyled>
                <DialogActionsStyled {...dialogActionsProps}>
                    <Button onClick={onClose} theme="secondary" size="medium">
                        {secondaryButtonText}
                    </Button>
                    <Button
                        onClick={handleOnSubmit}
                        theme="primary"
                        size="medium"
                    >
                        {primaryButtonText}
                    </Button>
                </DialogActionsStyled>
            </LoadingContainer>
        </Dialog>
    )
}

ConfirmationModal.propTypes = ConfirmationModalPropTypes

ConfirmationModal.defaultProps = {
    diaglogProps: {},
    dialogContentProps: {},
    diaglogTitleProps: {},
    diaglogContentTextProps: {},
    dialogActionsProps: {},
    open: false,
    onClose: () => {},
    onSubmit: () => {},
    title: null,
    description: '',
    secondaryButtonText: 'Cancel',
    primaryButtonText: 'Confirm',
}

export default ConfirmationModal

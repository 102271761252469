import React, { useState, forwardRef } from 'react'
import { AppBar, useMediaQuery } from '@mui/material'
import LargeScreenView from './screenViews/LargeScreenView'
import SmallScreenView from './screenViews/SmallScreenView'
import MediumScreenView from './screenViews/MediumScreenView'
import { AppHeaderProvider, UserProvider } from './data-provider'
import { SsgThemeProvider, APP_THEME as theme } from '../../../themes'
import CompanyLogo from '../../../../assets/images/spi_research_logo.svg'
import AppLogo from '../../../../assets/images/stepstone.png'

const AppHeader = forwardRef((props, ref) => {
    const {
        navLinks,
        appLinks,
        helpLinks,
        user,
        searchResults,
        setSearchResults,
        searchTerm,
        setSearchTerm,
        resultsFilter,
        setResultsFilter,
        menuData,
        updateResultSwitch,
        setUpdateResultSwitch,
        isExternalUser,
        loading,
        isComponentVisible,
        setIsComponentVisible,
        userRedirectURL = '/people',
        companyLogo = CompanyLogo,
        appLogo = AppLogo,
    } = props

    const [openModal, setOpenModal] = useState(false)
    const [openModalMOF, setOpenModalMOF] = useState(false)
    const [openSearchModal, setOpenSearchModal] = useState(false)

    // Anchor state to adjust popups/menu dropdown
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorProfileEl, setAnchorProfileEl] = useState(null)
    const [anchorHelpEl, setAnchorHelpEl] = useState(null)
    const [anchorElSearch, setAnchorElSearch] = useState(null)

    const largeScreens = useMediaQuery('(min-width:1290px)')
    const mediumScreens = useMediaQuery(
        '(min-width: 750px) and (max-width: 1289px)'
    )
    const smallScreens = useMediaQuery('(max-width:749px)')

    const [openSearch, setOpenSearch] = useState(false)
    const openProfile = Boolean(anchorProfileEl)
    const isMenuOpen = Boolean(anchorEl)
    const isHelpOpen = Boolean(anchorHelpEl)

    const id = 'add-popover'

    const handleMenuOpen = (value, event, clickCall) => {
        const show = (clickCall && !isMenuOpen) || (!clickCall && value)
        if (show) {
            setAnchorEl(event.currentTarget)
        } else {
            setAnchorEl(null)
        }
    }

    const handleHelpMenuOpen = (value, event, clickCall) => {
        const show = (clickCall && !isHelpOpen) || (!clickCall && value)
        if (show) {
            setAnchorHelpEl(event.currentTarget)
        } else {
            setAnchorHelpEl(null)
        }
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorProfileEl(event.currentTarget)
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleOpenModalMOF = () => setOpenModalMOF(true)
    const handleCloseModal = () => setOpenModal(false)
    const handleCloseModalMOF = () => setOpenModalMOF(false)
    const handleCloseSearchModal = () => setCloseSearchModal(true)

    const handleClose = () => {
        setAnchorEl(false)
        setAnchorProfileEl(false)
    }

    const handleCloseSearch = () => {
        setSearchTerm('')
        setOpenSearch(false)
        setIsComponentVisible(false)
        setAnchorElSearch(null)
        setSearchResults(null)
    }

    const initials = user
        ? `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
        : ''

    return (
        <SsgThemeProvider>
            <AppHeaderProvider value={{ appLogo }}>
                <UserProvider value={{ isExternalUser, userRedirectURL }}>
                    <AppBar
                        position="relative"
                        style={{ zIndex: theme.zIndex.drawer + 1 }}
                        sx={{
                            backgroundColor: '#fff',
                            zIndex: theme.zIndex.drawer + 1,
                            position: 'relative',
                        }}
                        {...props.appBar}
                    >
                        {largeScreens ? (
                            <>
                                <LargeScreenView
                                    ref={ref}
                                    openModal={openModal}
                                    handleCloseModal={handleCloseModal}
                                    handleOpenModal={handleOpenModal}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                    searchResults={searchResults}
                                    resultsFilter={resultsFilter}
                                    setResultsFilter={setResultsFilter}
                                    updateResultSwitch={updateResultSwitch}
                                    setUpdateResultSwitch={
                                        setUpdateResultSwitch
                                    }
                                    handleCloseSearch={handleCloseSearch}
                                    anchorEl={anchorEl}
                                    anchorProfileEl={anchorProfileEl}
                                    open={isMenuOpen}
                                    handleClick={handleMenuOpen}
                                    addMenuObject={menuData}
                                    openSearch={openSearch}
                                    setOpenSearch={setOpenSearch}
                                    openProfile={openProfile}
                                    handleProfileMenuOpen={
                                        handleProfileMenuOpen
                                    }
                                    id={id}
                                    handleClose={handleClose}
                                    appLinks={appLinks}
                                    helpLinks={helpLinks}
                                    navLinks={navLinks}
                                    user={user}
                                    initials={initials}
                                    loading={loading}
                                    isComponentVisible={isComponentVisible}
                                    setIsComponentVisible={
                                        setIsComponentVisible
                                    }
                                    isHelpOpen={isHelpOpen}
                                    handleHelpMenuOpen={handleHelpMenuOpen}
                                    anchorHelpEl={anchorHelpEl}
                                />
                            </>
                        ) : null}
                        {mediumScreens ? (
                            <>
                                <MediumScreenView
                                    ref={ref}
                                    openModal={openModal}
                                    handleCloseModal={handleCloseModal}
                                    handleOpenModal={handleOpenModal}
                                    anchorEl={anchorEl}
                                    anchorProfileEl={anchorProfileEl}
                                    open={isMenuOpen}
                                    openSearch={openSearch}
                                    setOpenSearch={setOpenSearch}
                                    openProfile={openProfile}
                                    handleClick={handleMenuOpen}
                                    handleProfileMenuOpen={
                                        handleProfileMenuOpen
                                    }
                                    id={id}
                                    handleClose={handleClose}
                                    appLinks={appLinks}
                                    helpLinks={helpLinks}
                                    navLinks={navLinks}
                                    addMenuObject={menuData}
                                    user={user}
                                    initials={initials}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                    searchResults={searchResults}
                                    setSearchResults={setSearchResults}
                                    resultsFilter={resultsFilter}
                                    setResultsFilter={setResultsFilter}
                                    updateResultSwitch={updateResultSwitch}
                                    setUpdateResultSwitch={
                                        setUpdateResultSwitch
                                    }
                                    handleCloseSearch={handleCloseSearch}
                                    loading={loading}
                                    isComponentVisible={isComponentVisible}
                                    setIsComponentVisible={
                                        setIsComponentVisible
                                    }
                                />
                            </>
                        ) : null}
                        {smallScreens ? (
                            <>
                                <SmallScreenView
                                    openModal={openModal}
                                    setOpenModal={setOpenModal}
                                    openModalMOF={openModalMOF}
                                    setOpenModalMOF={setOpenModalMOF}
                                    openSearchModal={openSearchModal}
                                    setOpenSearchModal={setOpenSearchModal}
                                    handleCloseModal={handleCloseModal}
                                    handleCloseModalMOF={handleCloseModalMOF}
                                    handleOpenModal={handleOpenModal}
                                    handleOpenModalMOF={handleOpenModalMOF}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                    anchorEl={anchorEl}
                                    anchorProfileEl={anchorProfileEl}
                                    open={isMenuOpen}
                                    openProfile={openProfile}
                                    anchorElSearch={anchorElSearch}
                                    setAnchorElSearch={setAnchorElSearch}
                                    handleCloseSearch={handleCloseSearch}
                                    handleCloseSearchModal={
                                        handleCloseSearchModal
                                    }
                                    handleClick={handleMenuOpen}
                                    handleProfileMenuOpen={
                                        handleProfileMenuOpen
                                    }
                                    id={id}
                                    handleClose={handleClose}
                                    appLinks={appLinks}
                                    helpLinks={helpLinks}
                                    navLinks={navLinks}
                                    addMenuObject={menuData}
                                    user={user}
                                    initials={initials}
                                />
                            </>
                        ) : null}
                    </AppBar>
                </UserProvider>
            </AppHeaderProvider>
        </SsgThemeProvider>
    )
})

export default AppHeader

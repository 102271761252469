import React, { forwardRef } from 'react'
import { FormProvider as RHFProvider } from 'react-hook-form'
import Form from './Form'

const FormProvider = forwardRef((props, ref) => {
    const { useFormMethods, onSubmit, children } = props

    return (
        <RHFProvider {...useFormMethods} onSubmit={onSubmit}>
            <Form ref={ref}>{children}</Form>
        </RHFProvider>
    )
})

export default FormProvider

import React, { forwardRef } from 'react'
import { Box as MaterialUIBox, styled } from '@mui/material'

const TekBox = styled(MaterialUIBox, {
    name: 'MuiBox',
    overridesResolver: (_, styles) => styles.root,
})()

const Box = forwardRef(({ children, ...props }, ref = null) => {
    return (
        <TekBox ref={ref} {...props}>
            {children}
        </TekBox>
    )
})

export default Box

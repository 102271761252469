import React from 'react';
import { CircularProgress, TableRow, TableCell } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
    children?: React.ReactNode;
    colSpan?: number;
    loading?: boolean;
    noData: boolean;
    noDataMessage?: string;
    noDataStyle?: {
        [key: string]: string | number;
    };
    size?: number;
    thickness?: number;
}

export const MaterialDataGridLoader = ({
    children = '',
    colSpan = 1,
    loading = false,
    noData,
    noDataMessage = 'No Data',
    noDataStyle,
    size = 100,
    thickness = 2,
}: Props) => {
    const classes = useStyles();

    if (!loading) {
        return noData ? (
            <TableRow>
                <TableCell
                    colSpan={colSpan}
                    className={classes.noDataTd}
                    style={noDataStyle}
                >
                    {noDataMessage}
                </TableCell>
            </TableRow>
        ) : (
            children
        );
    }

    return (
        <TableRow>
            <TableCell
                colSpan={colSpan}
                align='center'
                className={classes.loaderCell}
            >
                <CircularProgress
                    color='primary'
                    size={size}
                    thickness={thickness}
                    key={`${size}-${thickness}`}
                />
            </TableCell>
        </TableRow>
    );
};

import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridCustomDropdownFilter from './AgGridCustomDropdownFilter'
import AgGridCustomAutoCompleteFilter from './AgGridCustomAutoCompleteFilter'
import AgGridCustomConditionalDropdownFilter from './AgGridCustomConditionalDropdownFilter'
import AgGridCustomBooleanDropdownFilter from './AgGridCustomBooleanDropdownFilter'
import AgGridCustomTextSearchFilter from './AgGridCustomTextSearchFilter'
import AgGridDefaultDateFilter from './AgGridDefaultDateFilter'
import AgGridDefaultNumberFilter from './AgGridDefaultNumberFilter'
import AgGridDefaultTextFilter from './AgGridDefaultTextFilter'
import AgGridCustomTreeListFilter from './AgGridCustomTreeListFilter'
import AgGridDefaultSetFilter from './AgGridDefaultSetFilter'

class AgGridFilterFactory {
    static makeFilter(columnDefinition) {
        const filterType = columnDefinition.filter
        const filterStrategy = AgGridFilterFactory._makeFilter(filterType)

        return new filterStrategy(columnDefinition)
    }

    static _makeFilter(filterType) {
        switch (filterType) {
            case AgGridFilterConstants.FILTER_DROPDOWN:
                return AgGridCustomDropdownFilter
            case AgGridFilterConstants.FILTER_AUTO_COMPLETE:
                return AgGridCustomAutoCompleteFilter
            case AgGridFilterConstants.FILTER_CONDITIONAL_DROPDOWN:
                return AgGridCustomConditionalDropdownFilter
            case AgGridFilterConstants.FILTER_BOOLEAN_DROPDOWN:
                return AgGridCustomBooleanDropdownFilter
            case AgGridFilterConstants.FILTER_TEXT_SEARCH:
                return AgGridCustomTextSearchFilter
            case AgGridFilterConstants.FILTER_AG_DATE:
                return AgGridDefaultDateFilter
            case AgGridFilterConstants.FILTER_AG_NUMBER:
                return AgGridDefaultNumberFilter
            case AgGridFilterConstants.FILTER_AG_TEXT:
                return AgGridDefaultTextFilter
            case AgGridFilterConstants.FILTER_TREE_LIST:
                return AgGridCustomTreeListFilter
            case AgGridFilterConstants.FILTER_AG_SET:
                return AgGridDefaultSetFilter
            default:
                throw new Error(
                    `Filter type ${filterType} not supported. If you think this is a mistake, please contact the development team.`
                )
        }
    }
}

export default AgGridFilterFactory

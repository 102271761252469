import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Grid,
    Checkbox,
    FormControlLabel,
    CardActions,
    Button,
    CardContent,
    Divider,
    Card,
    InputTextField,
} from '../../../../base'
import { FontAwesomeIcon, fas } from '../../../../fontAwesome'
import {
    buttonContainerStyle,
    buttonStyle,
    checkboxStyle,
    closeIconStyle,
    containedButtonStyle,
    formControlLabelStyle,
    headerStyle,
    searchContainerStyle,
} from './style'

const SelectColumns = (props) => {
    const { api, columnDefinitions, columnApi } = props

    const [selectAll, setSelectAll] = useState(null)
    const [columnDefs, setColumnDefs] = useState([])
    const [filteredColumns, setFilteredColumns] = useState([])
    const [search, setSearch] = useState('')
    const [savedColumnDefs, setSavedColumnDefs] = useState([])

    useEffect(() => {
        const columns = columnDefinitions.map((column) => {
            const visible = column.hide !== true

            let headerName = column.headerName
                ? column.headerName
                : column.field

            return {
                pinned: !!column.pinned,
                field: column.field,
                headerName,
                lowercaseHeaderName: headerName.toLowerCase(),
                visible: visible,
            }
        })

        const unpinnedColumns = columns.filter((column) => !column.pinned)

        setColumnDefs(JSON.parse(JSON.stringify(unpinnedColumns)))
        setSavedColumnDefs(JSON.parse(JSON.stringify(unpinnedColumns)))

        updateSelectAllCheckbox(unpinnedColumns)
        filterColumns(search, unpinnedColumns)
    }, [])

    useEffect(() => {
        if (columnDefs.length === 0) return

        filterColumns(search, columnDefs)
    }, [search])

    const setColumnVisibility = (field, visible) => {
        columnApi.setColumnVisible(field, visible)
    }

    const updateSelectAllCheckbox = (columns) => {
        const visibleColumns = columns.filter((column) => column.visible)

        const allVisible = visibleColumns.length === columns.length

        if (allVisible) {
            setSelectAll(true)
            return
        }

        const someVisible = visibleColumns.length !== 0
        setSelectAll(someVisible ? null : false)
    }

    const filterColumns = (search, columns) => {
        if (!search) {
            setFilteredColumns(columns)
            return
        }

        const lowercaseSearch = search.toLowerCase()

        const filteredColumns = columns.filter((column) => {
            return column.lowercaseHeaderName.includes(lowercaseSearch)
        })

        setFilteredColumns(filteredColumns)
    }

    const toggleColumn = (field) => (event) => {
        const checked = event.target.checked
        const columnDefinition = columnDefs.find(
            (column) => column.field === field
        )

        columnDefinition.visible = checked

        const newColumns = columnDefs.map((column) => {
            if (column.field === field) {
                return columnDefinition
            }

            return column
        })

        setColumnDefs(JSON.parse(JSON.stringify(newColumns)))
        filterColumns(search, JSON.parse(JSON.stringify(newColumns)))
        updateSelectAllCheckbox(newColumns)
    }

    const updateColumnVisibility = () => {
        columnDefs.forEach((column) => {
            setColumnVisibility(column.field, column.visible)
        })

        setSavedColumnDefs(JSON.parse(JSON.stringify(columnDefs)))
        closeHandler()
    }

    const closeHandler = () => {
        api.closeToolPanel('columns')
    }

    const cancelHandler = () => {
        setColumnDefs(JSON.parse(JSON.stringify(savedColumnDefs)))
        setSearch('')
        filterColumns(search, JSON.parse(JSON.stringify(savedColumnDefs)))
        updateSelectAllCheckbox(savedColumnDefs)
        closeHandler()
    }

    const handleSelectAllChange = (event) => {
        const checked = event.target.checked

        const newColumns = columnDefs.map((column) => {
            column.visible = checked
            return column
        })

        setColumnDefs(JSON.parse(JSON.stringify(newColumns)))
        filterColumns(search, JSON.parse(JSON.stringify(newColumns)))
        updateSelectAllCheckbox(newColumns)
    }

    const handleSearchChange = (event) => setSearch(event.target.value)

    const filteredColumnItems = filteredColumns.map((column) => {
        return (
            <Grid item xs={12} key={column.field}>
                <FormControlLabel
                    sx={formControlLabelStyle}
                    control={
                        <Checkbox
                            sx={checkboxStyle}
                            type="checkbox"
                            checked={column.visible}
                            onChange={toggleColumn(column.field)}
                        />
                    }
                    label={column.headerName}
                />
            </Grid>
        )
    })
    return (
        <Card
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
            <CardContent>
                <Grid container>
                    <Grid item xs={11} style={headerStyle}>
                        Select Column
                    </Grid>
                    <Grid item xs={1}>
                        <FontAwesomeIcon
                            icon={fas.faClose}
                            onClick={cancelHandler}
                            style={closeIconStyle}
                        />
                    </Grid>
                    <Grid item xs={12} style={searchContainerStyle}>
                        <FormControlLabel
                            sx={{ paddingLeft: '10px' }}
                            control={
                                <Checkbox
                                    sx={checkboxStyle}
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                    indeterminate={selectAll === null}
                                />
                            }
                        />
                        <InputTextField
                            type="search"
                            placeholder="Search..."
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>

            <Divider />

            <CardContent sx={{ overflowY: 'scroll', flex: '1' }}>
                <Grid container>
                    <Grid item xs={12}></Grid>
                    {filteredColumnItems}
                </Grid>
            </CardContent>

            <CardActions
                sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    gap: '0.5rem',
                    paddingBottom: '24px',
                }}
            >
                <Button onClick={cancelHandler} theme="secondary" size="medium">
                    Cancel
                </Button>
                <Button
                    theme="primary"
                    size="medium"
                    onClick={updateColumnVisibility}
                >
                    Update
                </Button>
            </CardActions>
        </Card>
    )
}

export default SelectColumns

SelectColumns.propTypes = {
    columnDefinitions: PropTypes.array.isRequired,
    api: PropTypes.object.isRequired,
    columnApi: PropTypes.object.isRequired,
}

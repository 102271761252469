import React, { forwardRef, useRef, useState } from 'react'
import { TextField } from '../../base/TextField'
import InputAdornment from '../../base/InputAdornment'

const Upload = forwardRef((props, ref) => {
    const inputFileRef = useRef(null)
    const [hover, setHover] = useState(false)
    const [focus, setFocus] = useState(false)
    const value = props.value?.length ? props.value[0]?.name : ''
    const isError = props.error
    const enable = hover || focus || isError
    const borderColor =
        (isError && '#D32F2F') || (hover && '#000000') || (focus && '#007899')
    const borderSize = ((isError || hover) && '1px') || (focus && '2px')

    const handleWrapperClick = () => {
        inputFileRef.current.click()
    }

    return (
        <div
            onFocus={() => {
                setFocus(true)
                setHover(false)
            }}
            onBlur={() => setFocus(false)}
            onClick={handleWrapperClick}
        >
            <TextField
                {...props}
                ref={ref}
                size="small"
                fullWidth
                readOnly
                disabled={props.disabled}
                onMouseEnter={() => !isError && setHover(true)}
                onMouseLeave={() => !isError && setHover(false)}
                value={value}
                sx={{
                    '& .MuiInputBase-root': {
                        paddingLeft: 0,
                    },
                    '& .MuiInputBase-root, & .MuiInputBase-input': {
                        cursor: "pointer"
                    },
                }}
                inputProps={{
                    style: {
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontSize: '12px',
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <span
                                style={{
                                    fontFamily:
                                        '"Roboto","Helvetica","Arial",sans-serif',
                                    fontSize: '12px',
                                    borderRadius: '2px',
                                    borderRight: enable
                                        ? `${borderSize} solid ${borderColor}`
                                        : '1px solid #bdc3c7',
                                    borderBottom: ` ${
                                        focus ? `2px solid ${borderColor}` : ''
                                    }`,
                                    background: 'rgb(246, 250, 253)',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '18px',
                                    width: '4.5rem',
                                    padding: '7px 17px',
                                    marginLeft: '1px',
                                    color: '#333',
                                }}
                            >
                                Choose File
                            </span>
                        </InputAdornment>
                    ),
                    readOnly: true,
                }}
            />
            <input
                ref={inputFileRef}
                type="file"
                id="file-upload"
                style={{ display: 'none' }}
                disabled={props.disabled}
                onChange={(e) => {
                    props.onChange([...e.target.files])
                }}
            />
        </div>
    )
})

export default Upload

import React from 'react';
import { MaterialDataGridSortControls } from 'components/MaterialDataGridSortControls';
import { MaterialDataGridFilterControls } from 'components/MaterialDataGridFilterControls';
import {
    FilterFieldType,
    HorizontalAlignment,
    MaterialDataGridInterface,
} from 'types';
import { CellContent, TableCellStyled, useStyles } from './styles';

type Props = {
    children?: any;
    className?: string;
    dataGrid?: MaterialDataGridInterface;
    filterable?: boolean;
    filterMenuTransformOrigin?: HorizontalAlignment;
    name?: string;
    sortable?: boolean;
    style?: Object;
    type?: FilterFieldType;
};

export const MaterialDataGridTableHeaderNoContext = ({
    children,
    className = '',
    dataGrid,
    name = '',
    filterable,
    filterMenuTransformOrigin,
    sortable,
    style,
    type = 'text',
    ...props
}: Props) => {
    const { sort, handleSort, filters, setFilters = () => {} } = dataGrid || {};

    let sortDirection;
    let isActive = false;
    if (sortable) {
        const { by: sortBy, order: sortOrder } = sort || {};
        isActive = name === sortBy;
        // @ts-ignore
        sortDirection = sortOrder;
    }
    const classes = useStyles();

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <TableCellStyled
            sortDirection={isActive ? sortDirection : false}
            filterable={filterable}
            className={className}
            style={style}
            {...props}
        >
            <div className={classes.cellContent}>
                <CellContent sortable={sortable}>{children}</CellContent>
                <MaterialDataGridSortControls
                    sort={sortable}
                    active={isActive}
                    direction={isActive ? sortDirection : 'asc'}
                    onClick={() => {
                        if (handleSort && name) {
                            handleSort(name);
                        }
                    }}
                />
                <MaterialDataGridFilterControls
                    name={name}
                    filterable={filterable}
                    filters={filters}
                    setFilters={setFilters}
                    filterMenuTransformOrigin={filterMenuTransformOrigin}
                    type={type}
                />
            </div>
        </TableCellStyled>
    );
};

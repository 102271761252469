const AgGridFilterConstants = {
    FILTER_AUTO_COMPLETE: 'autoComplete',
    FILTER_BOOLEAN_DROPDOWN: 'booleanDropdown',
    FILTER_TEXT_SEARCH: 'textSearch',
    FILTER_DROPDOWN: 'dropdown',
    FILTER_CONDITIONAL_DROPDOWN: 'conditionalDropdown',
    FILTER_TREE_LIST: 'treeList',

    FILTER_AG_DATE: 'agDateColumnFilter',
    FILTER_AG_NUMBER: 'agNumberColumnFilter',
    FILTER_AG_TEXT: 'agTextColumnFilter',
    FILTER_AG_SET: 'agSetColumnFilter',

    FILTER_TYPE_TEXT: 'text',
    FILTER_TYPE_NUMBER: 'number',
    FILTER_TYPE_DATE: 'date',
    FILTER_TYPE_BOOLEAN: 'boolean',
    FILTER_TYPE_SET: 'set',

    CONDITION_WHERE_IN: 'whereIn',
    CONDITION_CONTAINS: 'contains',
    CONDITION_NOT_CONTAINS: 'notContains',
    CONDITION_EQUALS: 'equals',
    CONDITION_NOT_EQUALS: 'notEqual',
    CONDITION_STARTS_WITH: 'startsWith',
    CONDITION_ENDS_WITH: 'endsWith',
    CONDITION_BLANK: 'blank',
    CONDITION_NOT_BLANK: 'notBlank',
    CONDITION_GREATER_THAN: 'greaterThan',
    CONDITION_GREATER_THAN_OR_EQUAL: 'greaterThanOrEqual',
    CONDITION_LESS_THAN: 'lessThan',
    CONDITION_LESS_THAN_OR_EQUAL: 'lessThanOrEqual',
    CONDITION_IN_RANGE: 'inRange',
}

export default AgGridFilterConstants

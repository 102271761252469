import PropTypes from 'prop-types'

// CoInvestment
export const coInvestmentPropType = PropTypes.shape({
    CompanyID: PropTypes.number,
    FundID: PropTypes.number,
    GeneralPartnerID: PropTypes.number,
    asset_class_id: PropTypes.number,
    avail_to_ssg_local: PropTypes.string,
    chinese_investors: PropTypes.number,
    coinvest_deal_completed_id: PropTypes.number,
    coinvest_id: PropTypes.number,
    coinvest_process_id: PropTypes.number,
    creation_date: PropTypes.string,
    creator_user_id: PropTypes.number,
    credit_gp_relationship_id: PropTypes.number,
    currency: PropTypes.string,
    date_received: PropTypes.string,
    deal_fee: PropTypes.string,
    dealprocess_id: PropTypes.number,
    dealreview_id: PropTypes.number,
    dealshown_id: PropTypes.number,
    dealtype_id: PropTypes.number,
    fx_date: PropTypes.string,
    fx_rate: PropTypes.number,
    gross_asset_yield: PropTypes.string,
    in_pipeline: PropTypes.number,
    inst_id: PropTypes.number,
    kia_notes: PropTypes.string,
    ltv_tranche: PropTypes.string,
    metric_id: PropTypes.number,
    modified_by: PropTypes.number,
    modified_date_gmt: PropTypes.string,
    monitoring_fee: PropTypes.string,
    net_debt_ebitda_tranche: PropTypes.string,
    non_us_req_filing: PropTypes.number,
    non_us_withholdings: PropTypes.number,
    notes: PropTypes.string,
    pipeline_comments: PropTypes.string,
    project_name: PropTypes.string,
    real_asset_sector_id: PropTypes.number,
    security_type_id: PropTypes.number,
    sedco_compliant: PropTypes.number,
    sira_deal_type_id: PropTypes.number,
    source_client_id: PropTypes.number,
    sourcing_channel: PropTypes.string,
    sponsor_gp_id: PropTypes.number,
    sponsor_irr_max: PropTypes.string,
    sponsor_irr_min: PropTypes.string,
    sponsor_tvm_max: PropTypes.string,
    sponsor_tvm_min: PropTypes.string,
    ssg_board_observer_seats: PropTypes.number,
    ssg_board_seats: PropTypes.number,
    ssg_irr_max: PropTypes.string,
    ssg_irr_min: PropTypes.string,
    ssg_target_currency: PropTypes.string,
    ssg_target_fx_date: PropTypes.string,
    ssg_target_fx_rate: PropTypes.string,
    ssg_target_local: PropTypes.string,
    ssg_tvm_max: PropTypes.string,
    ssg_tvm_min: PropTypes.string,
    stake_pct: PropTypes.string,
    target_close_date: PropTypes.string,
    target_ic_date: PropTypes.string,
    tgf_review: PropTypes.number,
    top_pick_detail_id: PropTypes.number,
    total_board_seats: PropTypes.number,
    us_deal_pass_thru: PropTypes.number,
    use_of_proceeds_id: PropTypes.number,
})

import React, { useState } from 'react'
import { FontAwesomeIcon, fas } from '../../../fontAwesome'
import { PaginationContainer, ActionButton, PaginationDiv } from '../style'

const PaginationStepper = ({ api }) => {
    const [pageSize, setPageSize] = useState(api.paginationGetPageSize())
    const [currentPage, setCurrentPage] = useState(
        api.paginationGetCurrentPage()
    )
    const [totalPage, setTotalPage] = useState(api.paginationGetTotalPages())
    const [rowCount, setRowCount] = useState(api.paginationGetRowCount())
    const [previousDisable, setPreviousDisable] = useState(true)
    const [nextDisable, setNextDisable] = useState(false)

    api.addEventListener('paginationChanged', (params) => {
        setPageSize(params.api.paginationGetPageSize())
        setCurrentPage(params.api.paginationGetCurrentPage())
        setTotalPage(params.api.paginationGetTotalPages())
        setRowCount(params.api.paginationGetRowCount())

        handlePagination()
    })

    const handlePagination = () => {
        if (api.paginationGetCurrentPage() === 0) {
            setPreviousDisable(true)
        } else {
            setPreviousDisable(false)
        }

        if (
            api.paginationGetCurrentPage() + 1 ===
            api.paginationGetTotalPages()
        ) {
            setNextDisable(true)
        } else {
            setNextDisable(false)
        }
    }

    return (
        <PaginationDiv>
            <PaginationContainer>
                <b>{currentPage * pageSize + 1}</b> to{' '}
                <b>
                    {(currentPage + 1) * pageSize > rowCount
                        ? rowCount
                        : (currentPage + 1) * pageSize}
                </b>{' '}
                of <b>{rowCount}</b>
            </PaginationContainer>

            <ActionButton disabled={previousDisable}>
                <FontAwesomeIcon
                    icon={fas.faBackwardStep}
                    onClick={() =>
                        !previousDisable && api.paginationGoToFirstPage()
                    }
                />
            </ActionButton>

            <ActionButton disabled={previousDisable}>
                <FontAwesomeIcon
                    icon={fas.faAngleLeft}
                    onClick={() =>
                        !previousDisable && api.paginationGoToPreviousPage()
                    }
                />
            </ActionButton>

            <ActionButton>
                Page <b>{currentPage + 1}</b> of <b>{totalPage}</b>
            </ActionButton>

            <ActionButton disabled={nextDisable}>
                <FontAwesomeIcon
                    icon={fas.faAngleRight}
                    onClick={() => !nextDisable && api.paginationGoToNextPage()}
                />
            </ActionButton>

            <ActionButton disabled={nextDisable}>
                <FontAwesomeIcon
                    icon={fas.faForwardStep}
                    onClick={() => !nextDisable && api.paginationGoToLastPage()}
                />
            </ActionButton>
        </PaginationDiv>
    )
}

export default PaginationStepper

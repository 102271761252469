import styled from 'styled-components'

const ToastContainer = styled.div`
    position: fixed;
    min-width: 260px;
    top: 24px;
    right: 24px;
    z-index: 9999;

    & .MuiAlert-message {
        overflow: unset;
    }
`

export default ToastContainer

import AgGridFilterConstants from '../../constants/AgGridFilterConstants'

class AgGridFilterModelBuilder {
    constructor() {
        this.filterModel = {
            filter: null,
            filterTo: null,
            type: null,
            filterType: null,
            dateFrom: null,
            dateTo: null,
        }
    }

    setFilter(filter) {
        this.filterModel.filter = filter

        return this
    }

    setFilterTo(filterTo) {
        this.filterModel.filterTo = filterTo

        return this
    }

    setType(type) {
        this.filterModel.filterType = type

        return this
    }

    setFilterType(filterType) {
        this.filterModel.type = filterType

        return this
    }

    build() {
        if (
            this.filterModel.filterType ===
            AgGridFilterConstants.FILTER_TYPE_DATE
        ) {
            this.filterModel.dateFrom = this.filterModel.filter
            this.filterModel.dateTo = this.filterModel.filterTo

            this.filterModel.filter = null
            this.filterModel.filterTo = null
        }

        return this.filterModel
    }
}

export default AgGridFilterModelBuilder

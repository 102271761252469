import ExcelDataType from './ExcelDataType'

class ExcelData {
    constructor({ type = ExcelDataType.STRING, value = null }) {
        this.type = type
        this.value = value
    }

    setValue(value) {
        this.value = value
    }

    setType(type) {
        this.type = type
    }
}

export default ExcelData

import { useEffect, useState } from 'react'
// FIXME: this shouldn't depend on this context anymore
import { useProjectSearch } from '../../../projects'

export const useSearch = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedFilter, setFilter] = useState(0)
    const handleFilterChange = (event, index) => {
        setFilter(index)
    }
    const [isSearchOpen, setSearchOpen] = useState(false)
    const handleCloseSearch = () => {
        setSearchOpen(false)
        setSearchTerm('')
        setFilter(0)
    }

    const { debounceInterval = 500, onInputChange = (value) => {} } =
        useProjectSearch()

    useEffect(() => {
        if (searchTerm && !isSearchOpen) {
            setSearchOpen(true)
        }
    }, [searchTerm])

    useEffect(() => {
        if (searchTerm) {
            const handler = setTimeout(() => {
                onInputChange(searchTerm)
            }, debounceInterval)

            return () => {
                clearTimeout(handler)
            }
        }
    }, [searchTerm, debounceInterval, onInputChange])

    const onChangeSearchTerm = (value) => {
        setSearchTerm(value.target.value)
    }

    return {
        searchTerm,
        onChangeSearchTerm,
        selectedFilter,
        handleFilterChange,
        isSearchOpen,
        setSearchOpen,
        handleCloseSearch,
    }
}

import React, { createContext, useContext, useState } from 'react'
import projects from './projects'
import * as Layouts from './layout'
import { breakpoints } from './layout'

import { useTheme } from '@mui/material/styles' // or @mui/joy/styles
import useMediaQuery from '@mui/material/useMediaQuery'

export const useWidth = () => {
    const theme = useTheme()
    const keys = [...theme.breakpoints.keys]
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key))
            return matches ? key : output
        }, null) ?? 'xs'
    )
}

const DEFAULT_STATE = {
    project: 'default',
}

export const SSGAppHeaderContext = createContext(DEFAULT_STATE)

export const SSGAppHeaderProvider = ({ children, value }) => (
    <SSGAppHeaderContext.Provider value={value}>
        {children}
    </SSGAppHeaderContext.Provider>
)

export const useSSGAppHeader = () => useContext(SSGAppHeaderContext)

const selectLayout = ({ width, project }) => {
    const layout = Layouts[project]
    if (layout) {
        const index = breakpoints.findIndex(
            (breakpoint) => breakpoint === width
        )
        const filledLayouts = breakpoints
            .slice(0, index + 1) // get only the breakpoints from the start to the current width
            .map((w) => layout[w]) // filled the widths with the order or undefined if not defined
            .slice()
            .reverse() // reverse array

        // find first not undefined value
        const order = filledLayouts.find((o) => o !== undefined)
        return order
    }

    return undefined
}

export const useProjectLayoutOrder = () => {
    const { project } = useSSGAppHeader()
    const width = useWidth()

    return selectLayout({ width, project })
}

export const useProject = () => {
    const { project } = useSSGAppHeader()
    return { ...projects.default, ...projects[project], ...undefined }
}

const rgxFunction = /^[a-zA-Z]+\([^\)]*\)(\.[^\)]*\))?/
export const isFunction = (text) => {
    return rgxFunction.test(text)
}

const getFunctionName = (text) => {
    return text.split('(')[0] || ''
}

const emptyFunction = (name) => ({
    execute: () => {
        console.log(`function named ${name} not found`)
    },
    callback: () => {
        console.log('function not found')
    },
    name,
})

export const useFunction = (text) => {
    if (!text) return emptyFunction()
    const name = getFunctionName(text)
    const callback = useCallback(name)
    if (!isFunction(text) || !callback) {
        // if not valid function or not defined
        return emptyFunction(name)
    }
    return {
        execute: (event, item) => callback(event, item),
        callback,
        name,
    }
}

export const useProjectSearch = () => {
    const project = useProject()
    return project.searchInput
}

export const useCallback = (name) => {
    const { callbackMap } = window
    if (callbackMap) {
        return callbackMap[name]
    }
    return undefined
}

export const useRedirect = () => {
    const redirect = useCallback('redirect')
    const defaultRedirect = (url) => (window.location.href = url)
    return redirect || defaultRedirect
}

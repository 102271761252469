import React from 'react'
import { DropdownTextField, Grid } from '../../../base'
import { useState } from 'react'
import { getSortedOptions } from '../utils'
import { pageSizeDropdownStyle, pageSizeSpan, pageSizeWrapper } from '../style'

const PageSize = ({ api, options }) => {
    const sortOptions = getSortedOptions(options)
    const [perPage, setPerPage] = useState(sortOptions[0].value)

    return (
        <div style={pageSizeWrapper}>
            <span style={pageSizeSpan}>Rows:</span>
            <DropdownTextField
                sx={pageSizeDropdownStyle}
                isRequired
                value={perPage}
                onChange={(e) => {
                    api.paginationSetPageSize(e.target.value)
                    setPerPage(e.target.value)
                }}
                options={sortOptions}
            />
        </div>
    )
}

export default PageSize

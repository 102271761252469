import styled from 'styled-components'

export const Section = styled.p`
    color: #333333;
    margin: 24px 0 24px 0;
    font-weight: 600;
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
`

export const SeparatorGrid = {
    width: '100%',
    display: 'flex',
}

export const SeparatorContainer = {
    display: 'block',
    width: '80%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '1em',
}

export const Separator = {
    border: 'none',
    height: '1px',
    background: '#BDBFBF',
}

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon, far } from '../../fontAwesome'
import { LinearProgress } from '@mui/material'

import {
    FileRowContainer as Container,
    FileRowContent as Content,
    FileRowName as FileName,
} from './styles'

const FileRow = ({ file, index, onDelete }) => {
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        // Use FileReader to read file content
        const reader = new FileReader()

        // get progress
        reader.onprogress = (data) => {
            if (data.lengthComputable) {
                const ongoing = parseInt((data.loaded / data.total) * 100, 10)
                setProgress(ongoing)
            }
        }

        // on finish
        reader.onloadend = () => {
            // console.log(reader.result);
        }

        // on error
        reader.onerror = () => {
            console.error('There was an issue reading the file.')
        }

        reader.readAsDataURL(file)
    }, [file])

    return (
        <Container>
            <Content>
                <FileName>{file.name}</FileName>
                <FontAwesomeIcon
                    onClick={() => onDelete(index)}
                    icon={far.faTrashAlt}
                />
            </Content>
            {progress < 100 ? (
                <LinearProgress
                    size="lg"
                    variant="determinate"
                    value={progress}
                />
            ) : null}
        </Container>
    )
}

export default FileRow

import PropTypes from 'prop-types'

// Institutions
export const institutionPropType = PropTypes.shape({
    inst_id: PropTypes.number,
    inst_fullname: PropTypes.string,
    inst_type_id: PropTypes.number,
    inside_client: PropTypes.bool,
    active: PropTypes.number,
    inside_lead: PropTypes.number,
    total_asset_local: PropTypes.number,
    total_asset_date: PropTypes.string,
    total_asset_fx_rate: PropTypes.number,
    total_asset_fx_date: PropTypes.string,
    pe_alloc_local: PropTypes.number,
    pe_alloc_date: PropTypes.string,
    pe_alloc_fx_rate: PropTypes.number,
    pe_alloc_fx_date: PropTypes.string,
    invest_size_local_min: PropTypes.number,
    invest_size_local_max: PropTypes.number,
    invest_size_date: PropTypes.string,
    invest_size_fx_rate: PropTypes.number,
    invest_size_fx_date: PropTypes.string,
    website_thumb_date: PropTypes.string,
    preq_investor_id: PropTypes.number,
    created_by: PropTypes.number,
    created_date_gmt: PropTypes.string,
    modified_by: PropTypes.number,
    modified_date_gmt: PropTypes.string,
})

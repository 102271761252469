import styled from 'styled-components'
import { List } from '@mui/material'

export const SearchPageContainer = styled.div`
    color: #444;
    z-index: 1300;
    overflow: auto;
    left: 0;
    top: ${(props) => (props.screenSize === 'large' ? '68px' : '122px')};
    width: 100%;
    height: calc(
        100vh - ${(props) => (props.screenSize === 'large' ? '68px' : '122px')}
    );
    max-height: 100vh;
    background: #fff;
    position: absolute;
    transition: 0.5s;
`

export const FilterList = styled(List)`
    border-right: 1px solid #d0dae4;
    padding-right: 39px !important;
    marign-top: 10px;
    position: fixed;
`

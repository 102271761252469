import React, { Fragment } from 'react';
import { IconButton, Input, MenuItem, Select } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
    FilterFieldType,
    FilterInterface,
    FilterOperators,
    FilterTypeValue,
} from 'types';
import { filtersByType } from 'data/filters';
import { DeleteIconContainer, useStyles } from './styles';

interface Props {
    handleFilterValueUpdate: (index: number, newValue: string) => Promise<any>;
    handleFilterDelete: (index: number) => Promise<any>;
    handleFilterTypeUpdate: (
        index: number,
        newType: FilterTypeValue,
    ) => Promise<any>;
    handleFilterOperatorChange: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => Promise<any>;
    index: number;
    filter: FilterInterface;
    filters: Array<FilterInterface>;
    filterOperator: FilterOperators;
    type: FilterFieldType;
}

export const Filter = ({
    handleFilterValueUpdate,
    handleFilterDelete,
    handleFilterTypeUpdate,
    handleFilterOperatorChange,
    index,
    filter,
    filters,
    filterOperator,
    type,
}: Props) => {
    const classes = useStyles();

    const filterOptions = filtersByType[type];

    return (
        <Fragment>
            <div className={classes.singleFilter}>
                <DeleteIconContainer visible={index !== filters.length}>
                    <IconButton
                        size='small'
                        onClick={() => handleFilterDelete(index)}
                    >
                        <Delete />
                    </IconButton>
                </DeleteIconContainer>
                <div className={classes.filterInputsContainer}>
                    <b>Value:</b>{' '}
                    <Select
                        value={filter.type.value}
                        onChange={({ target: { value } }) =>
                            // @ts-ignore
                            handleFilterTypeUpdate(index, value)
                        }
                    >
                        {Object.keys(filterOptions).map((value) => (
                            <MenuItem key={value} value={value}>
                                {filterOptions[value].text}
                            </MenuItem>
                        ))}
                    </Select>{' '}
                    {filter.type?.inputValue && (
                        <Fragment>
                            <br />
                            {filter.type.inputValue === true && (
                                <Input
                                    placeholder={
                                        index === filters.length
                                            ? 'New Filter'
                                            : ''
                                    }
                                    fullWidth
                                    value={filter.value}
                                    onChange={({ target: { value } }) =>
                                        handleFilterValueUpdate(index, value)
                                    }
                                />
                            )}
                            {filter.type.inputValue === 'date' && (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        format='yyyy-MM-dd'
                                        margin='none'
                                        value={
                                            filter.value
                                                ? moment(filter.value).format(
                                                      'YYYY/MM/DD',
                                                  )
                                                : null
                                        }
                                        KeyboardButtonProps={{
                                            'aria-label': 'Filter Date',
                                        }}
                                        size='small'
                                        onChange={(_, value: string) =>
                                            handleFilterValueUpdate(
                                                index,
                                                value,
                                            )
                                        }
                                        style={{
                                            minWidth: '100px',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
            {filters.length - 1 > index && (
                <div className={classes.filterOperatorSelect}>
                    <Select
                        value={filterOperator}
                        onChange={handleFilterOperatorChange}
                    >
                        <MenuItem value='and'>And</MenuItem>
                        <MenuItem value='or'>Or</MenuItem>
                    </Select>
                </div>
            )}
        </Fragment>
    );
};

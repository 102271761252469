import React from 'react'

const DEFAULT_LABEL_PROPERTY = 'label'
const DEFAULT_VALUE_PROPERTY = 'key'

class AgGridBaseFilter {
    constructor(columnDefinition) {
        this.columnDefinition = columnDefinition
        this.filterParams = columnDefinition.filterParams
    }

    build() {
        return {
            ...this.columnDefinition,
            filter: this.getFilter(),
            filterParams: this.getFilterParams(),
            filterType: this.getFilterType(),
        }
    }

    /** abstract */
    getFilter() {
        throw new Error('Abstract method getFilter not implemented')
    }

    getFilterParams() {
        return {
            ...this.filterParams,
            ...this.getExtraFilterParams(),
        }
    }

    /** abstract */
    getFilterType() {
        throw new Error('Abstract method getFilterType not implemented')
    }

    getExtraFilterParams() {
        if (!this.isCustomFilter()) return {}

        const options = this.filterParams?.options ?? []

        const filterOptionsPromise = this.makeOptionsPromise(options)
        const customFilterParams = this.getCustomFilterParams()

        return {
            ...customFilterParams,
            options: filterOptionsPromise,
        }
    }

    /** abstract */
    isCustomFilter() {
        return false
    }

    makeOptionsPromise(options) {
        return new Promise((resolve) => {
            const formattedFilterOptions = this.getFormattedOptions(options)

            resolve(formattedFilterOptions)
        })
    }

    /** abstract */
    getCustomFilterParams() {
        return {}
    }

    getFormattedOptions(options) {
        if (!options) return undefined

        const optionsType = this.getOptionsType(options)

        if (!this.correctOptionsTypePassed(optionsType)) {
            throw `Incorrect options passed to ${
                this.constructor.name
            }, you passed a ${this.passedOptionTypeName(optionsType)}`
        }

        if (optionsType.isArray) {
            return this.getOptionsFromArray(options)
        }

        if (optionsType.isPromise) {
            return this.getOptionsFromPromise(options)
        }

        if (optionsType.isFunction) {
            return this.getOptionsFromFunction(options)
        }

        return []
    }

    passedOptionTypeName({ isArray, isPromise, isFunction }) {
        if (isArray) return 'array'
        if (isPromise) return 'promise'
        if (isFunction) return 'function'

        return 'unknown'
    }

    getOptionsType(options) {
        const isArray = Array.isArray(options)
        const isPromise =
            typeof options === 'object' && typeof options.then === 'function'
        const isFunction = typeof options === 'function' && !isPromise

        return {
            isArray,
            isPromise,
            isFunction,
        }
    }

    /** abstract */
    correctOptionsTypePassed() {
        return true
    }

    getOptionsFromArray(options) {
        const optionFormatter = this.getOptionFormatter()

        return options.map(optionFormatter)
    }

    getOptionsFromFunction(options) {
        const optionList = options()

        return this.getFormattedOptions(optionList)
    }

    getOptionsFromPromise(options) {
        const optionFormatter = this.getOptionFormatter()

        return options.then((optionList) => {
            return optionList.map(optionFormatter)
        })
    }

    getOptionFormatter() {
        const labelFormatter =
            this.filterParams?.labelFormatter ?? this.labelFormatter
        const keyFormatter =
            this.filterParams?.keyFormatter ?? this.keyFormatter

        const optionFormatter =
            this.filterParams?.optionFormatter ??
            this.optionFormatter(keyFormatter, labelFormatter)

        return optionFormatter
    }

    keyFormatter(option) {
        const optionHasKeyProperty =
            option[DEFAULT_VALUE_PROPERTY] !== undefined &&
            option[DEFAULT_VALUE_PROPERTY] !== null

        if (optionHasKeyProperty) return option[DEFAULT_VALUE_PROPERTY]

        return option
    }

    labelFormatter(option) {
        const optionHasLabelProperty =
            option[DEFAULT_LABEL_PROPERTY] !== undefined &&
            option[DEFAULT_LABEL_PROPERTY] !== null

        if (optionHasLabelProperty) return option[DEFAULT_LABEL_PROPERTY]

        return option
    }

    optionFormatter = (keyFormatter, labelFormatter) => (option) => {
        return {
            value: keyFormatter(option),
            label: labelFormatter(option),
        }
    }
}

export default AgGridBaseFilter

/**
 * Base Filter Params
 * labelFormatter: Optional function to format the label of the option. If not provided, we will search for the
 * label property in the option, if not found, we will use the option itself as the label.
 *
 * keyFormatter: Optional function to format the value of the option. If not provided, we will search for the
 * key property in the option, if not found, we will use the option itself as the value.
 *
 * optionFormatter: Optional function to format the complete option. If not provided, we will use the keyFormatter
 * and labelFormatter to format the option. The returned object must have a
 * value property and a label property.
 *
 * options: Optional array/function/promise of options to be displayed in the filter.
 */

/**
 * In the column definition, you can pass the filter prop to use the filter.
 * There are also a couple more configurations you can pass:
 * filterHeaderName: Optional string to set the name of the filter header. This is so we can use a different
 * name in the filter drawer than in the column header.
 *
 * headerTooltip: Optional string to set the tooltip. If passed, an icon will be displayed in the column header and
 * the tooltip will be displayed on hover in both the column and filter headers.
 */

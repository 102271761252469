import React, { forwardRef } from 'react'
import Button from '../Button'
import { SecondaryBtn } from '../style'

const AttachmentButton = forwardRef((props, ref) => {
    const { disabled, ...restProps } = props
    return (
        <Button
            {...restProps}
            disabled={disabled}
            ref={ref}
            sx={{ ...SecondaryBtn, ...props.error }}
        />
    )
})

export default AttachmentButton

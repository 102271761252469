import PropTypes from 'prop-types'

export const ConfirmationModalPropTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onSubmit: PropTypes.func.isRequired,
    diaglogProps: PropTypes.object,
    dialogContentProps: PropTypes.object,
    diaglogTitleProps: PropTypes.object,
    diaglogContentTextProps: PropTypes.object,
    dialogActionsProps: PropTypes.object,
    secondaryButtonText: PropTypes.string,
    primaryButtonText: PropTypes.string,
}

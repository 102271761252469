import React from 'react'
import { useProjectLayoutOrder } from './projects'
import { ComponentFactory } from './ComponentFactory'

const Layout = ({ payload }) => {
    const order = useProjectLayoutOrder()
    const layout =
        !order || order.length === 0
            ? payload
            : order.map(({ id, ...params }) => ({ ...payload[id], ...params }))

    return (
        <>
            {layout.map((item, index) => (
                <ComponentFactory key={`component-${index}`} {...item} />
            ))}
        </>
    )
}

export default Layout

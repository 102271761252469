import { useEffect, useState } from 'react';
import { PaginationType, ServerPaginationType } from 'types';

type UsePaginationResponse = [PaginationType, Function];

/**
 *
 * @param data              The data to use
 * @param paginated         Whether or not pagination is enabled
 * @param sortedData        Sorted data
 * @param defaultPerPage    Default per page value
 * @param perPageOptions    Options for per page
 * @param serverSideProcessing
 * @param serverPagination
 */
export const usePagination = (
    paginated: boolean,
    sortedData: any[],
    defaultPerPage: number,
    perPageOptions: number[],
    data?: any[],
    serverSideProcessing?: boolean,
    serverPagination?: ServerPaginationType,
): UsePaginationResponse => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(defaultPerPage);
    const [total, setTotal] = useState(0);

    const pages = Math.ceil(total / perPage);
    let start = 0;
    if (serverSideProcessing) {
        start = serverPagination?.start || 0;
    } else {
        start = total > 0 ? (page - 1) * perPage + 1 : 0;
    }

    const end = serverSideProcessing
        ? serverPagination?.end || 0
        : start + sortedData.length - 1;

    useEffect(() => {
        if (!serverSideProcessing || !serverPagination) {
            return;
        }
        setPage(serverPagination?.page || 1);
        setTotal(serverPagination?.total || 0);
    }, [serverPagination]);

    useEffect(() => {
        if (serverSideProcessing) {
            return;
        }
        if (paginated) {
            if (page > pages || start > total) {
                setPage(pages);
            } else if (page === 0 && total > 0) {
                setPage(1);
            }
        }
    }, [data, paginated, perPage, page, total]);

    const pagination = {
        start,
        end,
        total,
        perPage,
        page,
        pages,
        setPage,
        setPerPage,
        perPageOptions,
        paginated,
    };

    return [pagination, setTotal];
};

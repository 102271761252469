import React, { useEffect, useState } from 'react';
import { FieldFiltersInterface, FilterFieldType, FilterTypeValue } from 'types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import { FieldFilters } from 'prototypes';
import { useStyles } from './styles';
import { Filter } from './Filter';

interface Props {
    theseFilters?: FieldFiltersInterface;
    triggerClose: boolean;
    closeAndApply: Function;
    name: string;
    type: FilterFieldType;
}

const triggerWindowResize = (triggerResize = true) => {
    if (triggerResize) {
        window.dispatchEvent(new CustomEvent('resize'));
    }
};

export const FilterMenu = ({
    theseFilters = undefined,
    triggerClose,
    closeAndApply,
    name,
    type,
}: Props) => {
    const [localFilters, setLocalFilters] = useState(
        theseFilters
            ? theseFilters.clone()
            : new FieldFilters(name, undefined, undefined, type),
    );
    const classes = useStyles();

    const { filterOperator, filters } = localFilters;

    /**
     * Remove a filter
     * @param index
     */
    const handleFilterDelete = async (index: number): Promise<any> => {
        await setLocalFilters(localFilters.removeFilter(index));
        triggerWindowResize();
    };

    /**
     * Change the filter operator (and|or)
     * @param value
     */
    const handleFilterOperatorChange = async ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>): Promise<any> => {
        // @ts-ignore
        await setLocalFilters(localFilters.setOperator(value));
        triggerWindowResize();
    };

    /**
     * Update the filter type for a specific filter
     * @param index
     * @param newType
     */
    const handleFilterTypeUpdate = async (
        index: number,
        newType: FilterTypeValue,
    ): Promise<any> => {
        const triggerResize = index === filters.length;
        await setLocalFilters(localFilters.updateFilterType(index, newType));
        triggerWindowResize(triggerResize);
    };

    /**
     * Update the value of a filter
     * @param index
     * @param newValue
     */
    const handleFilterValueUpdate = async (
        index: number,
        newValue: string,
    ): Promise<any> => {
        const triggerResize = index === filters.length;
        await setLocalFilters(localFilters.updateFilterValue(index, newValue));
        triggerWindowResize(triggerResize);
    };

    /**
     * Clear all filters
     */
    const clearFilters = async () => {
        closeAndApply(null);
    };

    /**
     * Close the popup and apply the filters
     */
    const applyFilters = () => {
        closeAndApply(localFilters);
    };

    /**
     * Apply a filter update when triggerClose is set to true
     */
    useEffect(() => {
        if (triggerClose) {
            applyFilters();
        }
    }, [triggerClose]);

    return (
        <div className={classes.filterPopoverContent}>
            <div className={classes.filterPopoverInternalContainer}>
                {filters.map((filter, index) => (
                    <Filter
                        handleFilterValueUpdate={handleFilterValueUpdate}
                        handleFilterDelete={handleFilterDelete}
                        handleFilterTypeUpdate={handleFilterTypeUpdate}
                        handleFilterOperatorChange={handleFilterOperatorChange}
                        index={index}
                        filter={filter}
                        filters={filters}
                        filterOperator={filterOperator}
                        type={type}
                        key={
                            // eslint-disable-next-line react/no-array-index-key
                            index
                        }
                    />
                ))}
            </div>
            <div className={classes.addFilterActions}>
                <IconButton
                    onClick={() => handleFilterValueUpdate(filters.length, '')}
                    title='Add Filter'
                    size='small'
                >
                    <Add />
                </IconButton>
            </div>
            <div className={classes.filterPopoverActions}>
                <Button variant='outlined' onClick={clearFilters}>
                    Clear
                </Button>
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={applyFilters}
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};

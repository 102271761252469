import React, { useCallback } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { InputTypeProps } from './types';

const DateType = ({ value, onChange }: InputTypeProps) => {
    const handleChange = useCallback((_, newValue: string) => {
        onChange(newValue);
    }, []);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                format='yyyy-MM-dd'
                margin='none'
                value={value}
                KeyboardButtonProps={{
                    'aria-label': 'Filter Date',
                }}
                size='small'
                onChange={handleChange}
                style={{
                    minWidth: '100px',
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DateType;

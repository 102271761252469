import React from 'react'
import PropTypes from 'prop-types'
import { fundPropType } from './FundTypes'
import { Grid } from '@mui/material'
import CoverageIcons from './CoverageIcons'
import {
    EntityTypeText,
    LinkWrapper,
    EntityDescText,
    LinkStyled,
} from '../styles'

function FundGridDisplay({ fund, includeIcons, includeType, includeVintage }) {
    if (!fund) {
        return <></>
    }
    const {
        closed_ended: closeEnded,
        Vintage: vintage,
        FundID: fundId,
        Fund_Name: fundName = '',
    } = fund
    const vintageDisplay = closeEnded === false ? 'Open-Ended' : `${vintage}`

    return (
        <Grid container>
            <LinkWrapper item container xs={9}>
                {includeType && <EntityTypeText>Fund: </EntityTypeText>}
                <LinkStyled
                    href={`/fund_profile.php?id=${fundId}`}
                    title={`Go to Fund Profile (Fund ID ${fundId})`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {fundName}
                </LinkStyled>
            </LinkWrapper>
            <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                alignItems="center"
            >
                {includeIcons && (
                    <Grid item>
                        <CoverageIcons fund={fund} />
                    </Grid>
                )}
                {includeVintage && (
                    <Grid item>
                        <EntityDescText> ({vintageDisplay})</EntityDescText>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

FundGridDisplay.propTypes = {
    fund: fundPropType,
    includeIcons: PropTypes.bool,
    includeType: PropTypes.bool,
    includeVintage: PropTypes.bool,
}

FundGridDisplay.defaultProps = {
    fund: null,
    includeIcons: true,
    includeType: true,
    includeVintage: true,
}

export default FundGridDisplay

import React, { createContext } from 'react';
import { useDataGrid } from 'hooks';
import {
    MaterialDataGridInterface,
    SortOrderType,
    UpdateCellFunction,
} from 'types';
import withStyles from '@material-ui/core/styles/withStyles';

export const EmptyMaterialDataGridContext = {
    filters: undefined,
    setFilters: () => {},
    clearFilters: () => {},
    sort: undefined,
    handleSort: () => {},
    sortedData: [],
    paginated: false,
    pagination: {
        start: 0,
        end: 0,
        total: 0,
        perPage: 0,
        page: 0,
        pages: 0,
        setPage: () => {},
        setPerPage: () => {},
        perPageOptions: [5, 10, 25, 100],
        paginated: false,
    },
    updateCell: undefined,
};

// Empty context is needed to match the strong typing
//   The empty data will be replaced with completed context
//   once the grid is bootstrapped
export const MaterialDataGridContext = createContext<MaterialDataGridInterface>(
    EmptyMaterialDataGridContext,
);

type ChildrenFunction = (
    dataGrid: MaterialDataGridInterface,
) => React.ReactNode;

type MaterialDataGridProps = {
    children: React.ReactNode | ChildrenFunction;
    customSorter?: (valueA: any, valueB: any) => any;
    data?: Object[] | undefined;
    defaultSortBy?: string;
    defaultSortOrder?: SortOrderType;
    defaultPerPage?: number;
    getComparisonValue?: (fieldName: string, row: any) => any;
    idField?: string;
    paginated?: boolean;
    perPageOptions?: number[];
    noContext?: boolean;
    serverSideProcessing?: boolean;
    handleRequestedDataChange?: (
        page: number,
        perPage: number,
        filters: Object,
    ) => void;
    pagination?: {
        rows: number;
        page: number;
        pages: number;
        total: number;
        start: number;
        end: number;
    };
    updateCell?: UpdateCellFunction;
};

const Grid = ({
    children,
    customSorter = undefined,
    data,
    defaultSortBy = 'id',
    defaultSortOrder = 'asc',
    defaultPerPage = 10,
    getComparisonValue = undefined,
    idField = 'id',
    paginated = false,
    perPageOptions = [10, 25, 50, 100],
    noContext = false,
    serverSideProcessing = false,
    handleRequestedDataChange = undefined,
    pagination: serverPagination = undefined,
    updateCell,
}: MaterialDataGridProps) => {
    // TODO: add triggerClear prop which uses a use effect to clear sorts/filters
    const {
        data: sortedData,
        pagination,
        sort,
        handleSort,
        filters,
        setFilters,
        clearFilters,
    } = useDataGrid({
        customSorter,
        data,
        defaultPerPage,
        getComparisonValue,
        paginated,
        perPageOptions,
        serverSideProcessing,
        handleRequestedDataChange,
        serverPagination,
        defaultSortBy,
        defaultSortOrder,
    });

    if (noContext && typeof children === 'function') {
        return children({
            filters,
            setFilters,
            clearFilters,
            sort,
            handleSort,
            sortedData,
            paginated,
            pagination,
            updateCell,
        });
    }

    return (
        <MaterialDataGridContext.Provider
            value={{
                filters,
                setFilters,
                clearFilters,
                idField,
                sort,
                handleSort,
                sortedData,
                paginated,
                pagination,
                updateCell,
            }}
        >
            {children}
        </MaterialDataGridContext.Provider>
    );
};

export const MaterialDataGrid = withStyles({}, { withTheme: true })(Grid);

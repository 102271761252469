import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Button as MaterialButton } from '@mui/material'

const Button = forwardRef((props, ref) => {
    const {
        onClick,
        disabled,
        theme,
        size,
        children,
        icon,
        type,
        href,
        endIcon,
        onMouseLeave,
        onMouseOver,
    } = props

    if (theme) {
        return (
            <MaterialButton
                ref={ref}
                onClick={onClick}
                disabled={disabled}
                size={size}
                href={href}
                startIcon={icon}
                endIcon={endIcon}
                type={type}
                variant={theme}
                onMouseLeave={onMouseLeave}
                onMouseOver={onMouseOver}
            >
                {children}
            </MaterialButton>
        )
    }

    return <MaterialButton ref={ref} {...props} />
})

Button.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    theme: PropTypes.oneOf([
        'primary',
        'secondary',
        'accept',
        'progress',
        'reject',
        'info',
        'link',
        'delete',
        'grid-link',
    ]),
    size: PropTypes.oneOf(['medium', 'small']),
    icon: PropTypes.element,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
}

Button.defaultProps = {
    disabled: false,
    theme: null,
    size: 'medium',
    icon: null,
    type: 'button',
}

export default Button

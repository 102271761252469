import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import TableVariant from '../Table'
import {
    PageSize,
    PaginationStepper,
    ColumnPanel,
    TableHeader,
} from '../components'
import { domLayoutProps, getAgGridProps } from '../utils'
import { SERVER_SIDE_AND_ROW_DATA_UNDEFINED_ERROR } from '../constants'
import './PaginatedTableVariant.scss'
import AgGridColumnDefinitionBuilder from '../utils/AgGridColumnDefinitionBuilder'

const PaginatedTableVariant = forwardRef((props, ref) => {
    const {
        title,
        subtitle,
        buttonList,
        columnDefs,
        defaultColDef,
        agTheme,
        serverSideApi,
        rowHeight,
        perPageOptions,
        rowData,
        tableHeaderMarginBottom,
        tableHeight,
        allowContextMenuWithControlKey,
        getContextMenuItems,
        headerHeight,
    } = props

    if (!serverSideApi && !rowData) {
        throw new Error(SERVER_SIDE_AND_ROW_DATA_UNDEFINED_ERROR)
    }

    const defaultPerPage = perPageOptions[0]
    const dataProps = getAgGridProps(serverSideApi, rowData, defaultPerPage)

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                {
                    statusPanel: ({ api }) => (
                        <PageSize api={api} options={perPageOptions} />
                    ),
                    align: 'left',
                },
                {
                    statusPanel: ({ api }) => <PaginationStepper api={api} />,
                    align: 'right',
                },
            ],
        }
    }, [])

    const columnDefinitions = useMemo(() => {
        const columnDefinitionsBuilder = new AgGridColumnDefinitionBuilder(
            columnDefs
        )

        return columnDefinitionsBuilder.build()
    }, [columnDefs])

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: '',
                    labelKey: 'columns',
                    iconKey: '',
                    toolPanel: ({ api }) => (
                        <ColumnPanel
                            api={api}
                            columnApi={ref?.current.columnApi}
                            columnDefinitions={columnDefinitions}
                        />
                    ),
                },
                {
                    id: 'filters',
                    labelDefault: '',
                    labelKey: 'filters',
                    iconKey: '',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
        }
    }, [])

    const defaultColumnDefinition = {
        ...defaultColDef,
        headerValueGetter: (params) => {
            const agGridLocation = params.location
            const columnDefinition = params.column.colDef

            const headerName =
                columnDefinition.headerName ?? columnDefinition.field

            switch (agGridLocation) {
                case 'filterToolPanel':
                    return columnDefinition.filterHeaderName ?? headerName
                default:
                    return headerName
            }
        },
    }

    let headerHeightNumber = 48

    switch (headerHeight) {
        case 'small':
            headerHeightNumber = 32
            break
    }

    const COMMON_PROPS = {
        ...props,
        agTheme: agTheme || 'ag-theme-balham',
        sideBar,
        statusBar,
        tableType: 'aggrid',
        pagination: true,
        suppressPaginationPanel: true,
        rowHeight,
        paginationPageSize: defaultPerPage,
        cacheBlockSize: defaultPerPage,
        ...dataProps,
        columnDefs: columnDefinitions,
        defaultColDef: defaultColumnDefinition,
        ...domLayoutProps(tableHeight),
        className: 'ag-grid-custom',
        allowContextMenuWithControlKey,
        getContextMenuItems: getContextMenuItems,
        headerHeight: headerHeightNumber,
    }

    return (
        <>
            {
                <TableHeader
                    title={title}
                    subtitle={subtitle}
                    buttonList={buttonList}
                    gridRef={ref}
                    tableHeaderMarginBottom={tableHeaderMarginBottom}
                />
            }
            <TableVariant ref={ref} {...COMMON_PROPS} />
        </>
    )
})

export default PaginatedTableVariant

PaginatedTableVariant.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonList: PropTypes.arrayOf(PropTypes.object),
    columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultColDef: PropTypes.object,
    agTheme: PropTypes.string,
    serverSideApi: PropTypes.func,
    rowHeight: PropTypes.number,
    perPageOptions: PropTypes.arrayOf(PropTypes.number),
    rowData: PropTypes.arrayOf(PropTypes.object),
    tableHeaderMarginBottom: PropTypes.string,
    tableHeight: PropTypes.string,
    allowContextMenuWithControlKey: PropTypes.bool,
    getContextMenuItems: PropTypes.func,
    headerHeight: PropTypes.string,
}

PaginatedTableVariant.defaultProps = {
    defaultColDef: {
        filter: false,
        sortable: true,
        suppressMenu: true,
        resizable: true,
        suppressMenuMainPanel: true,
        suppressMenuColumnPanel: true,
        allowContextMenuWithControlKey: undefined,
        getContextMenuItems: undefined,
    },
    agTheme: 'ag-theme-alpine',
    rowHeight: 25,
    perPageOptions: [20, 50, 100, 250, 500],
    tableHeight: 'auto',
    headerHeight: '',
}

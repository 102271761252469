import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { HighchartsTheme } from '../../themes'
import HighchartsConfig from '../HighchartsConfig'

HighchartsTheme(Highcharts)
HighchartsConfig(Highcharts)

function HighchartsChart(props) {
    return <HighchartsReact highcharts={Highcharts} {...props} />
}

export default HighchartsChart

import React, { forwardRef } from 'react'
import { Autocomplete as MaterialAutocomplete } from '@mui/material'
import { TextField } from '../TextField'
import { getInputProps } from './util'

const Autocomplete = forwardRef((props, ref) => {
    const {
        disabled = false,
        disableCloseOnSelect,
        error,
        field,
        freeSolo = false,
        groupBy,
        helperText,
        isThemed = false,
        icon = null,
        limitTags,
        loading = false,
        loadingText = 'Loading...',
        multiple = false,
        onChange,
        onInputChange,
        options = [],
        optionLabel = 'label',
        optionValue = 'id',
        placeholder = '',
        value,
        getOptionKey,
        filterOptions,
        getOptionLabel = (option) => option?.[optionLabel],
        isOptionEqualToValue = (option, value) => {
            return option[optionValue] === value[optionValue]
        },
        renderOption,
        paperComponent,
    } = props

    if (!isThemed) {
        return <MaterialAutocomplete {...props} ref={ref} />
    }
    return (
        <MaterialAutocomplete
            disabled={disabled}
            disableCloseOnSelect={disableCloseOnSelect}
            filterOptions={filterOptions}
            renderOption={renderOption}
            PaperComponent={paperComponent}
            freeSolo={freeSolo}
            fullWidth
            groupBy={groupBy}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            limitTags={limitTags}
            loading={loading}
            loadingText={loadingText}
            multiple={!!multiple}
            onChange={(event, newValue) => {
                onChange && onChange(newValue)
            }}
            onInputChange={onInputChange}
            options={options}
            size={'small'}
            componentsProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: false,
                        },
                        {
                            name: 'preventOverflow',
                            enabled: false,
                        },
                    ],
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...getInputProps(params, error, icon)}
                    ref={ref}
                    placeholder={placeholder}
                    icon={icon}
                    error={error}
                    helperText={helperText}
                    {...(delete field?.onChange &&
                        delete field?.value &&
                        field)}
                />
            )}
            value={value}
        />
    )
})

export default Autocomplete

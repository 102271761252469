import React from 'react'
import InputLabel from '../../../base/InputLabel'
import Tooltip from '../../../base/Tooltip'
import { FontAwesomeIcon, fas } from '../../../fontAwesome'
import { LABEL } from './style'
import './FormLable.scss'
import TooltipDialog from '../../../stepstone/TooltipDialog/TooltipDialog'
import { COLOR_NAMES } from '../../../themes/SsgTheme'

const FormLabel = (props) => {
    const { children, label, tooltip, type, tooltipButton } = props
    const divWrapperClass =
        type === 'addAttachments' ? 'form-label-wrapper' : ''

    let LabelToolTip
    if (tooltip) {
        LabelToolTip = (
            <Tooltip title={tooltip} placement="right-start">
                <FontAwesomeIcon
                    icon={fas.faCircleInfo}
                    color={COLOR_NAMES.blue}
                    style={{
                        fontSize: '14px',
                        marginLeft: '4px',
                        verticalAlign: 'sub',
                    }}
                />
            </Tooltip>
        )
    } else if (tooltipButton) {
        LabelToolTip = (
            <TooltipDialog
                title={tooltipButton.title}
                icon={tooltipButton.icon}
                size={tooltipButton.size}
                buttons={tooltipButton.buttons}
            >
                {tooltipButton.bodyContent}
            </TooltipDialog>
        )
    }

    return (
        <div className={divWrapperClass}>
            <InputLabel sx={LABEL}>
                {label} {LabelToolTip}
            </InputLabel>
            <div>{children}</div>
        </div>
    )
}

export default FormLabel

import React, { forwardRef } from 'react'
import TextField from '../TextField'
import MenuItemBase from '../../MenuItemBase'
import { COMMON_TEXTFIELD_PROPS, SELECT_TEXTFIELD_PROPS } from '../constants'

const DropdownTextField = forwardRef((props, ref) => {
    const {
        disabled = false,
        options = [],
        isRequired = false,
        opLabel = 'label',
        opValue = 'value',
        emptyLabel = '',
        displayEmpty = false,
        multiple = false,
        loading = false,
        loadingText = 'Loading...',
        renderValue,
        ...restProps
    } = props

    let selectProps = SELECT_TEXTFIELD_PROPS
    selectProps.SelectProps.displayEmpty = displayEmpty
    selectProps.SelectProps.multiple = multiple
    selectProps.SelectProps.renderValue = renderValue

    const loadingOption = (
        <MenuItemBase
            value={null}
            sx={{ fontSize: '12px', minHeight: '30px' }}
            disabled
        >
            {loadingText}
        </MenuItemBase>
    )

    const hasOptions = options.length > 0

    const nullOption = (
        <MenuItemBase
            value={null}
            sx={{ height: '0', padding: '0 16px', display: 'none' }}
        />
    )

    const emptyOption = (
        <MenuItemBase value={''} sx={{ fontSize: '12px', minHeight: '30px' }}>
            {emptyLabel}
        </MenuItemBase>
    )

    const optionList = options.map((option, index) => (
        <MenuItemBase
            key={`${option[opLabel]}-${index}`}
            value={option[opValue]}
            sx={{ fontSize: '12px', minHeight: 'auto' }}
        >
            {option[opLabel]}
        </MenuItemBase>
    ))

    return (
        <TextField
            ref={ref}
            {...restProps}
            select
            disabled={disabled}
            {...COMMON_TEXTFIELD_PROPS}
            {...selectProps}
        >
            {loading && loadingOption}
            {!loading && !hasOptions && nullOption}
            {!loading && !isRequired && displayEmpty && emptyOption}
            {!loading && hasOptions && optionList}
        </TextField>
    )
})

export default DropdownTextField

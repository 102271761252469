import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon, fas } from '../../fontAwesome'
import { Paper } from '@mui/material'
import {
    ClickAwayListener,
    Popper,
    Typography,
    Grow,
    MenuList,
    MenuItem,
    Button,
    IconButton,
} from '../../base'
import {
    INVALID_THEME_VALUE,
    MISSING_THEME_PARAM,
    VALID_THEMES,
    MORE_THAN_ONE_ACTIONABLE_ITEMS,
    MISSING_ACTIONABLE_ITEMS,
} from './constants'
import { TYPOGRAPHY_STYLE } from './styles'

const ActionableButton = ({
    label,
    theme,
    anchorRef,
    handleToggle,
    icon,
    disabled,
    size,
}) => {
    if (label) {
        return (
            <Button
                disabled={disabled}
                theme={theme}
                ref={anchorRef}
                onClick={handleToggle}
                size={size}
                endIcon={
                    <FontAwesomeIcon
                        color="inherit"
                        icon={fas.faAngleDown}
                        style={{ fontSize: '16px', width: '16px' }}
                    />
                }
            >
                {label}
            </Button>
        )
    }

    if (icon) {
        return (
            <div ref={anchorRef}>
                <IconButton
                    onClick={handleToggle}
                    sx={{ color: 'inherit' }}
                    disableRipple
                    size={size || 'small'}
                >
                    <FontAwesomeIcon icon={icon} />
                </IconButton>
            </div>
        )
    }

    throw new Error(MISSING_ACTIONABLE_ITEMS)
}

function ButtonDropdown(props) {
    const { options, label, theme, icon, disabled = false, size } = props
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    if (label && !theme) {
        throw new Error(MISSING_THEME_PARAM)
    }

    if (label && !VALID_THEMES.includes(theme)) {
        throw new Error(INVALID_THEME_VALUE)
    }

    if (icon && label) {
        throw new Error(MORE_THAN_ONE_ACTIONABLE_ITEMS)
    }

    const handleMenuItemClick = (event, index, onClick) => {
        setOpen(false)
        onClick()
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    return (
        <>
            <ActionableButton
                label={label}
                theme={theme}
                anchorRef={anchorRef}
                handleToggle={handleToggle}
                icon={icon}
                disabled={disabled}
                size={size}
            />
            <Popper
                open={open}
                placement={'bottom-start'}
                anchorEl={anchorRef.current}
                transition
                // `disablePortal` removed to prevent zIndex overlapping
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'left bottom',
                            minWidth:
                                anchorRef.current &&
                                anchorRef.current.offsetWidth,
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                menuItemOptions={{
                                                    key: option.label,
                                                    onClick: (event) =>
                                                        handleMenuItemClick(
                                                            event,
                                                            index,
                                                            option.onClick
                                                        ),
                                                }}
                                            >
                                                <Typography
                                                    sx={TYPOGRAPHY_STYLE}
                                                >
                                                    {option.label}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

ButtonDropdown.propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape[
            {
                label: PropTypes.string,
                onClick: PropTypes.func,
            }
        ]
    ),
}

export default ButtonDropdown

import { fas, FontAwesomeIcon } from '../../fontAwesome'
import React from 'react'

const themeBgColors = {
    error: '#FBE2E4',
    info: '#E3F5FC',
    success: '#E0F3ED',
    warning: '#FAEBE7',
}

const themeFontColors = {
    error: '#99002B',
    info: '#007899',
    success: '#007C54',
    warning: '#E67111',
}

const themeTypeLabel = {
    error: 'Error',
    info: 'Info',
    success: 'Success',
    warning: 'Warning',
}

const renderIcon = (icon) => {
    return <FontAwesomeIcon style={{ fontSize: '16px' }} icon={icon} />
}

export const renderStrongText = (text) => {
    if (themeTypeLabel[text]) {
        const upperCaseText = text.charAt(0).toUpperCase() + text.slice(1)
        return <strong>{`${upperCaseText}: `}</strong>
    }
}

const themeIcon = {
    error: renderIcon(fas.faCircleExclamation),
    info: renderIcon(fas.faCircleInfo),
    success: renderIcon(fas.faCheckCircle),
    warning: renderIcon(fas.faExclamationTriangle),
}

export const getThemeElements = (theme) => {
    return {
        background: themeBgColors[theme],
        iconHtml: themeIcon[theme],
        color: themeFontColors[theme],
    }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { mandateProfilePropTypes } from './MandateTypes'
import { formatDateToString } from './helper'
import {
    LinkWrapper,
    EntityTypeText,
    EntityDescText,
    LinkStyled,
} from '../styles'

function MandateGridDisplay({
    mandate,
    displayDate,
    includeClientName,
    includeInvestmentVehicle,
    nameToDisplay,
}) {
    if (!mandate) {
        return <></>
    }
    const {
        client,
        mandate_name: mandateName,
        mandate_id: mandateId,
        investment_vehicle: investmentVehicle,
        mandate_start_date: mandateStartDate,
        mandate_end_date: mandateEndDate,
    } = mandate || {}

    const { Client_Name: clientName } = client || {}
    const mandateNameDisplay = nameToDisplay || mandateName
    let displayName =
        includeClientName && client
            ? `${mandateNameDisplay} (${clientName})`
            : mandateNameDisplay
    displayName +=
        investmentVehicle && includeInvestmentVehicle
            ? ` (investment vehicle = ${investmentVehicle})`
            : ''

    const formattedStartDate = mandateStartDate
        ? formatDateToString(new Date(mandateStartDate))
        : ''
    const formattedEndDate = mandateEndDate
        ? formatDateToString(new Date(mandateEndDate))
        : ''

    return (
        <Grid container>
            <LinkWrapper item container xs={displayDate ? 9 : 12}>
                <EntityTypeText>Mandate: </EntityTypeText>
                <LinkStyled
                    href={`/mandate/${mandateId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Go to Mandate Profile (Mandate ID ${mandateId})`}
                >
                    {displayName}
                </LinkStyled>
            </LinkWrapper>
            {displayDate && (
                <Grid item container xs={3} justifyContent="flex-end">
                    {(formattedStartDate || formattedEndDate) && (
                        <EntityDescText>
                            {`${formattedStartDate} - ${formattedEndDate}`}
                        </EntityDescText>
                    )}
                </Grid>
            )}
        </Grid>
    )
}

MandateGridDisplay.propTypes = {
    mandate: mandateProfilePropTypes,
    displayDate: PropTypes.bool,
}

MandateGridDisplay.defaultProps = {
    mandate: null,
    displayDate: false,
}

export default MandateGridDisplay

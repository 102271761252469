import React from 'react'
import { Grid } from '@mui/material'
import { companyPropType } from './CompanyTypes'
import { EntityTypeText, LinkWrapper, LinkStyled } from '../styles'

function CompanyGridDisplay({ company }) {
    if (!company) {
        return <></>
    }

    const { CompanyID: companyId, CompanyName: companyName = '' } =
        company || {}

    return (
        <Grid container>
            <LinkWrapper item container xs={12}>
                <EntityTypeText>Company: </EntityTypeText>
                <LinkStyled
                    href={`/m/company-profile/${companyId}`}
                    title={`Go to Company Profile (Company ID ${companyId})`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {companyName}
                </LinkStyled>
            </LinkWrapper>
        </Grid>
    )
}

CompanyGridDisplay.propTypes = {
    company: companyPropType.isRequired,
}

export default CompanyGridDisplay

import React from 'react';
import { SortOrderType } from 'types';
import { TableSortLabelStyled } from './styles';

interface Props {
    active?: boolean;
    direction?: SortOrderType;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    sort?: boolean;
}

export const MaterialDataGridSortControls = ({
    active = false,
    direction = 'asc',
    onClick,
    sort = false,
}: Props) => (
    <TableSortLabelStyled
        sort={sort}
        active={active}
        direction={active ? direction : 'asc'}
        onClick={onClick}
    />
);

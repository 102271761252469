import React from 'react'
import { fas, FontAwesomeIcon } from '../../../fontAwesome'
import { COLOR_NAMES } from '../../../themes'

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'

export const getDefaultProps = (error, helperText, format) => {
    return {
        sx: {
            width: '100%',
            '.MuiFormHelperText-root': {
                color: '#d32f2f',
                marginLeft: 0,
            },
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: error && COLOR_NAMES.persianRed,
            },
            '.MuiInputBase-root': {
                fontSize: '12px',
            },
            '.MuiInputBase-input': {
                padding: '8.5px 14px',
            },
            svg: {
                color: COLOR_NAMES.black,
            },
            '.Mui-disabled svg': {
                color: COLOR_NAMES.secondaryLightGray,
            },
        },
        slotProps: {
            textField: {
                helperText: error && helperText,
            },
        },
        slots: {
            openPickerIcon: () => (
                <FontAwesomeIcon
                    icon={fas.faCalendar}
                    style={{ fontSize: '12px' }}
                />
            ),
        },
        format: format || DEFAULT_DATE_FORMAT,
    }
}

export const SecondaryBtn = {
    backgroundColor: '#FFFFFF',
    border: '1px solid #BDBFBF',
    color: '#333333',
    minWidth: '88px',
    minHeight: '30px',
    fontSize: '12px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: `rgba(25, 118, 210, 0.04)`,
        border: `1px solid #BDBFBF`,
    },
}

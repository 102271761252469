import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridDefaultNumberFilter extends AgGridBaseFilter {
    getFilter() {
        return AgGridFilterConstants.FILTER_AG_NUMBER
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_AG_NUMBER
    }
}

export default AgGridDefaultNumberFilter

/**
 * AgGridDefaultNumberFilter Params
 * To use pass AgGridFilterConstants.FILTER_AG_NUMBER to the filter prop of the column definition
 *
 * This is AgGrids default number filter.
 * https://www.ag-grid.com/react-data-grid/filter-number/
 */

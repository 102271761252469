import React, { forwardRef } from 'react'
import { TextareaAutosize as MaterialTextareaAutosize } from '@mui/material'
import styled from 'styled-components'
import { DEFAULT_STYLES, StyledParagraph } from './style'

const StyledTextArea = styled(MaterialTextareaAutosize)`
    ${DEFAULT_STYLES}
`
const TextareaAutosize = forwardRef(
    (
        {
            disabled = false,
            defaultValue,
            error,
            helperText,
            onChange,
            placeholder,
            value,
            minRows = 1,
            maxRows = Infinity,
        },
        ref
    ) => {
        return (
            <div>
                <StyledTextArea
                    ref={ref}
                    placeholder={placeholder}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    value={value}
                    $error={error}
                    minRows={minRows}
                    maxRows={maxRows}
                    disabled={disabled}
                />
                {error && <StyledParagraph>{helperText}</StyledParagraph>}
            </div>
        )
    }
)

export default TextareaAutosize

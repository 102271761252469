import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { personPropTypes } from './PersonTypes'
import { useUser } from '../../../data-provider'
import {
    EntityTypeText,
    LinkWrapper,
    EntityDescText,
    LinkStyled,
} from '../styles'

function PersonGridDisplay({ person }) {
    const { userRedirectURL } = useUser()

    if (!person) {
        return <></>
    }

    const [worksForText, setWorksForText] = useState('')
    const { career, person_id: personId, full_name: fullName } = person

    const personURL = person?.resource_uri || `${userRedirectURL}/${personId}`

    useEffect(() => {
        const { gp, institution } = career[0] || {}
        if (
            institution &&
            institution.inst_fullname !== '' &&
            institution.inst_fullname !== null
        ) {
            setWorksForText(`Institution: ${institution.inst_fullname}`)
        } else if (gp && gp.GeneralPartnerID === 5428) {
            setWorksForText('StepStone Employee')
        } else if (gp) {
            setWorksForText(`GP: ${gp.GP_Name}`)
        } else {
            setWorksForText('Other')
        }
    }, [])

    return (
        <Grid container>
            <LinkWrapper item container xs={9}>
                <EntityTypeText>Person: </EntityTypeText>
                <LinkStyled
                    href={personURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Go to Person Profile (Person ID ${personId})`}
                >
                    {fullName}
                </LinkStyled>
            </LinkWrapper>
            <Grid item container xs={3} justifyContent="flex-end">
                <EntityDescText> ({worksForText})</EntityDescText>
            </Grid>
        </Grid>
    )
}

PersonGridDisplay.propTypes = { person: personPropTypes.isRequired }

export default PersonGridDisplay

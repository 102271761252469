import DropdownFilter from '../../components/Filters/DropdownFilter'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridCustomDropdownFilter extends AgGridBaseFilter {
    getFilter() {
        return DropdownFilter
    }

    isCustomFilter() {
        return true
    }

    correctOptionsTypePassed({ isArray, isPromise, isFunction }) {
        return isPromise || isArray || isFunction
    }

    getFilterType() {
        return 'agTextColumnFilter'
    }

    getCustomFilterParams() {
        return {
            multiple: this.filterParams.multiple ?? false,
            keyFormatter: this.filterParams.keyFormatter ?? this.keyFormatter,
        }
    }
}

export default AgGridCustomDropdownFilter

/**
 * AgGridCustomDropdownFilter Params
 * To use pass AgGridFilterConstants.FILTER_DROPDOWN to the filter prop of the column definition
 *
 * Filter Params:
 * multiple: Optional boolean to set if the dropdown is multiple or not. Default is false.
 *
 * keyFormatter: Optional function to format the value of the option. If not provided, we will search for the
 * key property in the option, if not found, we will use the option itself as the value.
 *
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableSortLabel } from '@material-ui/core';
import { SortOrderType } from 'types';

interface TableSortLabelInterface {
    active: boolean;
    direction: SortOrderType;
    sort: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles(() => ({
    tableSortLabel: ({ sort }: TableSortLabelInterface) =>
        sort
            ? {
                  flexGrow: 1,
              }
            : {
                  visibility: 'hidden',
              },
}));

export const TableSortLabelStyled = (props: TableSortLabelInterface) => {
    const { active, direction, onClick } = props;
    const classes = useStyles(props);

    return (
        <TableSortLabel
            active={active}
            className={classes.tableSortLabel}
            direction={direction}
            onClick={onClick}
        />
    );
};

import React from 'react'
import { FontAwesomeIcon, fas } from '../../../../../../fontAwesome'
import { Grid } from '../../../../../../base'

import SearchPage from './SearchPage'
import { useSearch } from './hooks'
import SearchComponent from './SearchComponent'

const SearchInput = ({
    placeholder,
    loading = false,
    filters: { filterOptions },
    ...props
}) => {
    const {
        searchTerm,
        onChangeSearchTerm,
        selectedFilter,
        handleFilterChange,
        handleCloseSearch,
        isSearchOpen,
        setSearchOpen,
    } = useSearch()

    const toggleSearchPage = () => setSearchOpen(!isSearchOpen)

    return (
        <Grid
            item
            alignItems="center"
            sx={{
                display: 'flex',
                height: '100%',
                maxHeight: '56px',
                justifyContent: {
                    xs: 'end',
                },
            }}
            xs={true}
            sm={3}
            {...props}
        >
            <SearchComponent
                placeholder={placeholder}
                searchTerm={searchTerm}
                onChangeSearchTerm={onChangeSearchTerm}
                toggleSearchPage={toggleSearchPage}
            />
            {isSearchOpen && (
                <SearchPage
                    loading={loading}
                    handleCloseSearch={handleCloseSearch}
                    filterOptions={filterOptions}
                    selectedFilter={selectedFilter}
                    handleFilterChange={handleFilterChange}
                />
            )}
        </Grid>
    )
}

export default SearchInput

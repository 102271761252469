import PropTypes from 'prop-types'

// Institution Third Party Provider
export const thirdPartyProviderPropType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    third_party_provider_category_id: PropTypes.number,
    institution_parents_id: PropTypes.number,
    website: PropTypes.string,
    corporate_registration_number: PropTypes.number,
    legal_entity_identifier: PropTypes.string,
    bloomberg_id: PropTypes.string,
    internal_engagement: PropTypes.number,
    created_by: PropTypes.number,
    created_date_gmt: PropTypes.string,
    modified_by: PropTypes.number,
    modified_date_gmt: PropTypes.string,
})

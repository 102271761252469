import React, { useEffect, useRef, useState } from 'react'
import {
    Avatar,
    Button,
    Divider,
    Grid,
    Paper,
    Popper,
    Typography,
} from '../../../../../../base'
import {
    APP_COLORS,
    APP_TYPOGRAPHY,
    HOVER_COLOR_NAMES,
    SSG_FONT_FAMILY,
} from '../../../../../../themes'
import { useRedirect } from '../../../projects/context'

const Profile = ({ email, full_name, initials, profile_href, logout_href }) => {
    const redirect = useRedirect()
    const [open, setOpen] = useState(false)
    const [hoverButton, setHoverButton] = useState(false)
    const [hoverMenu, setHoverMenu] = useState(false)
    const anchorRef = useRef(null)

    const goToProfile = () => redirect(profile_href)
    const goToLogout = () => redirect(logout_href)

    useEffect(() => {
        if (open && !hoverButton && !hoverMenu) setOpen(false)
    }, [hoverButton, hoverMenu, open])

    return (
        <Grid
            item
            sx={{
                maxHeight: '56px',
                display: 'flex',
                alignItems: 'center',
                width: '56px',
                padding: 0,
            }}
        >
            <Button
                ref={anchorRef}
                id="menu-button-profile"
                aria-controls={open ? 'menu-button-profile' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onMouseLeave={() => {
                    setHoverButton(false)
                }}
                onMouseEnter={() => {
                    setOpen(true)
                    setHoverButton(true)
                }}
                sx={[
                    {
                        borderRadius: '0',
                        borderBottom: '2px solid white',
                        color: APP_COLORS.body,
                        textTransform: 'capitalize',
                        minWidth: 0,
                        height: '56px',
                        width: '56px !important',
                        fontSize: APP_TYPOGRAPHY.body_1.font_size,
                    },
                    open && {
                        backgroundColor: HOVER_COLOR_NAMES.secondaryGray,
                        borderBottom: `2px solid ${APP_COLORS.primary_border}`,
                        color: APP_COLORS.primary_border,
                    },
                ]}
            >
                <Avatar sx={{ width: 32, height: 32, fontSize: '14px' }}>
                    {initials}
                </Avatar>
            </Button>
            <Popper
                placement="bottom"
                id={`menu-button-profile`}
                sx={{
                    position: 'fixed',
                    zIndex: 1300,
                }}
                open={open}
                anchorEl={anchorRef.current}
                onMouseLeave={() => {
                    setHoverMenu(false)
                }}
                onMouseEnter={() => {
                    setHoverMenu(true)
                }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: `300px`,
                        background: 'white',
                        borderRadius: '0',
                        boxShadow: '0 0 8px 0 rgba(51, 51, 51, 0.2)',
                        borderRadius: '4px',
                    }}
                >
                    <Grid
                        container
                        spacing="16px"
                        direction="column"
                        alignItems="center"
                    >
                        <Grid
                            container
                            item
                            spacing="12px"
                            direction="column"
                            alignItems="center"
                            sx={{ marginTop: '16px' }}
                        >
                            <Grid item>
                                <Avatar
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        fontSize: '14px',
                                    }}
                                >
                                    {initials}
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="body_3"
                                    sx={{ fontFamily: SSG_FONT_FAMILY }}
                                >
                                    {full_name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body_3"
                                sx={{ fontFamily: SSG_FONT_FAMILY }}
                            >
                                {email}
                            </Typography>
                        </Grid>
                        {profile_href && (
                            <Grid item>
                                <Button theme="secondary" onClick={goToProfile}>
                                    View Profile
                                </Button>
                            </Grid>
                        )}
                        <Grid item sx={{ width: '100%' }}>
                            <Divider sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item sx={{ marginBottom: '16px' }}>
                            <Button theme="primary" onClick={goToLogout}>
                                Logout
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Popper>
        </Grid>
    )
}

export default Profile

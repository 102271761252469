import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridDefaultSetFilter extends AgGridBaseFilter {
    getFilter() {
        return AgGridFilterConstants.FILTER_AG_SET
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_AG_SET
    }
}

export default AgGridDefaultSetFilter

/**
 * AgGridDefaultSetFilter Params
 * To use pass AgGridFilterConstants.FILTER_AG_SET to the filter prop of the column definition
 *
 * This is AgGrids default set filter.
 * https://www.ag-grid.com/react-data-grid/filter-set/
 */

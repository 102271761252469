import React from 'react'
import { ListItemButton, Typography } from '../../../../../../base'
import { APP_THEME, COLOR_NAMES } from '../../../../../../themes'

const SearchFilterItem = ({
    selected = false,
    index,
    label,
    value,
    onClick,
}) => {
    return (
        <ListItemButton
            selected={selected}
            key={index}
            value={value}
            onClick={(event) => onClick(event, index)}
            button
            sx={APP_THEME.typography.h5}
        >
            <Typography
                variant="body_1"
                style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    color: selected ? COLOR_NAMES.blue : COLOR_NAMES.black,
                }}
            >
                {label}
            </Typography>
        </ListItemButton>
    )
}

export default SearchFilterItem

import React from 'react'
import PropTypes from 'prop-types'
import { fundPropType } from './FundTypes'

const CoverageIconsProps = {
    fund: fundPropType,
}

const CoverageIconsDefaults = {
    fund: null,
}

const SingleIcon = ({ title, image }) => (
    <img
        src={`/images/${image}`}
        alt={title}
        style={{ height: '0.9em' }}
        height={11}
        className="coveragestatusicon"
        title={title}
    />
)

SingleIcon.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
}

const CoverageIcons = ({ fund }) => {
    if (!fund) {
        return <></>
    }
    const {
        fund_summary_doc: fundSummaryDoc,
        investment_review_date_gmt: fundReviewDate,
        investment_memo_doc: investmentMemoDoc,
        track_record: trackRecord,
        fingerprint,
        aida_fund_id: aidaFundId,
    } = fund
    const { doc_date: investmentMemoDate } = investmentMemoDoc || {}
    const { iconFile, access_id: accessId } = trackRecord || {}

    // Default values represents a fingerprint for a AIDA fund
    let trackRecordTitle = 'Fingerprint Available'
    let trackRecordIconUrl = 'coverage-tr-1-20px.png'

    // Check if the track record is a public or client submitted track record
    if (trackRecord) {
        trackRecordTitle =
            accessId === 3
                ? 'Track Record Available'
                : 'Client-Submitted Track Record Available.  Email SPI Team for access.'
        trackRecordIconUrl = iconFile
    }

    return (
        <div style={{ whiteSpace: 'nowrap', display: 'flex' }}>
            {(fundSummaryDoc || aidaFundId) && (
                <SingleIcon
                    title="Fund Summary Available"
                    image="coverage-fs-1-20px.png"
                />
            )}
            {fundReviewDate && (
                <SingleIcon
                    title="Fund Review Available"
                    image="coverage-ir-1-20px.png"
                />
            )}
            {investmentMemoDate && (
                <SingleIcon
                    title="Investment Memo Available"
                    image="coverage-memo-1-20px.png"
                />
            )}
            {(trackRecord || fingerprint) && (
                <SingleIcon
                    title={trackRecordTitle}
                    image={trackRecordIconUrl}
                />
            )}
        </div>
    )
}

CoverageIcons.propTypes = CoverageIconsProps
CoverageIcons.defaultProps = CoverageIconsDefaults

export default CoverageIcons

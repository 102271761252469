import { FiltersType, ResultData, ResultsUri, SortType } from 'types';

const resultToString = (result: ResultData): ResultsUri => {
    const resultingArray: string[] = [];
    resultingArray.push(`page=${result.page}`);
    resultingArray.push(`perPage=${result.perPage}`);
    if (result.sort) {
        resultingArray.push(`sort=${result.sort}`);
    }
    const filter = result?.filter;
    const fieldKeys = Object.keys(filter || {});
    if (filter && fieldKeys.length > 0) {
        fieldKeys.forEach((field) => {
            const fieldFilters = filter[field];
            const comparators = Object.keys(fieldFilters || {});
            if (comparators.length === 0) {
                return;
            }
            comparators.forEach((comparator) => {
                const values = fieldFilters[comparator];
                resultingArray.push(
                    `filter[${field}][${comparator}]=${values}`,
                );
            });
        });
    }

    return `?${resultingArray.join('&')}`;
};

export const convertToJsonApi = (
    page: number = 1,
    perPage: number = 10,
    sort?: SortType,
    filters?: FiltersType,
    rawData = false,
): ResultData | ResultsUri => {
    const result = {
        sort: '',
        filter: {},
        page,
        perPage,
    };
    if (sort) {
        result.sort = `${sort?.order === 'desc' ? '-' : ''}${sort?.by}`;
    } else {
        delete result.sort;
    }
    const fieldFilters = Object.values(filters || {});
    if (filters) {
        result.filter = {};
        fieldFilters.forEach((filter) => {
            // No Filters
            const {
                field,
                filterOperator: fieldOperator,
                filters: theseFilters,
            } = filter;
            if (theseFilters.length === 0) {
                return;
            }
            result.filter[field] = {};
            theseFilters.forEach((thisFilter) => {
                const {
                    type: { comparator, inputValue },
                    value,
                } = thisFilter;
                const thisComparator = `${fieldOperator}.${comparator}`;
                const displayValue = inputValue ? encodeURI(value) : true;
                if (
                    !Object.prototype.hasOwnProperty.call(
                        result.filter[field],
                        thisComparator,
                    )
                ) {
                    result.filter[field][thisComparator] = displayValue;
                } else {
                    result.filter[field][thisComparator] += `,${displayValue}`;
                }
            });
        });
    } else {
        delete result.filter;
    }

    return rawData ? result : resultToString(result);
};

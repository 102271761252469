import firstNWords from './firstNWords'

/**
 * @param text
 * @param numberWords
 * @param suffix
 * @returns {string}
 */
const truncateWithSuffix = (text, numberWords, suffix) => {
    const firstWords = firstNWords(text, numberWords)

    const firstWordsArray = firstWords.split(' ')
    const firstWordsArrayLength = firstWordsArray.length

    const textNumberOfWords = text.split(' ').length

    const isTruncated = textNumberOfWords > firstWordsArrayLength

    const joinedText = firstWordsArray.join(' ')
    const trimmedJoinedText = joinedText.trim()

    return isTruncated ? trimmedJoinedText + suffix : trimmedJoinedText
}

export default truncateWithSuffix

import React, { forwardRef } from 'react'
import TextField from '../TextField'
import { COMMON_TEXTFIELD_PROPS, DEFAULT_TEXTFIELD_PROPS } from '../constants'

const InputTextField = forwardRef((props, ref) => {
    const { disabled, ...restProps } = props

    return (
        <TextField
            ref={ref}
            disabled={disabled}
            {...restProps}
            {...DEFAULT_TEXTFIELD_PROPS}
            {...COMMON_TEXTFIELD_PROPS}
        />
    )
})

export default InputTextField

import React, { Component } from 'react'
import DropdownTextField from '../../../../base/TextField/variants/DropdownTextField'
import AgGridFilterModelBuilder from '../../utils/models/AgGridFilterModelBuilder'

class ConditionalDropdownFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            options: [],
            selectedValue: '',
        }

        this.emptyLabel = props.emptyLabel ?? ''

        props.options.then((options) => {
            this.setState({
                options,
            })
        })
    }

    isFilterActive = () => {
        const filterActive =
            this.state.selectedValue !== null &&
            this.state.selectedValue !== undefined &&
            this.state.selectedValue !== ''

        return filterActive
    }

    doesFilterPass = (params) => {
        const filterPassed =
            this.state.selectedValue === null ||
            this.state.selectedValue === undefined ||
            this.state.selectedValue === '' ||
            params.value === this.state.selectedValue

        return this.state.selectedValue === params.value
    }

    getModel = () => {
        if (this.state.selectedValue === '') return undefined

        const selectedModel = this.props.conditionalModels.find(
            (option) => option.key === this.state.selectedValue
        )

        const modelBuilder = new AgGridFilterModelBuilder()

        modelBuilder.setFilter(selectedModel.filter)
        modelBuilder.setFilterType(selectedModel.condition)
        modelBuilder.setType(selectedModel.type)

        return modelBuilder.build()
    }

    setModel = (model, param1, param2) => {
        if (model === null) {
            this.setSelectedValue('')
            return
        }
    }

    setSelectedValue = (selectedValue) => {
        this.setState(
            {
                selectedValue,
            },
            () => this.props.filterChangedCallback()
        )
    }

    onChange = (event) => {
        this.setSelectedValue(event.target.value)
    }

    render = () => {
        return (
            <DropdownTextField
                {...this.props}
                displayEmpty
                multiple={this.props.multiple}
                placeholder={this.placeholder}
                value={this.state.selectedValue}
                onChange={this.onChange}
                options={this.state.options}
            />
        )
    }
}

export default ConditionalDropdownFilter

import PropTypes from 'prop-types'

// Company
export const gicsType = PropTypes.shape({
    Rowid: PropTypes.number,
    GICS_Sector: PropTypes.string,
    GICS_SubSector: PropTypes.string,
    sort_index: PropTypes.number,
})

export const companyPropType = PropTypes.shape({
    CompanyID: PropTypes.number,
    CompanyName: PropTypes.string,
    Country: PropTypes.string,
    Creation_Date: PropTypes.string,
    Description: PropTypes.string,
    Status: PropTypes.string,
    Ticker: PropTypes.string,
    Website: PropTypes.string,
    Website_Thumb: PropTypes.number,
    Website_Thumb_Date: PropTypes.string,
    Website_Thumb_ErrCode: PropTypes.number,
    Website_Thumb_Fail_Count: PropTypes.number,
    ciq_company_id: PropTypes.string,
    gics: gicsType,
    gics_rowid: PropTypes.number,
    parish_direct_id: PropTypes.string,
})

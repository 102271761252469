import React from 'react'
import { IconButton, Typography, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SearchPageContainer } from '../styles'
import SearchFilters from './SearchFilters'
import SearchResultLists from './SearchResultLists'
import { COLOR_GRAY } from '../../../../themes'
import LoadingContainer from '../../LoadingContainer/LoadingContainer'

function SearchPageView(props) {
    const {
        searchResults,
        resultsFilter,
        setResultsFilter,
        updateResultSwitch,
        setUpdateResultSwitch,
        handleCloseSearch,
        loading,
        screenSize,
    } = props

    return (
        <SearchPageContainer screenSize={screenSize}>
            <Grid container style={{ paddingTop: '32px' }}>
                <Grid item xs={3} sx={{ marginTop: '20px' }}>
                    <SearchFilters
                        resultsFilter={resultsFilter}
                        setResultsFilter={setResultsFilter}
                        updateResultSwitch={updateResultSwitch}
                        setUpdateResultSwitch={setUpdateResultSwitch}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="column" alignItems="flex-start">
                        <Typography
                            variant="h6"
                            sx={{
                                fontFamily: "'Open Sans'",
                                fontWeight: '600',
                                color: COLOR_GRAY['900'],
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                                paddingLeft: '39px',
                            }}
                        >
                            Search Results
                        </Typography>
                    </Grid>
                    <LoadingContainer loading={loading}>
                        <SearchResultLists searchResults={searchResults} />
                    </LoadingContainer>
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction="column" alignItems="center">
                        <IconButton onClick={handleCloseSearch}>
                            <CloseIcon sx={{ color: '#444', fontSize: 40 }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </SearchPageContainer>
    )
}

export default SearchPageView

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { APP_TYPOGRAPHY } from '../../themes/SsgTheme'
import { Button, Collapse } from '../../base'
import { Alert as AlertMaterial } from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import styled, { css } from 'styled-components'

function Alert({ variant, type, action, handleClose, children }) {
    const [open, setOpen] = useState(true)
    const props = { customvariant: variant, type }

    useEffect(() => {
        let timeout = null
        if (type === 'toast') {
            timeout = setTimeout(() => setOpen(false), 5000)
        }

        return () => {
            if (type === 'toast') {
                clearTimeout(timeout)
            }
        }
    }, [])

    function DisplayedAlert() {
        let alertComponents = getAlertComponents()
        let alertTypeData = getAlertTypeData()

        let IconComponent = alertComponents.icon
        let onCloseProp = alertTypeData.onCloseProp

        return (
            <AlertComponent
                onClose={onCloseProp}
                icon={IconComponent}
                {...props}
            >
                <StyledAlertTypeText>
                    {alertData[variant].typeText}:{' '}
                </StyledAlertTypeText>
                {children}
                {action !== null && (
                    <DivComponentButton>
                        <ButtonAlertComponent
                            onClick={action.handleClick}
                            {...props}
                        >
                            {action.buttonText}
                        </ButtonAlertComponent>
                    </DivComponentButton>
                )}
            </AlertComponent>
        )
    }

    function getAlertTypeData() {
        let alertTypeInfo = {}
        let onCloseProp = undefined

        if (type === 'toast' || type === 'embed' || type === 'stripe') {
            onCloseProp = () => {
                handleCloseAction()
            }
        }

        alertTypeInfo.onCloseProp = onCloseProp

        return alertTypeInfo
    }

    function handleCloseAction() {
        setOpen(false)
        if (handleClose !== null) {
            handleClose()
        }
    }

    function getAlertComponents() {
        let icon = null
        let alertObject = {}

        switch (variant) {
            case 'info':
                icon = <InfoStyledIcon {...props} />
                break
            case 'warning':
                icon = <WarningStyledIcon {...props} />
                break
            case 'error':
                icon = <ErrorStyledIcon {...props} />
                break
            case 'success':
                icon = <SuccessStyledIcon {...props} />
                break
            default:
                icon = <InfoStyledIcon {...props} />
        }
        alertObject.icon = icon

        return alertObject
    }

    return (
        <Collapse in={open}>
            <DisplayedAlert />
        </Collapse>
    )
}

const alertDefaultProps = {
    variant: 'info',
    type: 'embed',
    action: null,
    handleClose: null,
}
Alert.defaultProps = {
    ...alertDefaultProps,
}

Alert.propTypes = {
    variant: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    action: PropTypes.shape({
        buttonText: PropTypes.string.isRequired,
        handleClick: PropTypes.func.isRequired,
    }),
    handleClose: PropTypes.func,
}

export default Alert

const alertData = {
    info: {
        textColor: '#007899',
        borderColor: '#2DAEE1',
        bgColor: '#E3F5FC',
        typography: APP_TYPOGRAPHY.body_font,
        typeText: 'Info',
    },
    warning: {
        textColor: '#B25408',
        borderColor: '#E67111',
        bgColor: '#FAEBE7',
        typography: APP_TYPOGRAPHY.body_font,
        typeText: 'Warning',
    },
    error: {
        textColor: '#99002B',
        borderColor: '#CD1D2D',
        bgColor: '#FBE2E4',
        typography: APP_TYPOGRAPHY.body_font,
        typeText: 'Error',
    },
    success: {
        textColor: '#007C54',
        borderColor: '#00996E',
        bgColor: '#E0F3ED',
        typography: APP_TYPOGRAPHY.body_font,
        typeText: 'Success',
    },
}

const DivComponentButton = styled.div`
    margin-top: 25px;
    margin-bottom: 5px;
`

const ButtonAlertComponent = styled(Button)`
    background-color: #ffffff;
    color: ${(props) => alertData[props.customvariant].textColor};
    border: ${(props) =>
        '1px solid ' + alertData[props.customvariant].textColor};
    text-transform: none;
    font-weight: 400;
    margin-left: -7px;
    padding: 5px 15px;
`

const AlertComponent = styled(AlertMaterial)`
    color: ${(props) => alertData[props.customvariant].textColor};
    border-bottom: ${(props) => alertData[props.customvariant].borderColor};
    align-items: flex-start;
    border-radius: 0px;
    background-color: ${(props) =>
        props.type === 'indicator'
            ? 'transparent'
            : alertData[props.customvariant].bgColor};
    ${(props) =>
        props.type === 'toast' &&
        css`
            border: 1px solid ${alertData[props.customvariant].borderColor};
            right: 24px;
            top: 24px;
            min-width: 100%;
            margin-bottom: 16px;
        `}
    border-bottom: ${(props) =>
        props.type === 'stripe' &&
        '1px solid ' + alertData[props.customvariant].borderColor};
`

const StyledAlertTypeText = styled.span`
    font-weight: 700;
    margin-left: -5px;
    margin-right: 4px;
`

const InfoStyledIcon = styled(InfoRoundedIcon)`
    fill: ${(props) => alertData[props.customvariant].textColor};
    font-size: 16px;
    margin-top: 2px;
`

const WarningStyledIcon = styled(WarningRoundedIcon)`
    fill: ${(props) => alertData[props.customvariant].textColor};
    font-size: 16px;
    margin-top: 2px;
`

const ErrorStyledIcon = styled(ErrorRoundedIcon)`
    fill: ${(props) => alertData[props.customvariant].textColor};
    font-size: 16px;
    margin-top: 2px;
`

const SuccessStyledIcon = styled(CheckCircleRoundedIcon)`
    fill: ${(props) => alertData[props.customvariant].textColor};
    font-size: 16px;
    margin-top: 2px;
`

export const formControlLabelStyle = {
    height: '38px',
    width: '100%',
    paddingLeft: '10px',
    borderRadius: '4px 4px 0px 0px',
    border: '1px 1px 0px 1px',
    gap: '10px',
    '&:hover': {
        backgroundColor: '#E5F1F5',
    },
}

export const checkboxStyle = {
    width: '18px',
    height: '18px',
    fontSize: '12px',
    borderRadius: '2px',
    border: '1px',
    color: '#007899',
    '&.Mui-checked': {
        color: '#007899',
    },
    '&.MuiCheckbox-indeterminate': {
        color: '#007899',
    },
}

export const headerStyle = {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
}

export const searchContainerStyle = {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center',
}

export const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
}

export const buttonStyle = {
    width: '88px',
    height: '38px',
    padding: '8px 16px 8px 16px',
    borderRadius: '4px',
    gap: '10px',
}

export const containedButtonStyle = {
    border: '1px',
    backgroundColor: '#007899',
}

export const closeIconStyle = {
    fontSize: '20px',
    cursor: 'pointer',
}

import React from 'react'
import {
    Box,
    IconButton,
    ImageList,
    ImageListItem,
    Link,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@mui/material'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { COLOR_NAMES } from '../../../../themes'

const HelpMenu = (props) => {
    const { id, open, anchorEl, helpLinks, handleOpen } = props

    return (
        <>
            <IconButton
                size="large"
                sx={{ color: '#444' }}
                aria-describedby={id}
                onClick={(e) => {
                    handleOpen(true, e, true)
                }}
                onMouseEnter={(e) => {
                    handleOpen(true, e)
                }}
            >
                <HelpRoundedIcon />
            </IconButton>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                disablePortal={true}
                sx={{ zIndex: 1305 }}
                placement="bottom-end"
            >
                <Paper sx={{ padding: '0px 10px' }}>
                    <MenuList key={id} sx={{ width: '100%' }}>
                        <ImageList cols={1} gap={0}>
                            {helpLinks.map((item, index) => {
                                return (
                                    <ImageListItem key={index}>
                                        <Box>
                                            <Link
                                                href={item?.href}
                                                onClick={item?.onClick}
                                                underline="none"
                                            >
                                                <MenuItem
                                                    sx={{
                                                        padding: '5px',
                                                    }}
                                                >
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '14px !important',
                                                                fontWeight:
                                                                    '100',
                                                                color: COLOR_NAMES.black,
                                                            }}
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            </Link>
                                        </Box>
                                    </ImageListItem>
                                )
                            })}
                        </ImageList>
                    </MenuList>
                </Paper>
            </Popper>
        </>
    )
}

export default HelpMenu

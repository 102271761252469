import { COLOR_NAMES } from '../../themes'
import styled from 'styled-components'

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

export const Container = styled('div')`
    display: flex;
    flex: 1;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid ${COLOR_NAMES.secondaryLightGray};
`

export const Dropzone = styled('div')`
    display: flex;
    padding: 24px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed ${COLOR_NAMES.blue};
`

export const Content = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export const P = styled('p')`
    text-align: center;
    margin: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`

export const MaxSize = styled('p')`
    font-size: 12px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: ${COLOR_NAMES.darkGray};
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`

export const FileList = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const FileRowContainer = styled('div')`
    display: flex;
    width: 100%;
`

export const FileRowContent = styled('div')`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

export const FileRowName = styled('p')`
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 12px;
    color: black;
`
export const ErrorContainer = styled('div')`
    display: flex;
    width: 100%;
`

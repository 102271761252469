export const mockNavLinks = [
    {
        heading: 'Research',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        subMenus: [
            {
                heading: 'Header Sub Heading',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
        ],
    },
    {
        heading: 'Screening',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        subMenus: [
            {
                heading: 'Sub Heading',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
            {
                heading: 'Sub Heading 2',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Sub 2 Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Sub 2 Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
        ],
    },
    {
        heading: 'Tools',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        subMenus: [
            {
                heading: 'Sub Heading',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
            {
                heading: 'Sub Heading 2',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Sub 2 Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Sub 2 Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
        ],
    },
    {
        heading: 'Deals',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        subMenus: [
            {
                heading: 'Sub Heading',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
            {
                heading: 'Sub Heading 2',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Sub 2 Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Sub 2 Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
        ],
    },
    {
        heading: 'Management',
        hiddenOnMobile: false,
        hiddenOnTablet: false,
        hiddenOnDesktop: false,
        subMenus: [
            {
                heading: 'Sub Heading',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
            {
                heading: 'Sub Heading 2',
                hiddenOnMobile: false,
                hiddenOnTablet: false,
                hiddenOnDesktop: false,
                links: [
                    {
                        heading: 'Sub 2 Item 1',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                    {
                        heading: 'Sub 2 Item 2',
                        url: 'https://google.com',
                        hiddenOnMobile: false,
                        hiddenOnTablet: false,
                        hiddenOnDesktop: false,
                    },
                ],
            },
        ],
    },
]

export const mockAppLinks = [
    {
        title: 'SPI',
        href: 'https://spi.stepstonegroup.com',
    },
    {
        title: 'Omni',
        href: 'https://omni.stepstonegroup.com',
    },
]

export const mockHelpLinks = [
    {
        title: 'Report An Issue',
        href: 'https://google.com',
    },
    {
        title: 'Request A Feature',
        href: 'mailto:spi@stepstonegroup.com?subject=SPI',
    },
]

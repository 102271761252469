import PropTypes from 'prop-types'

export const CommentItemPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    initials: PropTypes.string.isRequired,
    profile_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    created_by: PropTypes.number.isRequired,
})

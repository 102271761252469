import React from 'react'
import { Grid } from '@mui/material'
import { secondariesPropType } from './SecondaryTypes'
import {
    EntityTypeText,
    LinkWrapper,
    EntityDescText,
    LinkStyled,
} from '../styles'

function SecondaryGridDisplay({ secondary }) {
    if (!secondary) {
        return <></>
    }

    const {
        date_received: dateReceived,
        second_id: secondId,
        code_name: codeName,
    } = secondary

    const date = new Date(dateReceived)
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    })
    const [{ value: month }, , { value: day }, , { value: year }] =
        dateTimeFormat.formatToParts(date)
    const dateFormatted = `${year}-${month}-${day}`

    return (
        <Grid container>
            <LinkWrapper item container xs={9}>
                <EntityTypeText>Secondary: </EntityTypeText>
                <LinkStyled
                    href={`/secondary_profile.php?id=${secondId}`}
                    title={`Go to Secondary Profile (Second ID ${secondId})`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {codeName}
                </LinkStyled>
            </LinkWrapper>
            <Grid item container xs={3} justifyContent="flex-end">
                <EntityDescText> (Received {dateFormatted})</EntityDescText>
            </Grid>
        </Grid>
    )
}

SecondaryGridDisplay.propTypes = {
    secondary: secondariesPropType,
}

SecondaryGridDisplay.defaultProps = { secondary: null }

export default SecondaryGridDisplay

import React, { cloneElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const FormController = (props) => {
    const { control } = useFormContext()
    const { children, name, options } = props

    return (
        <Controller
            name={name}
            control={control}
            render={(controlProps) =>
                cloneElement(children, {
                    ...controlProps,
                    name,
                    options,
                })
            }
        />
    )
}

export default FormController

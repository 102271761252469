import { useEffect, useState } from 'react';
import { buildComparer } from 'helpers';
import {
    Comparer,
    Sorter,
    SortType,
    FiltersType,
    PaginationType,
    SortOrderType,
    HandleSortFunction,
    ServerPaginationType,
    SetFiltersFunction,
    ClearFiltersFunction,
    HandleRequestedDataChange,
} from 'types';
import { usePagination } from './usePagination';
import { useSort } from './useSort';
import { useFilters } from './useFilters';

type UseDataProps = {
    customSorter?: Sorter;
    data?: Object[];
    defaultPerPage?: number;
    getComparisonValue?: Comparer;
    paginated: boolean;
    perPageOptions: number[];
    serverSideProcessing?: boolean;
    handleRequestedDataChange?: HandleRequestedDataChange;
    serverPagination?: ServerPaginationType;
    defaultSortBy?: string;
    defaultSortOrder?: SortOrderType;
};

type UseDataGridResponse = {
    data: Array<Object> | undefined;
    pagination: PaginationType;
    sort: SortType;
    handleSort: HandleSortFunction;
    filters: FiltersType;
    setFilters: SetFiltersFunction;
    clearFilters: ClearFiltersFunction;
};

/**
 * Hook for data Grid
 * @param customSorter        A custom sorter function to use
 * @param data                The raw data to use
 * @param defaultPerPage      Default per page value
 * @param getComparisonValue  Function to get comparisons to use for filter/sort
 * @param sort                Sort Value { by: 'field', order: 'asc' }
 * @param paginated           Whether or not to paginate
 * @param perPageOptions      The per page options
 */
export const useDataGrid = ({
    customSorter,
    data,
    defaultPerPage = 10,
    getComparisonValue,
    paginated,
    perPageOptions,
    defaultSortBy = 'id',
    defaultSortOrder = 'asc',
    serverSideProcessing = false,
    handleRequestedDataChange = () => {},
    serverPagination = undefined,
}: UseDataProps): UseDataGridResponse => {
    const [sort, handleSort] = useSort(defaultSortBy, defaultSortOrder);
    const [filters, setFilters, clearFilters] = useFilters();
    const [sortedData, setSortedData] = useState([]);
    const [pagination, setTotal] = usePagination(
        paginated,
        sortedData,
        defaultPerPage,
        perPageOptions,
        data,
        serverSideProcessing,
        serverPagination,
    );

    const { page, perPage } = pagination;

    // TODO: Convert this to useCallback
    // Builds a comparer using inputs
    const [filterData, sortData] = buildComparer(
        filters,
        sort,
        customSorter,
        getComparisonValue,
    );

    // Re-apply filter, sort, and pagination if any value changes
    useEffect(() => {
        if (serverSideProcessing) {
            return;
        }
        if (
            sortedData.length === 0 &&
            (!Array.isArray(data) || data?.length === 0)
        ) {
            return;
        }
        let newSortedData = [...(Array.isArray(data) ? data : [])]
            .filter(filterData)
            .sort(sortData);

        setTotal(newSortedData.length);

        if (paginated) {
            const sliceStart = (page - 1) * perPage;
            newSortedData = newSortedData.slice(
                sliceStart,
                sliceStart + perPage,
            );
        }

        // @ts-ignore
        setSortedData(newSortedData);
    }, [data, filters, sort, page, perPage, paginated]);

    useEffect(() => {
        if (!serverSideProcessing) {
            return;
        }
        handleRequestedDataChange(page, perPage, sort, filters);
    }, [filters, sort, page, perPage]);

    return {
        data: serverSideProcessing ? data : sortedData,
        pagination,
        sort,
        handleSort,
        filters,
        setFilters,
        clearFilters,
    };
};

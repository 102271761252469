function HighchartsConfig(Highcharts) {
    Highcharts.dateFormats = {
        q: function (timestamp) {
            const date = new Date(timestamp),
                quarter = Math.floor(date.getUTCMonth() / 3) + 1

            return quarter
        },
    }
}

export default HighchartsConfig

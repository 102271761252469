import React from 'react'
import FundGridDisplay from './SearchLabelGrid/Fund/FundGridDisplay'
import GpGridDisplay from './SearchLabelGrid/GeneralPartner/GpGridDisplay'
import CompanyGridDisplay from './SearchLabelGrid/Company/CompanyGridDisplay'
import CoinvestGridDisplay from './SearchLabelGrid/CoInvestment/CoinvestGridDisplay'
import InstitutionGridDisplay from './SearchLabelGrid/Institution/InstitutionGridDisplay'
import ThirdPartyProviderGridDisplay from './SearchLabelGrid/ThirdPartyProvider/ThirdPartyProviderGridDisplay'
import InstitutionIntermediaryGridDisplay from './SearchLabelGrid/InstitutionIntermediary/InstitutionIntermediaryGridDisplay'
import SecondaryGridDisplay from './SearchLabelGrid/Secondary/SecondaryGridDisplay'
import PersonGridDisplay from './SearchLabelGrid/Person/PersonGridDisplay'
import ClientGridDisplay from './SearchLabelGrid/Client/ClientGridDisplay'
import MandateGridDisplay from './SearchLabelGrid/Mandate/MandateGridDisplay'

export const getName = (data) => {
    switch (data.type) {
        case 'coinvest':
            return data.project_name
        case 'client':
            return data.Client_FullName
        case 'mandate':
            return data.mandate_name
        case 'GP':
            return data.GP_Name
        case 'fund':
            return data.Fund_Name
        case 'institution':
            return data.inst_fullname
        case 'thirdPartyProvider':
            return data.name
        case 'company':
            return data.CompanyName
        case 'person':
            return data.full_name
        case 'secondary':
            return data.code_name
        default:
            return ''
    }
}

export const convertResponseToOptions = (response) => {
    const institutionIntermediaries = response?.institutionIntermediaries || []
    return [
        ...response.funds.map((fund) => ({
            ...fund,
            id: fund.FundID,
            type: 'fund',
            render: <FundGridDisplay fund={fund} />,
        })),
        ...response.generalPartners.map((generalPartner) => ({
            ...generalPartner,
            id: generalPartner.GeneralPartnerID,
            type: 'GP',
            render: <GpGridDisplay gp={generalPartner} />,
        })),
        ...response.companies.map((company) => ({
            ...company,
            id: company.CompanyID,
            type: 'company',
            render: <CompanyGridDisplay company={company} />,
        })),
        ...response.coInvestments.map((coInvestment) => ({
            ...coInvestment,
            id: coInvestment.coinvest_id,
            type: 'coinvest',
            render: <CoinvestGridDisplay coinvest={coInvestment} />,
        })),
        ...response.secondaries.map((secondaries) => ({
            ...secondaries,
            id: secondaries.second_id,
            type: 'secondary',
            render: <SecondaryGridDisplay secondary={secondaries} />,
        })),
        ...response.institutions.map((institution) => ({
            ...institution,
            id: institution.inst_id,
            type: 'institution',
            render: <InstitutionGridDisplay institution={institution} />,
        })),
        ...response.thirdPartyProviders.map((thirdPartyProvider) => ({
            ...thirdPartyProvider,
            id: thirdPartyProvider.id,
            type: 'thirdPartyProvider',
            render: (
                <ThirdPartyProviderGridDisplay
                    thirdPartyProvider={thirdPartyProvider}
                />
            ),
        })),
        ...institutionIntermediaries.map((intermediary) => ({
            ...intermediary,
            id: intermediary.id,
            type: 'intermediaryProvider',
            render: (
                <InstitutionIntermediaryGridDisplay
                    intermediaryProvider={intermediary}
                />
            ),
        })),
        ...response.clients.map((client) => ({
            ...client,
            id: client.ClientID,
            type: 'client',
            render: <ClientGridDisplay client={client} />,
        })),
        ...response.mandates.map((mandate) => ({
            ...mandate,
            id: mandate.mandate_id,
            type: 'mandate',
            render: <MandateGridDisplay mandate={mandate} displayDate />,
        })),
        ...response.persons.map((person) => ({
            ...person,
            id: person.person_id,
            type: 'person',
            render: <PersonGridDisplay person={person} />,
        })),
    ]
}

import React from 'react'
import { Slide, List, ListItem, ListItemText } from '@mui/material'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import stepStoneLogo from '../../../../../assets/images/stepstone.png'
import { COLOR_NAMES } from '../../../../themes'

const SidebarDropdown = (props) => {
    const {
        selectDrawer,
        setSelectDrawer,
        dropdownOptions,
        length,
        mediumScreens,
        menuType,
    } = props

    return (
        <Slide
            direction="right"
            in={selectDrawer === length}
            mountOnEnter
            unmountOnExit
            timeout={10}
            key="menu items"
        >
            <List component="nav">
                <ListItem
                    button
                    onClick={() => {
                        setSelectDrawer(1)
                    }}
                    sx={{ marginTop: mediumScreens ? '125px' : '60px' }}
                >
                    <ChevronLeftRoundedIcon />
                    <ListItemText>Main Menu</ListItemText>
                </ListItem>
                <div>
                    {dropdownOptions.map((item) => {
                        return (
                            <ListItem
                                key={item.heading || item.title}
                                button
                                component="a"
                                href={item.url || item.href}
                                onClick={item?.onClick}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {menuType === 'app' && (
                                    <img
                                        src={stepStoneLogo}
                                        width="30"
                                        height="30"
                                        alt={item.heading || item.title}
                                    />
                                )}
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: '14px',
                                    }}
                                    sx={{
                                        paddingLeft: '1rem',
                                        color: COLOR_NAMES.black,
                                    }}
                                >
                                    {item.heading || item.title}
                                </ListItemText>
                            </ListItem>
                        )
                    })}
                </div>
            </List>
        </Slide>
    )
}

export default SidebarDropdown

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { getDefaultProps } from '../constants'
import { formatDate } from '../utils'

const Datepicker = forwardRef(
    ({ error, format, helperText, onChange, value, disabled }, ref) => {
        const DEFAULT_PROPS = getDefaultProps(error, helperText, format)
        const inputValue = value ? dayjs(value) : null

        const onChangeHandler = (date) => {
            const formattedDate = formatDate(date, format)
            if (formattedDate) {
                onChange(formattedDate)
            }
        }

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    ref={ref}
                    disabled={disabled}
                    onChange={onChangeHandler}
                    value={inputValue}
                    {...DEFAULT_PROPS}
                />
            </LocalizationProvider>
        )
    }
)

Datepicker.propTypes = {
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    helperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
}

Datepicker.defaultProps = {
    error: false,
    disabled: false,
    format: 'MM/DD/YYYY',
    helperText: '',
    onChange: false,
    value: '',
}

export default Datepicker

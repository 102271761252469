import { useState } from 'react';
import { SortType, SortOrderType, HandleSortFunction } from 'types';

/**
 *
 * @param defaultSortBy     Default field to sort by
 * @param defaultSortOrder  Default sort order, asc|desc
 * @param handleSortUpdate  Sort update handler
 */
export const useSort = (
    defaultSortBy: string = '',
    defaultSortOrder: SortOrderType = 'asc',
    handleSortUpdate: Function = () => {},
): [SortType, HandleSortFunction] => {
    const [sort, setSortValues] = useState({
        by: defaultSortBy,
        order: defaultSortOrder,
    });

    const setSort = (newSortBy: string, newSortOrder: SortOrderType) => {
        const newSort: SortType = {
            by: newSortBy,
            order: newSortOrder === 'asc' ? 'asc' : 'desc',
        };
        setSortValues(newSort);
        handleSortUpdate(newSort);
    };

    const handleSort = (sortBy) => {
        const { by, order } = sort;
        let newSortOrder = defaultSortOrder;
        if (by === sortBy) {
            newSortOrder = order === 'asc' ? 'desc' : 'asc';
        }
        setSort(sortBy, newSortOrder);
    };

    return [sort, handleSort];
};

import AutoCompleteFilter from '../../components/Filters/AutoCompleteFilter'
import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridBaseFilter from './AgGridBaseFilter'

const DEFAULT_DEBOUNCE_MS = 500
const DEFAULT_MINIMUM_INPUT_LENGTH = 2

class AgGridCustomAutoCompleteFilter extends AgGridBaseFilter {
    getFilter() {
        return AutoCompleteFilter
    }

    correctOptionsTypePassed({ isArray, isPromise, isFunction }) {
        const correctOptionsTypePassed = (isPromise || isFunction) && !isArray

        if (!correctOptionsTypePassed) {
            console.error(
                'Incorrect options passed to AgGridCustomAutoCompleteFilter, options must be a promise or a function'
            )
        }

        return correctOptionsTypePassed
    }

    /**
     * Overriden so if a function is passed it will not be called on first render.
     * This will only be called by the component when the user types something.
     */
    getOptionsFromFunction(options) {
        return options
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_AUTO_COMPLETE
    }

    isCustomFilter() {
        return true
    }

    getCustomFilterParams() {
        return {
            multiple: this.filterParams.multiple ?? true,
            optionPromise: this.filterParams.options,
            keyFormatter: this.filterParams.keyFormatter ?? this.keyFormatter,
            debounceMs: this.filterParams.debounceMs ?? DEFAULT_DEBOUNCE_MS,
            minimumInputLength:
                this.filterParams.minimumInputLength ??
                DEFAULT_MINIMUM_INPUT_LENGTH,
        }
    }
}

export default AgGridCustomAutoCompleteFilter

import moment from 'moment';
import { FilterInterface } from 'types';

/**
 * Checks if a field passes filter checks
 *
 * @param value
 * @param filter
 */
export const checkPasses = (value: any, filter: FilterInterface) => {
    const {
        type: { value: filterType },
        value: filterValue,
    } = filter;
    const lowerValue =
        value === undefined || value === null
            ? ''
            : value.toString().toLowerCase();
    const lowerFilterValue = filterValue.toLowerCase();
    let valueDate;
    let filterValueDate;
    const dateFormat = 'YYYY-MM-DD';

    switch (filterType) {
        case 'equal':
            // eslint-disable-next-line eqeqeq
            return lowerValue == lowerFilterValue;
        case 'notEqual':
            // eslint-disable-next-line eqeqeq
            return lowerValue != lowerFilterValue;
        case 'greater':
            return value > filterValue;
        case 'less':
            return value < filterValue;
        case 'greaterOrEqual':
            return value >= filterValue;
        case 'lessOrEqual':
            return value <= filterValue;
        case 'contains':
            return lowerValue.indexOf(lowerFilterValue) !== -1;
        case 'doesNotContain':
            return lowerValue.indexOf(lowerFilterValue) === -1;
        case 'isBlank':
            return lowerValue === '';
        case 'isNotBlank':
            return lowerValue !== '';
        case 'beginsWith':
            return (
                lowerValue.substring(0, lowerFilterValue.length) ===
                lowerFilterValue
            );
        case 'doesNotBeginWith':
            return (
                lowerValue.substring(0, lowerFilterValue.length) !==
                lowerFilterValue
            );
        case 'endsWith':
            return (
                lowerValue.substring(
                    lowerValue.length - lowerFilterValue.length,
                ) === lowerFilterValue
            );
        case 'doesNotEndWith':
            return (
                lowerValue.substring(
                    lowerValue.length - lowerFilterValue.length,
                ) !== lowerFilterValue
            );
        case 'isTrue':
            return value === true;
        case 'isFalse':
            return value === false;
        case 'isNull':
            return value === null;
        case 'isNotNull':
            return value !== null;
        case 'before':
            valueDate = moment(value).format(dateFormat);
            filterValueDate = moment(filterValue).format(dateFormat);
            return valueDate < filterValueDate;
        case 'onOrBefore':
            valueDate = moment(value).format(dateFormat);
            filterValueDate = moment(filterValue).format(dateFormat);
            return valueDate <= filterValueDate;
        case 'after':
            valueDate = moment(value).format(dateFormat);
            filterValueDate = moment(filterValue).format(dateFormat);
            return valueDate > filterValueDate;
        case 'onOrAfter':
            valueDate = moment(value).format(dateFormat);
            filterValueDate = moment(filterValue).format(dateFormat);
            return valueDate >= filterValueDate;
        case 'on':
            valueDate = moment(value).format(dateFormat);
            filterValueDate = moment(filterValue).format(dateFormat);
            return valueDate === filterValueDate;
        default:
            return true;
    }
};

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    FormControlLabel,
    Checkbox as MaterialCheckbox,
    Tooltip,
} from '@mui/material'

import { FontAwesomeIcon, fas } from '../../fontAwesome'

const Checkbox = forwardRef((props, ref) => {
    const {
        checked,
        tooltip,
        inderteminate,
        disabled,
        onChange,
        label,
        value,
        isThemed,
    } = props

    if (!isThemed) {
        return <MaterialCheckbox {...props} ref={ref} />
    }

    const LabelToolTip = tooltip && (
        <Tooltip title={tooltip}>
            <FontAwesomeIcon
                icon={fas.faCircleInfo}
                color={'#007899'}
                style={{ fontSize: '14px' }}
            />
        </Tooltip>
    )

    return (
        <FormControlLabel
            tek-label-for="checkbox"
            ref={ref}
            value={value}
            label={
                <>
                    {label}
                    {LabelToolTip}
                </>
            }
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            control={<MaterialCheckbox indeterminate={inderteminate} />}
        />
    )
})

Checkbox.propTypes = {
    checked: PropTypes.bool,
    inderteminate: PropTypes.bool,
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.node,
    value: PropTypes.string,
    isThemed: PropTypes.bool,
}

Checkbox.defaultProps = {
    checked: undefined,
    inderteminate: false,
    tooltip: undefined,
    disabled: false,
    onChange: undefined,
    label: undefined,
    value: undefined,
    isThemed: false,
}

export default Checkbox

import React from 'react'
import { Grid } from '@mui/material'
import { institutionPropType } from './InstitutionTypes'
import { LinkWrapper, EntityTypeText, LinkStyled } from '../styles'

function InstitutionGridDisplay({ institution }) {
    if (!institution) {
        return <></>
    }

    const {
        inst_id: institutionId,
        inst_fullname: institutionFullname,
        resource_uri: resourceUri,
    } = institution

    return (
        <Grid container>
            <LinkWrapper item container xs={12}>
                <EntityTypeText>Institution: </EntityTypeText>
                <LinkStyled
                    href={resourceUri}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Go to Institution Profile (Inst ID ${institutionId})`}
                >
                    {institutionFullname}
                </LinkStyled>
            </LinkWrapper>
        </Grid>
    )
}

InstitutionGridDisplay.propTypes = {
    institution: institutionPropType,
}

InstitutionGridDisplay.defaultProps = { institution: null }

export default InstitutionGridDisplay

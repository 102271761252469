import React, { Children } from 'react'
import Grid from '../../../base/Grid'
import SectionWrapper from './SectionWrapper'
import { calculateBreakpoints } from '../../utils'

const FormGridContent = (props) => {
    const {
        children,
        columns,
        spacing = 2,
        section,
        useSeparator = false,
    } = props
    const breakpoints = calculateBreakpoints(columns)

    if (!columns)
        return <SectionWrapper section={section}>{children}</SectionWrapper>

    const gridEnabledChildren = Children.map(children, (child) => {
        if (!child) return

        return (
            <Grid item {...breakpoints}>
                {child}
            </Grid>
        )
    })

    return (
        <SectionWrapper section={section} useSeparator={useSeparator}>
            <Grid container spacing={spacing}>
                {gridEnabledChildren}
            </Grid>
        </SectionWrapper>
    )
}

export default FormGridContent

import React, { forwardRef, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { AttachmentButton } from '../../../base/Button'
import SelectedFile from './SelectedFile'
import Typography from '../../../base/Typography'

const Attachment = forwardRef((props, ref) => {
    const inputRef = useRef()
    const { setValue } = useFormContext()
    const {
        disabled = false,
        name,
        field,
        error,
        helperText,
        displayValue = 'Add Attachments',
        accept = [
            '.pdf',
            '.docx',
            '.doc',
            '.xlsx',
            '.csv',
            '.xlsm',
            '.xls',
            '.xltm',
        ],
    } = props

    const fileTypes = accept.length ? accept.join() : ''
    const borderColor = error && '#D32F2F'

    const handleInput = () => {
        inputRef.current.click()
    }

    const removeFile = (index) => {
        const filteredList = Array.from(props.value).filter(
            (_, filterIndex) => filterIndex !== index
        )
        setValue(name, filteredList.length ? filteredList : undefined)
    }

    return (
        <div>
            {props.value &&
                Array.from(props.value).map((file, index) => (
                    <SelectedFile
                        key={file?.name}
                        name={file?.name}
                        onDelete={() => removeFile(index)}
                    />
                ))}

            <AttachmentButton
                {...field}
                ref={ref}
                onClick={handleInput}
                error={{ borderColor }}
                accept={fileTypes}
                disabled={disabled}
            >
                <span>{displayValue}</span>
                <input
                    ref={inputRef}
                    type="file"
                    id="files-upload"
                    style={{ display: 'none' }}
                    multiple
                    onChange={(event) => {
                        props.onChange([
                            ...(props.value ?? []),
                            ...event.target.files,
                        ])
                    }}
                />
            </AttachmentButton>
            {error && (
                <Typography
                    sx={{
                        color: borderColor,
                        fontSize: '12px',
                        letterSpacing: '0.03em',
                        marginTop: '4px',
                    }}
                >
                    {helperText}
                </Typography>
            )}
        </div>
    )
})

export default Attachment

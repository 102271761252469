import React, { useContext } from 'react';
import { PaginationFooter } from 'components/PaginationFooter';
import { Size } from '../../types';
import { MaterialDataGridContext } from '../MaterialDataGrid';

interface Props {
    disablePerPage: boolean;
    loading: boolean;
    noResultsText: string;
    size: Size;
}

export const ContextPagination = (props: Props) => {
    const { pagination } = useContext(MaterialDataGridContext);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PaginationFooter {...props} {...pagination} />;
};

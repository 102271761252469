import React from 'react'
import { Divider, Grid } from '../../../../../base'

const AppHeaderDivider = ({ ...props }) => {
    return (
        <Grid item>
            <Grid item>
                <Divider
                    orientation="vertical"
                    sx={{ width: '1px', height: '40px' }}
                    {...props}
                />
            </Grid>
        </Grid>
    )
}

export default AppHeaderDivider

import { styled, alpha } from '@mui/material/styles'

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: '100%',
}))

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

export const SearchPageContainer = styled('div')`
    color: #444;
    z-index: 1300;
    overflow: auto;
    left: 0;
    top: 57px;
    width: 100%;
    height: calc(
        100vh - ${(props) => (props.screenSize === 'large' ? '68px' : '122px')}
    );
    max-height: 100vh;
    background: #fff;
    position: absolute;
    transition: 0.5s;
`

import React, { createContext, useContext } from 'react'

const DEFAULT_STATE = {
    userRedirectURL: '/people',
}

const CommentContext = createContext(DEFAULT_STATE)

export const CommentProvider = ({ children, value }) => {
    return (
        <CommentContext.Provider value={{ ...DEFAULT_STATE, ...value }}>
            {children}
        </CommentContext.Provider>
    )
}

export const useComment = () => useContext(CommentContext)

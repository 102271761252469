import { LicenseManager } from '@ag-grid-enterprise/core'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

export const setTableLicense = () => {
    let license =
        'Using_this_AG_Grid_Enterprise_key_( AG-039205 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Stepstone Group LP )_is_granted_a_( Single Application )_Developer_License_for_the_application_( SPI )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( SPI )_need_to_be_licensed___( SPI )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 5 May 2024 )____[v2]_MTcxNDg2MzYwMDAwMA==2813fe66c932e508d27d2274f6cfbc2f'

    LicenseManager.setLicenseKey(license)
}

export const serverSideDataSource = (serverSideApi) => {
    return {
        getRows: (params) => {
            const sortModel = params.request.sortModel
            const filterModel = params.request.filterModel
            const page = params.api.paginationGetCurrentPage() + 1
            const itemsPerPage = params.api.paginationGetPageSize()

            const successCallback = (data, totalRows) => {
                params.success({
                    rowData: data,
                    rowCount: totalRows,
                })
            }

            const failCallback = () => {
                params.fail()
            }

            serverSideApi({
                page,
                itemsPerPage,
                filterModel,
                sortModel,
                successCallback,
                failCallback,
            })
        },
    }
}

const serverSideDefaultProps = ({ serverSideApi, perPage }) => ({
    modules: [ServerSideRowModelModule],
    rowModelType: 'serverSide',
    serverSideSortOnServer: true,
    serverSideFilterOnServer: true,
    serverSideDatasource: serverSideDataSource(serverSideApi),
    serverSideInitialRowCount: perPage,
})

export const getAgGridProps = (serverSideApi, rowData, perPage) => {
    return (
        (serverSideApi && serverSideDefaultProps({ serverSideApi, perPage })) ||
        (rowData && { rowData }) ||
        {}
    )
}

export const getSortedOptions = (options) => {
    return (
        options
            .sort((a, b) => a - b)
            .map((value) => {
                return {
                    value,
                    label: value,
                }
            }) || []
    )
}

export const domLayoutProps = (height, domLayout = 'autoHeight') => {
    if (height && height !== 'auto') {
        return { gridStyle: { height } }
    } else {
        return { domLayout }
    }
}

import React from 'react'
import { Link as MaterialLink } from '@mui/material'
import PropTypes from 'prop-types'

const stepStoneBlue = '#007899'
const textDecoration = 'none'

const LinkStyle = {
    fontFamily: '"Open Sans", Roboto, sans-serif',
    color: stepStoneBlue,
    textDecoration,
    '&:hover': {
        color: stepStoneBlue,
        textDecoration,
    },
    '&:visited': {
        color: stepStoneBlue,
        textDecoration,
    },
    '&:active': {
        color: stepStoneBlue,
        textDecoration,
    },
}

function Link(props) {
    const newTab = props.newTab || false

    let target = newTab ? '_blank' : undefined
    let rel = newTab ? 'noopener' : undefined

    const customLinkStyle = props.customLinkStyle || {}

    let style = { ...LinkStyle, ...customLinkStyle }

    return <MaterialLink sx={style} {...props} target={target} rel={rel} />
}

Link.propTypes = {
    newTab: PropTypes.bool,
    href: PropTypes.string.isRequired,
}

Link.defaultProps = {
    newTab: false,
}

export default Link

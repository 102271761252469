import React from 'react'
import { Grid, List } from '../../../../../../base'
import SearchFilterItem from './SearchFilterItem'

const SearchFilters = ({
    filters = [],
    selected = 0,
    handleSelection,
    FilterComponent = SearchFilterItem,
}) => {
    return (
        <Grid container direction="column" alignItems="flex-end">
            <List sx={{ position: 'fixed' }}>
                {filters.map((filter, index) => (
                    <FilterComponent
                        selected={selected === index}
                        index={index}
                        label={filter.label}
                        value={filter.value}
                        onClick={handleSelection}
                    />
                ))}
            </List>
        </Grid>
    )
}

export default SearchFilters

import React, { createContext, useContext } from 'react'
import CompanyLogo from '../../../../../assets/images/spi_research_logo.svg'
import AppLogo from '../../../../../assets/images/stepstone.png'

const DEFAULT_STATE = {
    companyLogo: CompanyLogo,
    appLogo: AppLogo,
}

export const AppHeaderContext = createContext(DEFAULT_STATE)

export const AppHeaderProvider = ({ children, value }) => {
    return (
        <AppHeaderContext.Provider value={{ ...DEFAULT_STATE, ...value }}>
            {children}
        </AppHeaderContext.Provider>
    )
}

export const useAppHeader = () => useContext(AppHeaderContext)

import React, { useState } from 'react'
import AppsRoundedIcon from '@mui/icons-material/AppsRounded'
import { FontAwesomeIcon, fas } from '../../../../../fontAwesome'

import {
    Box,
    Button,
    Typography,
    Drawer,
    Grid,
    IconButton,
    Link,
} from '../../../../../base'

import SSGLogo from '../../../../../../assets/images/stepstone.png'
import { useFunction, useRedirect } from '../../projects'

const default_content = [
    { title: "SPI", href: "https://spi.dseapps.com/home.php" },
    { title: "Omni", href: "https://omni.dseapps.com/home" },
    { title: "Pacing", href: "https://pacing.dseapps.com/" },
    { title: "Benchmarking", href: "https://benchmarking.dseapps.com/" },
    { title: "Codex", href: "https://codex.dseapps.com/" }
]

const AppItem = ({ title, href, logo = SSGLogo, onClick }) => {
    const redirect = useRedirect()
    const { execute } = useFunction(onClick)
    const handeClick = (event) => {
        if (onClick) {
            return execute(event, props)
        }
        return redirect(href)
    }
    return (
        <Grid item>
            <Link
                component="button"
                display="flex"
                alignItems="center"
                gap="8px"
                onClick={handeClick}
                sx={{ textDecoration: 'none', color: 'inherit' }}
            >
                <img width="24px" src={logo} />
                <Typography variant="body2">{title}</Typography>
            </Link>
        </Grid>
    )
}

const AppDrawer = ({ content = default_content }) => {
    const [isOpen, setOpen] = useState(false)
    const toggle = () => setOpen(!isOpen)
    const close = () => setOpen(false)
    return (
        <Grid item sx={{ maxHeight: '56px' }}>
            <IconButton onClick={toggle} sx={{ padding: '0' }}>
                <AppsRoundedIcon size={16} />
            </IconButton>
            <Drawer anchor="left" open={isOpen} onClose={close}>
                <Box
                    role="presentation"
                    sx={{
                        width: '300px',
                        padding: '16px 24px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '16px',
                        }}
                    >
                        <IconButton sx={{ padding: '0' }}>
                            <AppsRoundedIcon size={16} />
                        </IconButton>
                        <Button
                            onClick={close}
                            sx={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                textTransform: 'none',
                            }}
                        >
                            <span>All Apps</span>
                            <FontAwesomeIcon
                                icon={fas.faAngleRight}
                                style={{ fontSize: '16px' }}
                            />
                        </Button>
                    </Box>
                    <Grid container direction="column" gap="24px">
                        <Typography variant="h5">StepStone Apps</Typography>
                        { content.map((app) => (
                            <AppItem {...app} />
                        )) }
                    </Grid>
                </Box>
            </Drawer>
        </Grid>
    )
}

export default AppDrawer

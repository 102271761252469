import ExcelData from './ExcelData'

class ExcelCell {
    constructor(value) {
        this.data = new ExcelData({ value })
        this.mergeAcross = 0
        this.ref = null
        this.collapsibleRanges = null
        this.styleId = null
    }

    setValue(excelData) {
        this.data = excelData
    }

    setType(type) {
        this.data.setType(type)
    }

    setMergeAcross(mergeAcross = 0) {
        this.mergeAcross = mergeAcross
    }

    setRef(ref) {
        this.ref = ref
    }

    setCollapsibleRanges(collapsibleRanges) {
        this.collapsibleRanges = collapsibleRanges
    }

    setStyleId(styleId) {
        this.styleId = styleId
    }
}

export default ExcelCell

import React from 'react';
import { MaterialDataGridInterface, Size } from 'types';
import { EmptyMaterialDataGridContext } from '../MaterialDataGrid';
import { PaginationFooter } from '../PaginationFooter';

interface Props {
    dataGrid?: MaterialDataGridInterface;
    disablePerPage: boolean;
    loading: boolean;
    noResultsText: string;
    size: Size;
}

export const NoContextPagination = ({
    dataGrid = EmptyMaterialDataGridContext,
    ...props
}: Props) => {
    const { pagination } = dataGrid || {};

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PaginationFooter {...props} {...pagination} />;
};

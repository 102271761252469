import React from 'react';
import { HeaderWithContext } from './HeaderWithContext';
import { MaterialDataGridTableHeaderNoContext } from './MaterialDataGridTableHeaderNoContext';
import { HeaderProps } from './types';
/* eslint-disable react/jsx-props-no-spreading */

export const MaterialDataGridTableHeader = ({
    children,
    noContext = false,
    dataGrid,
    ...props
}: HeaderProps) => {
    if (noContext) {
        return (
            <MaterialDataGridTableHeaderNoContext
                dataGrid={dataGrid}
                {...props}
            >
                {children}
            </MaterialDataGridTableHeaderNoContext>
        );
    }
    return <HeaderWithContext {...props}>{children}</HeaderWithContext>;
};

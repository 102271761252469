import styled, { css } from 'styled-components'

export const DEFAULT_STYLES = css`
    box-sizing: border-box;
    background: none;
    margin: 0;
    display: block;
    min-width: 100%;
    max-width: 100%;
    padding: 8.5px 14px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    border-color: ${({ $error }) =>
        !!$error ? `#d32f2f` : `rgba(0, 0, 0, 0.23)`};
    font-size: 12px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.5;
`
export const StyledParagraph = styled.p`
    color: #d32f2f;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
`

import React from 'react'
import {
    Box,
    IconButton,
    ImageList,
    ImageListItem,
    Link,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@mui/material'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { COLOR_NAMES } from '../../../../themes'

const QuickAddMenu = (props) => {
    const { id, open, anchorEl, addMenuObject, handleClick } = props

    return (
        <>
            <IconButton
                size="large"
                color="primary"
                aria-describedby={id}
                onClick={(e) => {
                    handleClick(true, e, true)
                }}
                onMouseEnter={(e) => {
                    handleClick(true, e)
                }}
            >
                <AddCircleRoundedIcon />
            </IconButton>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                disablePortal={true}
                sx={{ zIndex: 1305 }}
                placement="bottom-end"
            >
                <Paper sx={{ padding: '0px 10px' }}>
                    <MenuList key={id} sx={{ width: '100%' }}>
                        <ImageList cols={2} gap={0}>
                            {addMenuObject?.map((item, index) => {
                                return (
                                    <ImageListItem key={index}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    padding: '10px 5px 5px',
                                                    fontSize: '14px !important',
                                                    fontWeight: '600',
                                                    color: COLOR_NAMES.black,
                                                }}
                                            >
                                                {item.heading}
                                            </Typography>
                                            {item?.items?.map((item, index) => {
                                                return (
                                                    <Link
                                                        href={item.href}
                                                        underline="none"
                                                        key={index}
                                                    >
                                                        <MenuItem
                                                            sx={{
                                                                padding: '5px',
                                                            }}
                                                        >
                                                            <Box>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize:
                                                                            '14px !important',
                                                                        fontWeight:
                                                                            '100',
                                                                        color: COLOR_NAMES.black,
                                                                    }}
                                                                >
                                                                    {item.title}
                                                                </Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    </Link>
                                                )
                                            })}
                                        </Box>
                                    </ImageListItem>
                                )
                            })}
                        </ImageList>
                    </MenuList>
                </Paper>
            </Popper>
        </>
    )
}

export default QuickAddMenu

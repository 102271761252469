import { COLOR_NAMES } from '../../../themes'

export const DEFAULT_TEXTFIELD_PROPS = {
    autoComplete: 'off',
    sx: {
        fontSize: '12px',
        svg: {
            color: COLOR_NAMES.black,
        },
        '& .MuiInputBase-root': {
            minHeight: '40px'
        }
    },
    InputProps: {
        inputProps: {
            style: {
                textAlign: 'left',
                fontSize: '12px',
            },
        },
    },
    inputProps: {
        style: { fontSize: '12px' },
    },
}

export const COMMON_TEXTFIELD_PROPS = {
    FormHelperTextProps: {
        style: {
            fontSize: '12px',
            marginLeft: '0',
        },
    },
    fullWidth: true,
    size: 'small',
}

export const SELECT_TEXTFIELD_PROPS = {
    sx: {
        svg: {
            color: COLOR_NAMES.black,
        },
    },
    defaultValue: '',
    SelectProps: {
        style: { fontSize: '12px' },
        MenuProps: {
            PaperProps: {
                sx: {
                    maxHeight: '230px',
                },
            },
        },
    },
}

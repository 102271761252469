const ExcelDataType = {
    STRING: 'String',
    FORMULA: 'Formula',
    NUMBER: 'Number',
    BOOLEAN: 'Boolean',
    DATETIME: 'DateTime',
    ERROR: 'Error',
}

export default ExcelDataType

import { COLOR_NAMES } from '../../themes/SsgTheme'

export const DialogTitleStyle = {
    fontFamily: '"Open Sans", Roboto, sans-serif',
    fontWeight: '600',
    color: COLOR_NAMES.black,
    lineHeight: '24px',
    fontSize: '16px',
    padding: '0px',
}

import React, { useState } from 'react';
import {
    FilterFieldType,
    FiltersType,
    HorizontalAlignment,
    SetFiltersFunction,
} from 'types';
import { FilterMenu } from 'components/FilterMenu';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import FilterList from '@material-ui/icons/FilterList';

interface Props {
    filterable?: boolean;
    filters?: FiltersType;
    filterMenuTransformOrigin?: HorizontalAlignment;
    name: string;
    setFilters: SetFiltersFunction;
    type: FilterFieldType;
}

export const MaterialDataGridFilterControls = ({
    filterable = false,
    filters = {},
    filterMenuTransformOrigin = 'center',
    name,
    setFilters,
    type = 'text',
}: Props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [triggerClose, setTriggerClose] = useState(false);
    const menuOpen = Boolean(anchorEl);

    /**
     * Close the popover without applying changes
     */
    const closeAndDontApply = () => {
        setAnchorEl(null);
    };

    /**
     * Close the popover and update filters
     * @param newFilters
     */
    const closeAndApply = async (newFilters) => {
        setTriggerClose(false);
        setAnchorEl(null);
        setFilters(name, newFilters);
    };

    /**
     * Toggles opening the filter menu
     * @param event
     */
    const toggleFilterMenu = (event) => {
        if (!menuOpen) {
            const { currentTarget } = event;
            setTriggerClose(false);
            setAnchorEl(currentTarget);
        } else {
            // Trigger close triggers the child component to save it's filters
            setTriggerClose(true);
        }
    };

    /**
     * If not filterable, return nothing for this component
     */
    if (!filterable) {
        return null;
    }

    /**
     * Get the filter for this name
     */
    const theseFilters =
        typeof filters === 'object' && Object.hasOwnProperty.call(filters, name)
            ? filters[name]
            : null;

    const id = menuOpen ? `${name}-filter-popover` : undefined;

    return (
        <span title='Apply Filters'>
            <IconButton
                size='small'
                color={theseFilters ? 'primary' : 'default'}
                aria-describedby={id}
                onClick={toggleFilterMenu}
            >
                <FilterList />
            </IconButton>
            <Popover
                id={id}
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={closeAndDontApply}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: filterMenuTransformOrigin,
                }}
            >
                <FilterMenu
                    triggerClose={triggerClose}
                    closeAndApply={closeAndApply}
                    name={name}
                    theseFilters={theseFilters}
                    type={type}
                />
            </Popover>
        </span>
    );
};

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, IconButton, Menu } from '@mui/material'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import LoadingContainer from '../layouts/LoadingContainer'
import { CommentItemPropType } from './types'
import {
    CommenterAvatar,
    CommenterLink,
    Comment,
    MenuItemStyled,
} from './styles'
import ConfirmationModal from '../ConfirmationModal'
import { useComment } from './data-provider'

const CommentsList = () => {
    const {
        comments,
        setParentComment,
        isUserAdmin,
        userId,
        loading,
        loadData,
        handleDeleteComment,
        commentToModify,
        setCommentToModify,
        userRedirectURL,
    } = useComment()

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [anchorActionsElement, setAnchorActionsElement] = useState(null)
    const openActions = !!anchorActionsElement

    const handleDeleteMenuItemClick = () => {
        setOpenDeleteDialog(true)
        setAnchorActionsElement(null)
        setParentComment(null)
    }

    const handleUpdateMenuItemClick = () => {
        setParentComment(commentToModify)
        setAnchorActionsElement(null)
    }

    const onDeleteClose = () => {
        setCommentToModify(null)
        setOpenDeleteDialog(false)
    }

    const handleMoreActions = (target, commentItem) => {
        setCommentToModify(commentItem)
        setAnchorActionsElement(target)
    }

    const onDeleteSubmit = async () => {
        const responseStatus = await handleDeleteComment()

        if (responseStatus && responseStatus === 200) {
            onDeleteClose()
            await loadData()
        }
    }

    const renderComment = (commentItem) => {
        const showActions = commentItem.created_by == userId || isUserAdmin

        return (
            <Comment key={commentItem.id}>
                <CommenterAvatar>{commentItem.initials}</CommenterAvatar>
                <CommenterLink
                    href={`${userRedirectURL}/${commentItem.profile_id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    {commentItem.name}
                </CommenterLink>
                {showActions && (
                    <>
                        <IconButton
                            onClick={(e) =>
                                handleMoreActions(e.currentTarget, commentItem)
                            }
                        >
                            <MoreVertRoundedIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorActionsElement}
                            open={openActions}
                            onClose={() => setAnchorActionsElement(null)}
                        >
                            <MenuItemStyled onClick={handleUpdateMenuItemClick}>
                                Update
                            </MenuItemStyled>
                            <MenuItemStyled onClick={handleDeleteMenuItemClick}>
                                Delete
                            </MenuItemStyled>
                        </Menu>
                    </>
                )}
                <Typography variant="body2">{commentItem.timestamp}</Typography>
                <Typography variant="body2">{commentItem.comment}</Typography>
            </Comment>
        )
    }

    return (
        <LoadingContainer loading={loading}>
            {comments.map(renderComment)}
            <ConfirmationModal
                open={openDeleteDialog}
                onClose={onDeleteClose}
                title={'Are you sure you want to delete this comment?'}
                description={
                    <>
                        {commentToModify?.timestamp}
                        <br />
                        {commentToModify?.comment}
                    </>
                }
                onSubmit={onDeleteSubmit}
                secondaryButtonText="Cancel"
                primaryButtonText="Delete"
            />
        </LoadingContainer>
    )
}

CommentsList.propTypes = {
    loading: PropTypes.bool,
    comments: PropTypes.arrayOf(CommentItemPropType).isRequired,
    loadData: PropTypes.func.isRequired,
    setParentComment: PropTypes.func,
    isUserAdmin: PropTypes.string,
    userId: PropTypes.number,
    handleDeleteComment: PropTypes.func,
    commentToModify: CommentItemPropType,
    setCommentToModify: PropTypes.func,
}

CommentsList.defaultProps = {
    loading: false,
    comments: [],
    loadData: () => {},
    setComment: () => {},
    setParentComment: () => {},
    isUserAdmin: '',
    userId: null,
    handleDeleteComment: () => {},
    commentToModify: () => {},
    setCommentToModify: () => {},
}

export default CommentsList

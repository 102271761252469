export const getErrorMessage = (error) => {
    switch (error) {
        case 401:
            return 'You are not authorized.  Please wait while we re-authorize your session.'
        case 403:
            return 'Forbidden'
        case 404:
            return 'Resource Not Found'
        case 500:
            return 'Internal Server Error'
        default:
            return ''
    }
}

import PropTypes from 'prop-types'

// General Partners
export const generalPartnerPropType = PropTypes.shape({
    GeneralPartnerID: PropTypes.number,
    GP_Name: PropTypes.string,
    GP_Name_Chinese: PropTypes.string,
    latest_fund_id: PropTypes.number,
    AUM: PropTypes.number,
    AUM_Date: PropTypes.string,
    Website: PropTypes.string,
    Website_Thumb: PropTypes.number,
    Website_Thumb_Date: PropTypes.string,
    Website_Thumb_ErrCode: PropTypes.string,
    Website_Thumb_Fail_Count: PropTypes.number,
    asset_class_id: PropTypes.number,
    Number_of_Partners: PropTypes.number,
    Additional_Investment_Professionals: PropTypes.number,
    Next_In_Market: PropTypes.number,
    IndustryID: PropTypes.number,
    Investing_Since: PropTypes.string,
    Comparables: PropTypes.string,
    Currency: PropTypes.string,
    Exchange_Rate: PropTypes.number,
    Exchange_Rate_Date: PropTypes.string,
    kia_gp: PropTypes.number,
    fundraising_status_id: PropTypes.number,
    timeperiod_id: PropTypes.number,
    fundraising_notes: PropTypes.string,
    gp_parent_id: PropTypes.number,
    primary_market_map_id: PropTypes.number,
    aifmd_compliant: PropTypes.number,
    chinese_investors: PropTypes.number,
    id_register_ignore: PropTypes.number,
    Creation_Date: PropTypes.string,
    modified_date_gmt: PropTypes.string,
    confidential: PropTypes.number,
    publicly_listed: PropTypes.bool,
    ticker: PropTypes.string,
})

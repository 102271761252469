import React, { useEffect, useState, forwardRef } from 'react'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormHelperText from '@mui/material/FormHelperText'
import { COLOR_NAMES } from '../../themes/SsgTheme'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 700,
            fontSize: '12px',
        },
    },
}

const MultiSelect = forwardRef((props, ref) => {
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedLabels, setSelectedLabels] = useState([])
    const {
        name,
        groups,
        options,
        limitTags = 1,
        disabled,
        error = false,
        helperText,
        defaultValues = [],
        onChange,
    } = props
    const calculatedWidth = 100 / groups.length

    useEffect(() => {
        if (defaultValues.length) {
            const newData = options.filter((opt) => {
                return defaultValues.includes(opt.optionId)
            })

            setSelectedOptions(newData)
            onChange && onChange(newData)
        }
    }, [])

    const calculateLimitTags = (totalSelection, limit) => {
        const newSelection = []
        for (let index = 0; index < limit; index++) {
            newSelection.push(totalSelection[index])
        }

        return newSelection
    }

    useEffect(() => {
        if (selectedOptions.length) {
            if (selectedOptions.length <= limitTags) {
                setSelectedLabels(selectedOptions)
            } else {
                setSelectedLabels([
                    ...calculateLimitTags(selectedOptions, limitTags),
                    {
                        groupId: 0,
                        name: `+${selectedOptions.length - limitTags}`,
                    },
                ])
            }
        } else {
            setSelectedLabels([])
        }
    }, [selectedOptions])

    const handleOptionToggle = (option) => () => {
        const currentIndex = selectedOptions.indexOf(option)
        const newSelectedOptions = [...selectedOptions]

        if (currentIndex === -1) {
            newSelectedOptions.push(option)
        } else {
            newSelectedOptions.splice(currentIndex, 1)
        }

        setSelectedOptions(newSelectedOptions)
        onChange && onChange(newSelectedOptions)
    }

    const removeSelectedOption = (option) => {
        const currentIndex = selectedOptions.indexOf(option)
        const newSelectedOptions = [...selectedOptions]

        if (currentIndex === -1) {
            newSelectedOptions.push(option)
        } else {
            newSelectedOptions.splice(currentIndex, 1)
        }

        setSelectedOptions(newSelectedOptions)
        onChange && onChange(newSelectedOptions)
    }

    return (
        <>
            <Select
                ref={ref}
                sx={{
                    width: '100%',
                    '& .MuiSelect-select': {
                        padding: '6px',
                    },
                    svg: {
                        color: COLOR_NAMES.black,
                    },
                }}
                labelId="multiselect-label"
                id="multiselect"
                displayEmpty
                multiple
                name={name}
                value={selectedLabels}
                error={error}
                input={<OutlinedInput id="multiselect-input" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((opt) => (
                            <Chip
                                key={`selected-option-${opt.optionId}`}
                                style={{
                                    padding: '3px 12px',
                                    backgroundColor: COLOR_NAMES.blue,
                                    maxHeight: '24px',
                                    color: COLOR_NAMES.white,
                                    borderRadius: '8px',
                                }}
                                label={opt.name}
                                onDelete={
                                    opt.groupId
                                        ? () => {
                                              if (opt.groupId !== null) {
                                                  removeSelectedOption(opt)
                                              }
                                          }
                                        : null
                                }
                                onMouseDown={(event) => {
                                    event.stopPropagation()
                                }}
                                sx={{
                                    '& .MuiChip-deleteIcon': {
                                        color: '#FFFFFF',
                                        fontSize: '14px',
                                    },
                                    '& .MuiChip-deleteIcon:hover': {
                                        color: '#e3e0e0',
                                        fontSize: '14px',
                                    },
                                }}
                            />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
                disabled={disabled}
            >
                <div>
                    <Box p={3} style={{ display: 'flex' }}>
                        {groups.map((header) => (
                            <div
                                style={{
                                    padding: '0px',
                                    width: `${calculatedWidth}%`,
                                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                }}
                                key={header.groupId}
                            >
                                <span
                                    style={{
                                        fontWeight: 'bolder',
                                    }}
                                >
                                    {header.name}
                                </span>
                                <div>
                                    {options.map((option) => {
                                        return option.groupId ===
                                            header.groupId ? (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                style={{
                                                    padding: '0px',
                                                    fontSize: '12px',
                                                }}
                                                onClick={handleOptionToggle(
                                                    option
                                                )}
                                            >
                                                <Checkbox
                                                    checked={selectedOptions.includes(
                                                        option
                                                    )}
                                                    onChange={handleOptionToggle(
                                                        option
                                                    )}
                                                />
                                                {option.name}
                                            </MenuItem>
                                        ) : null
                                    })}
                                </div>
                            </div>
                        ))}
                    </Box>
                </div>
            </Select>
            {error && (
                <FormHelperText error={error}>{helperText}</FormHelperText>
            )}
        </>
    )
})

export default MultiSelect

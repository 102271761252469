import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Slider as MaterialSlider } from '@mui/material'

const Slider = forwardRef((props, ref) => {
    const { variant, onChange, value, marks, min, max, step, disabled } = props

    return (
        <MaterialSlider
            ref={ref}
            variant={variant}
            onChange={onChange}
            value={value}
            marks={marks}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
        />
    )
})

Slider.propTypes = {
    variant: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    marks: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.node,
                value: PropTypes.number.isRequired,
            })
        ),
    ]),
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
}

Slider.defaultProps = {
    variant: 'slider',
    onChange: undefined,
    value: undefined,
    marks: false,
    min: 0,
    max: 100,
    step: 1,
    disabled: false,
}

export default Slider

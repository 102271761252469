import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import ConditionalDropdownFilter from '../../components/Filters/ConditionalDropdownFilter'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridCustomConditionalDropdownFilter extends AgGridBaseFilter {
    getFilter() {
        return ConditionalDropdownFilter
    }

    isCustomFilter() {
        return true
    }

    correctOptionsTypePassed({ isArray, isPromise, isFunction }) {
        const correctOptionsTypePassed = !isPromise && isArray && !isFunction

        if (!correctOptionsTypePassed) {
            console.error(
                'Incorrect options passed to AgGridCustomConditionalDropdownFilter, options must be an array'
            )
        }

        return correctOptionsTypePassed
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_CONDITIONAL_DROPDOWN
    }

    getCustomFilterParams() {
        return {
            conditionalModels: this.filterParams.options,
            emptyLabel: this.filterParams.emptyLabel ?? '',
        }
    }
}

export default AgGridCustomConditionalDropdownFilter

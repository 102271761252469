import * as React from 'react'
import Typography from '../Typography/Typography'
import Box from '@mui/material/Box'

export default function TabPanel(props) {
    const { children, value, index, tabPanelStyle = { p: 3 }, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={tabPanelStyle}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

import React from 'react'
import { Popover, Typography, Button, Avatar, Divider } from '@mui/material'
import styled from 'styled-components'
import { useUser } from './data-provider'

const UserProfile = (props) => {
    const { userRedirectURL } = useUser()
    const { anchorProfileEl, handleClose, openProfile, id, user, initials } =
        props

    const {
        first_name: firstName,
        last_name: lastName,
        person_id: personId,
        email,
    } = user || {}

    // User Profile pop up menu
    return (
        <Popover
            id={id}
            open={openProfile}
            anchorEl={anchorProfileEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {user && (
                <>
                    <MenuContainer
                        direction="column"
                        alingItems="center"
                        padding="1em"
                    >
                        <Avatar>{initials}</Avatar>
                        <Typography
                            variant="subtitle2"
                            display="block"
                            sx={{
                                fontWeight: 'bold !important',
                                paddingTop: '5px',
                                color: '333333',
                            }}
                        >
                            {`${firstName} ${lastName}`}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{ paddingBottom: '0.9em', color: '333333' }}
                        >
                            {email}
                        </Typography>
                        <Button
                            variant="outlined"
                            href={`${userRedirectURL}/${personId}`}
                        >
                            View Profile
                        </Button>
                    </MenuContainer>
                    <Divider />
                    <MenuContainer
                        direction="column"
                        alingItems="center"
                        padding="1em"
                    >
                        <LogoutButton variant="contained" href="/auth/logout">
                            Logout
                        </LogoutButton>
                    </MenuContainer>
                </>
            )}
        </Popover>
    )
}

const MenuContainer = styled.div`
    display: ${(props) => (props.display ? props.display : 'flex')};
    grid-template-columns: auto auto auto;
    flex-wrap: wrap;
    flex-direction: ${(props) =>
        props.direction ? props.direction : 'column'};
    padding-top: 1em;
    padding: ${(props) => (props.padding ? props.padding : '')};
    align-items: ${(props) => (props.alingItems ? props.alingItems : '')};
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
`

const LogoutButton = styled(Button)`
    background-color: #007899 !important;
    color: rgba(255, 255, 255, 1) !important;
    font-size: 12px;

    &:hover {
        background-color: #00576c !important;
    }
`

export default UserProfile

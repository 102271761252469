import React, { useState, useEffect } from 'react'
import { ListItemButton, Grid, List, Alert } from '@mui/material'
import styled from 'styled-components'
import { getName, convertResponseToOptions } from './helper'

const SearchResultLists = ({ searchResults }) => {
    const [resultData, setResultData] = useState(null)

    useEffect(() => {
        const mergedData = searchResults
            ? convertResponseToOptions(searchResults)
            : []
        setResultData(mergedData)
    }, [searchResults])

    return (
        <Grid container direction="column" alignItems="flex-start">
            <List sx={{ paddingLeft: '39px', width: '100%' }}>
                {resultData && resultData?.length > 0 ? (
                    resultData?.map((obj) => (
                        <ListItemContainer
                            key={`${obj.type}-${obj.id}`}
                            value={getName(obj)}
                            sx={{ width: '100%' }}
                        >
                            <ListItemLink>{obj.render}</ListItemLink>
                        </ListItemContainer>
                    ))
                ) : (
                    <Grid xs={12}>
                        <Alert severity="info">
                            No matching search results
                        </Alert>
                    </Grid>
                )}
            </List>
        </Grid>
    )
}

const ListItemLink = styled.span`
    width: 100%;
    color: #007899;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
`

const ListItemContainer = styled(ListItemButton)`
    border-bottom: 1px solid rgba(231, 236, 242, 1) !important;
    &:hover {
        background-color: #f3f5f7 !important;
    }
`

export default SearchResultLists

import React, { useState, useEffect } from 'react'
import {
    Tabs,
    Tab,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Tooltip,
    IconButton,
    Button,
} from '@mui/material'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import TabPanel from './TabPanel'
import {
    COLOR_STONE,
    COLOR_WHITE,
    COLOR_NAMES,
    COLOR_GRAY,
} from '../../../themes'

// todo: Figure out why tab navigation styling does not match legacy TabSections component from the old library. The complex example in storybook is incorrect as far as styling but the simple example is good.

function TabSections({
    stepper,
    tabSectionsNavTitle,
    tabSectionsNavIcon,
    tabs,
    collapsed,
    tabSectionsHref,
    uncollapsedStyle,
    collapsedStyle,
    initialTab,
}) {
    // Current tab state
    const [currentTab, setCurrentTab] = useState(initialTab || 0)

    // Subtab state
    const [currentSubTab, setCurrentSubTab] = useState(0)

    // Stepper state
    const [activeStep, setActiveStep] = useState(initialTab || 0)

    // subTabsIndex is to keep track of all tab indexes that have subtabs
    const [subTabsIndex, setSubTabsIndex] = useState([])

    // Length of the list of tabs
    const [tabsLength, setTabsLength] = useState(tabs.length || 0)

    // Collapse NavBar
    const [uncollapsed, setUncollapsed] = useState(true)

    // Destructure tabSectionsNavIcon
    const {
        src: iconScr,
        alt: iconAlt,
        icon: TabSectionIcon,
        iconComponent,
        iconStyle,
    } = tabSectionsNavIcon

    // If first tab on load has subtabs, it will display the accordingly not disabled first subtab
    useEffect(() => {
        if (tabs[0].subTabs) {
            let newCurrentTab = getEligibleNextSubTab(
                0,
                0,
                tabs[0].subTabs.length
            )
            setCurrentSubTab(newCurrentTab)
        }
    }, [])

    const a11yProps = (index) => ({
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    })

    const handleTabChange = (event, newValue) => {
        if (tabs[newValue].onTabSelected) {
            tabs[newValue].onTabSelected()
        }

        setCurrentTab(newValue)
    }

    const handleSubTabChange = (newValue) => {
        setCurrentSubTab(newValue)
    }

    // handle active step is incoporating the use of the stepper keeping track via activeStep state
    // currentSubTab is updated to false if no subtabs, otherwise it'll be the subtabs index
    const handleActiveSubTab = (index, newValue) => {
        setActiveStep(index)
        setCurrentSubTab(newValue)

        if (!stepper) setCurrentTab(index)
    }

    // function to to find the previous tab that's not disabled
    const getEligibleBackSubTab = (tabIdx, subTabIndex) => {
        for (let i = subTabIndex - 1; i >= 0; i--) {
            let currentSection = tabs[tabIdx].subTabs[i]
            if (!currentSection.disabled) {
                return `${tabIdx}_${i}`
            }
        }
    }

    // Function to handle moving back to the previous tab
    const handleBack = () => {
        if (activeStep >= 0) {
            const getEligibleBackStep = (activeStep) => {
                for (let i = activeStep - 1; i >= 0; i--) {
                    let currentSection = tabs[i]
                    if (!currentSection.disabled) {
                        return i
                    }
                }
                return 0
            }

            const newActiveStep = getEligibleBackStep(activeStep)
            const newCurrentTab = newActiveStep

            // No stepper is used and this is only for regular tabs and subtabs features
            if (currentSubTab) {
                // retrieving the subtab index ex. "0_1",
                let indexes = currentSubTab.split('_')
                // tabIdx value is the tab index and subTabIndex is the subtabs index
                let tabIdx = Number(indexes[0])
                let subTabIndex = Number(indexes[1])

                if (subTabIndex) {
                    // moving back from subtabs to subtabs minusing an index from it
                    const backSubTab = getEligibleBackSubTab(
                        tabIdx,
                        subTabIndex
                    )
                    if (backSubTab) {
                        setCurrentSubTab(backSubTab)
                        return
                    }
                }
            }

            // subTabsIndex is a list of tabs that have subtabs
            // When the activeStep value/index is in the subTabsIndex, it will follow these steps to navigate back
            if (subTabsIndex.includes(newActiveStep)) {
                if (!stepper) setCurrentTab(newCurrentTab)
                const backSubTab = getEligibleNextSubTab(
                    Number(newActiveStep),
                    0,
                    tabs[newActiveStep].subTabs.length
                )
                setCurrentSubTab(backSubTab)
                setActiveStep(newActiveStep)
                return
            }

            if (!stepper) setCurrentTab(newCurrentTab)
            setActiveStep(newActiveStep)
            // set currentSubTab to false because the main tab will not have subtabs
            setCurrentSubTab(false)
        }
    }

    // function to to find the next tab that's not disabled
    const getEligibleNextSubTab = (tabIdx, subTabIndex, length) => {
        for (let i = subTabIndex; i < length; i++) {
            let currentSection = tabs[tabIdx].subTabs[i]
            if (!currentSection.disabled) {
                return `${tabIdx}_${i}`
            }
        }
    }

    // Handle navigating to the next tab
    const handleContinue = async (
        customOnClick = false,
        subTabsLength = false
    ) => {
        // continue only if active step is within the size of all tabs
        if (activeStep < tabsLength - 1) {
            if (customOnClick) {
                let result = await customOnClick()
                if (result instanceof Error) return
            }

            const getEligibleNextActiveStep = (activeStep) => {
                for (let i = activeStep + 1; i < tabs.length; i++) {
                    let currentSection = tabs[i]
                    if (!currentSection.disabled) {
                        return i
                    }
                }
            }

            const newActiveStep = getEligibleNextActiveStep(activeStep)
            const newCurrentTab = newActiveStep

            // if there is subtablength passed in means that it has subtabs. Navigating with subtabs
            if (subTabsLength) {
                // retrieving the subtab index ex. "0_1",
                let indexes = currentSubTab.split('_')
                let tabIdx = Number(indexes[0])
                let subTabIndex = Number(indexes[1])

                const nextSubTab = getEligibleNextSubTab(
                    tabIdx,
                    subTabIndex + 1,
                    subTabsLength
                )
                setCurrentSubTab(nextSubTab)

                // if its the final subtab then continue will navigate to the next tab else it will continue to the next subtab
                if (subTabsLength - 1 === subTabIndex || !nextSubTab) {
                    // setting subtab back false if next tab doesn't have subtabs
                    setCurrentSubTab(false)
                    setActiveStep(newActiveStep)
                    if (!stepper) setCurrentTab(newCurrentTab)

                    // if next tab has subtabs it will set subtab value
                    if (subTabsIndex.includes(newActiveStep)) {
                        const newSubTab = getEligibleNextSubTab(
                            newActiveStep,
                            0,
                            tabs[newActiveStep].subTabs.length
                        )
                        setCurrentSubTab(newSubTab)
                    }
                }
                return
            }

            // if next tab has subtabs navigating from a regular tab
            if (subTabsIndex.includes(newCurrentTab)) {
                const nextSubTab = getEligibleNextSubTab(
                    Number(newActiveStep),
                    0,
                    tabs[newActiveStep].subTabs.length
                )
                setCurrentSubTab(nextSubTab)
            }

            if (!stepper) setCurrentTab(newCurrentTab)
            setActiveStep(newActiveStep)
        }
    }

    // Function to handle setting up the header and buttons
    const handleHeader = (header, subTabsLength = false) => {
        const headerControls = header.props.controls
        if (headerControls) {
            const modifiedChildren = headerControls.map((item, idx) => {
                if (item?.props?.['data-add-continue-function']) {
                    let customOnClick = item?.props?.onClick
                    if (customOnClick) {
                        return React.cloneElement(item, {
                            key: idx,
                            onClick: () => {
                                handleContinue(customOnClick, subTabsLength)
                            },
                        })
                    } else {
                        return React.cloneElement(item, {
                            key: idx,
                            onClick: () => {
                                handleContinue(false, subTabsLength)
                            },
                        })
                    }
                } else if (item?.props?.['data-add-back-function']) {
                    return React.cloneElement(item, {
                        key: idx,
                        onClick: () => {
                            handleBack()
                        },
                    })
                } else {
                    return item
                }
            })

            const newHeader = {
                ...header,
                props: {
                    ...header.props,
                    controls: modifiedChildren,
                },
            }

            return newHeader
        }

        return header
    }

    // Function to add accordion to tabs that have subtabs
    const addAccordion = (item, index) => {
        // storing all the tab index that has subtabs
        if (!subTabsIndex.includes(index)) {
            setSubTabsIndex([...subTabsIndex, index])
        }
        return (
            <AccordionWrapper
                key={index}
                expanded={activeStep === index}
                sx={{
                    '&:before': {
                        display: 'none',
                    },
                }}
            >
                <AccordionContent>
                    <CustomTab
                        key={index}
                        label={item.title}
                        {...a11yProps(index)}
                        onClick={() =>
                            handleActiveSubTab(
                                index,
                                getEligibleNextSubTab(
                                    index,
                                    0,
                                    item.subTabs.length
                                )
                            )
                        }
                        disabled={item.disabled}
                    />
                </AccordionContent>
                <AccordionDetails>
                    <ListWrapper component="nav">
                        {item.subTabs.map((tab, idx) => (
                            <ListItem
                                key={idx}
                                autoFocus={idx === 0}
                                onClick={() =>
                                    handleSubTabChange(`${index}_${idx}`, index)
                                }
                                selected={currentSubTab === `${index}_${idx}`}
                                button
                                disabled={tab.disabled}
                            >
                                <ListItemTextWrapper
                                    primary={tab.title}
                                ></ListItemTextWrapper>
                            </ListItem>
                        ))}
                    </ListWrapper>
                </AccordionDetails>
            </AccordionWrapper>
        )
    }

    let tabsNav
    // Setting up the stepper and tabs. if there is going to be steppers, subtabs
    if (stepper) {
        tabsNav = (
            <StepperWrapper
                nonLinear
                activeStep={activeStep}
                orientation="vertical"
                $uncollapsed={uncollapsed}
                tabs={tabs}
                lastStep={activeStep}
            >
                {tabs.map((item, index) => (
                    <Tooltip
                        title={!uncollapsed ? item.title : ''}
                        placement="right-end"
                    >
                        <Step key={index}>
                            <StepperIconStyle color="inherit">
                                <Collapse
                                    orientation="vertical"
                                    in={uncollapsed}
                                >
                                    {item.subTabs ? (
                                        addAccordion(item, index)
                                    ) : (
                                        <CustomTab
                                            key={index}
                                            label={item.title}
                                            {...a11yProps(index)}
                                            onClick={() =>
                                                handleActiveSubTab(index, false)
                                            }
                                            disabled={item.disabled}
                                        />
                                    )}
                                </Collapse>
                            </StepperIconStyle>
                        </Step>
                    </Tooltip>
                ))}
            </StepperWrapper>
        )
    } else {
        // No stepper, but is there going to be subtabs
        tabsNav = tabs.map((item, index) =>
            item.subTabs ? (
                addAccordion(item, index)
            ) : (
                <CustomTab
                    key={index}
                    label={item.title}
                    {...a11yProps(index)}
                    onClick={() => handleActiveSubTab(index, false)}
                    disabled={item.disabled}
                />
            )
        )
    }

    const collapsedButton = collapsed && (
        <IconButtonStyled
            onClick={() => setUncollapsed(!uncollapsed)}
            $uncollapsed={uncollapsed}
            $uncollapsedStyle={uncollapsedStyle}
            $collapsedStyle={collapsedStyle}
        >
            {uncollapsed ? (
                <ArrowBackIosRoundedIcon color="disabled" />
            ) : (
                <ArrowForwardIosRoundedIcon color="disabled" />
            )}
        </IconButtonStyled>
    )

    const NavIcon = () => {
        if (iconComponent) {
            return (
                <IconComponentButtonStyled>
                    {iconComponent}
                </IconComponentButtonStyled>
            )
        } else if (iconScr) {
            return <TabSectionsNavIcon src={iconScr} alt={iconAlt} />
        } else {
            return <TabSectionIcon sx={iconStyle ?? TabSectionsNavIconClass} />
        }
    }

    return (
        <TabSectionsWrapper $uncollapsed={uncollapsed} stepper={stepper}>
            <TabSectionsNav>
                <TabSectionsNavHeader $uncollapsed={uncollapsed}>
                    <ButtonStyled
                        href={tabSectionsHref}
                        sx={{
                            display: `${
                                !stepper && !uncollapsed ? 'none' : 'contents'
                            }`,
                        }}
                    >
                        <NavIcon />
                        <Collapse orientation="vertical" in={uncollapsed}>
                            <TabPanelHeading variant="h6">
                                {tabSectionsNavTitle}
                            </TabPanelHeading>
                        </Collapse>
                    </ButtonStyled>
                    {collapsedButton}
                </TabSectionsNavHeader>
                <CustomTabs
                    orientation="vertical"
                    value={stepper === true ? currentTab : activeStep}
                    onChange={handleTabChange}
                    aria-label={`${tabSectionsNavTitle} tab panel`}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#f1f1f1',
                            width: '100%',
                            zIndex: '-10000',
                            borderRadius: '5px',
                        },
                    }}
                    sx={{
                        display: `${
                            !stepper && !uncollapsed ? 'none' : 'contents'
                        }`,
                    }}
                >
                    {tabsNav}
                </CustomTabs>
            </TabSectionsNav>
            <TabSectionsContent>
                {tabs.map((item, index) => {
                    if (item.subTabs) {
                        return item.subTabs.map((tab, idx) => (
                            <TabPanel
                                key={idx}
                                currentTab={currentSubTab}
                                index={`${index}_${idx}`}
                            >
                                {tab.tabPanelHeader &&
                                    handleHeader(
                                        tab.tabPanelHeader,
                                        item.subTabs.length
                                    )}
                                {tab.component}
                            </TabPanel>
                        ))
                    } else {
                        return (
                            <TabPanel
                                key={index}
                                currentTab={
                                    stepper === true ? activeStep : currentTab
                                }
                                index={index}
                            >
                                {item.tabPanelHeader &&
                                    handleHeader(item.tabPanelHeader)}
                                {item.component}
                            </TabPanel>
                        )
                    }
                })}
            </TabSectionsContent>
        </TabSectionsWrapper>
    )
}

TabSections.propTypes = {
    stepper: PropTypes.bool,
    tabSectionsNavTitle: PropTypes.string.isRequired,
    tabSectionsNavIcon: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        icon: PropTypes.object,
        iconStyle: PropTypes.object,
    }).isRequired,
    tabs: PropTypes.array.isRequired,
    collapsed: PropTypes.bool,
    tabSectionsHref: PropTypes.string,
}

export default TabSections

const TabSectionsWrapper = styled.div`
    grid-column: 1 / -1;
    display: grid;
    height: 100%;

    ${(props) =>
        props.$uncollapsed
            ? css`
                  grid-template-columns: 246px calc(100vw - 246px);
              `
            : css`
                  grid-template-columns: ${props.stepper ? '75' : '25'}px calc(
                          100vw - ${props.stepper ? '75' : '25'}px
                      );
              `};
`

const TabSectionsNav = styled.section`
    padding: 1.5em 0.75em;
    border-right: 1px solid ${COLOR_NAMES['secondaryLightGray']};
    background-color: ${COLOR_NAMES['white']};
`

const TabSectionsNavHeader = styled.header`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3em;

    ${(props) =>
        !props.$uncollapsed &&
        css`
            margin-bottom: 1.5em;
        `};
`

const TabSectionsNavIcon = styled.img`
    width: 32px;
    margin-right: 0.5em;
`

const TabSectionsNavIconClass = {
    width: '32px',
    marginRight: '0.5em',
}

const TabSectionsContent = styled.section`
    background-color: ${COLOR_GRAY['50']};
    padding: 24px 16px;
`

const TabPanelHeading = styled(Typography)`
    font-size: 16px;
    color: ${COLOR_NAMES['black']};
    text-transform: capitalize;
    font-weight: 400;
    text-align: left;
`

const ButtonStyled = styled(Button)`
    &:hover {
        background-color: transparent;
    }
`

const CustomTabs = styled(Tabs)`
    border: none;
    background-color: ${COLOR_NAMES['white']};

    .MuiTabs-flexContainer {
        background-color: ${COLOR_NAMES['white']};
    }
`

const CustomTab = styled(Tab)`
    transition: background-color 0.2s ease-out;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 12px;
    align-items: flex-start;
    color: ${COLOR_NAMES['black']};
    &:hover {
        background-color: rgba(0, 120, 153, 0.1);
        border-radius: 5px;
    }
    .MuiTab-wrapper {
        text-align: left;
        display: block;
    }
    &.Mui-selected {
        color: ${COLOR_STONE['800']};
        background-color: rgba(0, 120, 153, 0.1);
        border-radius: 5px;
    }
`

const StepperWrapper = styled(Stepper).withConfig({
    // To remove warnings and only pass through props needed to the DOM
    shouldForwardProp: (props) =>
        ['children', 'nonLinear', 'activeStep', 'orientation'].includes(props),
})`
    ${(props) =>
        !props.$uncollapsed &&
        css`
            padding-left: 0.25em;
            padding-top: 0;
        `};

    .MuiStepConnector-vertical {
        padding: 0;
        margin-left: 12px;
        margin-top: 20px;
        position: absolute;

        // If it's the last step and has subtabs remove vertical line
        ${(props) =>
            props.tabs.length - 1 === props.lastStep &&
            props.tabs[props.tabs.length - 1].subTabs
                ? css`
                      height: 65%;
                  `
                : css`
                      height: 100%;
                  `};
        ${(props) =>
            props.$uncollapsed
                ? css`
                      padding-bottom: 90px;
                  `
                : css`
                      padding-bottom: 55px;
                  `};
    }
    .MuiStepConnector-lineVertical {
        height: 100%;
    }
`

const StepperIconStyle = styled(StepLabel)`
    .MuiStepIcon-root {
        color: ${COLOR_WHITE};
        border: 1px solid #bdbdbd;
        border-radius: 50%;
        z-index: 1;
    }
    .MuiStepIcon-active {
        color: ${COLOR_STONE['800']};
        border: none;
    }
    .MuiStepIcon-text {
        fill: none;
    }
    .MuiStepLabel-iconContainer {
        align-self: flex-start;
        margin-top: 10px;
    }
    .MuiStepLabel-label {
        color: inherit;
    }
`

const AccordionWrapper = styled(Accordion).withConfig({
    // To remove warnings and only pass through props needed to the DOM
    shouldForwardProp: (props) =>
        ['children', 'expanded', 'sx'].includes(props),
})`
    box-shadow: none;
    &.MuiAccordion-root:before {
        background-color: white;
    }
    &.Mui-expanded {
        margin: 0;
    }
    .MuiAccordionSummary-root {
        padding: 0;
        min-height: 3em;
    }
    .MuiAccordionDetails-root {
        padding: 0 1em;
    }
`

const AccordionContent = styled(AccordionSummary)`
    .MuiAccordionSummary-content {
        margin: 0;
    }
    .MuiAccordionSummary-content > button {
        width: 100%;
    }
`

const ListWrapper = styled(List)`
    display: flex;
    width: 100%;
    flex-direction: column;
`

const ListItemTextWrapper = styled(ListItemText)`
    .MuiListItemText-primary {
        margin-left: 1em;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.54);
        white-space: normal;
        word-break: keep-all;
    }
`

const IconComponentButtonStyled = styled(IconButton)`
    background-color: ${COLOR_NAMES.secondaryLightGray};
    margin-right: 10px;
    width: 40px;
    height: 40px;

    &:hover {
        background-color: ${COLOR_NAMES.secondaryLightGray};
    }

    svg {
        width: 12px;
        height: 16px;
        color: ${COLOR_NAMES.black};
    }
`

const IconButtonStyled = styled(IconButton)`
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
    position: absolute;
    background-color: white;
    z-index: 100;
    padding: 0.2rem;
    top: auto;

    .MuiSvgIcon-root {
        font-size: 0.7rem;
        color: ${COLOR_NAMES['black']};
    }

    &:hover {
        background-color: white;

        .MuiSvgIcon-root {
            color: ${COLOR_STONE['800']};
        }
    }

    ${(props) => {
        // Conditional to check and style collapsed icon dependent on the App header
        if (props.$uncollapsed) {
            return props.$uncollapsedStyle
                ? css`
                      ${props.$uncollapsedStyle}
                  `
                : css`
                      left: 14.7rem;
                  `
        } else {
            return props.$collapsedStyle
                ? css`
                      ${props.$collapsedStyle}
                      margin-top: 50px;
                  `
                : css`
                      left: 4.05rem;
                      margin-top: 50px;
                  `
        }
    }}
`

import React, { useState } from 'react'

import MenuTab from './MenuTab'
import { Grid } from '../../../../../../base'

const columnSize = 204

const Menu = ({ content = [], align = 'left', ...props }) => {
    // inner state
    const [selected, setSelected] = useState('')
    const isOpen = (value) => value === selected
    const handleOpen = (value) => {
        setSelected(value)
    }
    const handleClose = () => {
        setSelected('')
    }

    if (content.length <= 0) {
        // if there is no content
        return null
    }
    const isSingle = content.length === 1

    return (
        <Grid
            item
            sx={[
                {
                    maxHeight: '56px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: align,
                },
            ]}
            xs={!isSingle}
            {...props}
        >
            {content.map(({ title, type, columns, href, onClick }, index) => (
                <MenuTab
                    key={`tab-${index}`}
                    open={isOpen(title)}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    type={type}
                    icon={title}
                    title={title}
                    columns={columns}
                    columnSize={columnSize}
                    isSingle={isSingle}
                    href={href}
                    onClick={onClick}
                />
            ))}
        </Grid>
    )
}

export default Menu

import React, { Component } from 'react'
import AgGridFilterModelBuilder from '../../utils/models/AgGridFilterModelBuilder'
import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import { FormControl, Select } from '@mui/material'
import { Chip, MenuItemBase } from '../../../../base'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DropdownFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            options: [],
            selectedValues: [],
        }

        props.options.then((options) => {
            const uniqueOptions = this.makeUniqueOptions(options)

            this.setState({
                options: uniqueOptions,
            })
        })

        this.placeholder = props.placeholder ?? ''
        this.emptyValue = props.multiple ? [] : ''

        this.hasPlaceholder = this.valueIsDefined(this.placeholder)

        this.displayEmpty = this.hasPlaceholder
    }

    valueIsDefined(value) {
        if (Array.isArray(value)) return value.length > 0

        return value !== '' && value !== null && value !== undefined
    }

    makeUniqueOptions = (options) => {
        const values = new Set()
        const uniqueOptions = []

        options.forEach((option) => {
            const jsonValue = JSON.stringify(option)

            if (values.has(jsonValue)) return

            values.add(jsonValue)
            uniqueOptions.push(option)
        })

        return uniqueOptions
    }

    isFilterActive = () => {
        return this.state.selectedValues.length > 0
    }

    doesFilterPass = (params) => {
        const { valueGetter } = params
        const value = valueGetter(params)

        const selectedKeys = this.state.selectedValues

        if (selectedKeys.length === 0) return true

        return selectedKeys.includes(value)
    }

    getModel = () => {
        const modelBuilder = new AgGridFilterModelBuilder()

        const selectedKeys = this.state.selectedValues
        const keysAreNumbers = selectedKeys.every(
            (key) => typeof key === 'number'
        )

        if (selectedKeys.length === 0) return undefined

        if (keysAreNumbers) {
            modelBuilder.setType(AgGridFilterConstants.FILTER_TYPE_NUMBER)
        } else {
            modelBuilder.setType(AgGridFilterConstants.FILTER_TYPE_TEXT)
        }

        modelBuilder.setFilter(selectedKeys)
        modelBuilder.setFilterType(AgGridFilterConstants.CONDITION_WHERE_IN)

        return {
            ...modelBuilder.build(),
        }
    }

    setModel = (model, param1, param2) => {
        if (model === null) {
            this.setSelectedValues([])
            return
        }
    }

    setSelectedValues = (selectedValues) => {
        this.setState(
            {
                selectedValues,
            },
            () => this.props.filterChangedCallback()
        )
    }

    onChange = (event) => {
        const value = event.target.value
        const selectedValuesSet = this.makeSelectedValues(value)
        const selectedValues = this.setToArray(selectedValuesSet)

        this.setSelectedValues(selectedValues)
    }

    makeSelectedValues = (value) => {
        if (!this.valueIsDefined(value)) return new Set()

        const selectedValues = new Set()

        if (!Array.isArray(value)) {
            selectedValues.add(value)

            return selectedValues
        }

        value.forEach((valueItem) => {
            if (!this.valueIsDefined(valueItem)) return

            selectedValues.add(valueItem)
        })

        return selectedValues
    }

    setToArray = (set) => {
        if (!set) return []

        return Array.from(set)
    }

    renderValue = (selected) => {
        const valueSelected = selected.length > 0

        if (valueSelected) return this.selectedValueRendered(selected)

        if (this.hasPlaceholder) return this.placeholder

        return ''
    }

    selectedValueRendered(selected) {
        return selected.map((value) => {
            return (
                <Chip
                    key={value}
                    label={value}
                    clickable
                    deleteIcon={
                        <FontAwesomeIcon
                            icon={fas.faTimesCircle}
                            size="xs"
                            onMouseDown={(event) => {
                                event.stopPropagation()
                            }}
                        />
                    }
                    onDelete={(event) => {
                        this.onChange({
                            target: {
                                value: this.state.selectedValues.filter(
                                    (selectedValue) => selectedValue !== value
                                ),
                            },
                        })
                    }}
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: '#007899',
                        color: '#FFFFFF',
                        fontSize: '12px',
                        marginRight: '2px',
                        marginBottom: '0px',
                    }}
                />
            )
        })
    }

    optionMap = (option, index) => (
        <MenuItemBase
            key={`${option['label']}-${index}`}
            value={option['value']}
            sx={{ fontSize: '12px', minHeight: 'auto' }}
        >
            {option['label']}
        </MenuItemBase>
    )

    render = () => {
        return (
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                    {...this.props}
                    size="small"
                    className={'ag-grid-dropdown-filter'}
                    fullWidth
                    sx={{
                        fontSize: '12px',
                        padding: '2.5px 6px',
                    }}
                    multiple={this.props.multiple}
                    value={this.state.selectedValues}
                    onChange={this.onChange}
                    options={this.state.options}
                    displayEmpty={this.displayEmpty}
                    renderValue={this.renderValue}
                >
                    {this.displayEmpty && (
                        <MenuItemBase
                            value={this.emptyValue}
                            sx={{ fontSize: '12px', minHeight: '30px' }}
                            disabled
                        >
                            {this.placeholder}
                        </MenuItemBase>
                    )}

                    {this.state.options &&
                        this.state.options.map(this.optionMap)}
                </Select>
            </FormControl>
        )
    }
}

export default DropdownFilter

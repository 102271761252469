import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '../../../base'
import Loader from './Loader'
import { getErrorMessage } from './utils'
import { ErrorCodeDisplay, ErrorMessageDisplay, GridStyle } from './style'

const LoadingContainer = ({ children, error, loading, size }) => {
    const errorMessage = getErrorMessage(error)
    let content = children

    if (loading) {
        content = <Loader size={size} />
    } else if (!loading && error) {
        content = (
            <Grid container style={GridStyle}>
                <Grid item xs={6} container justifyContent="flex-end">
                    <ErrorCodeDisplay>Error {error}</ErrorCodeDisplay>
                </Grid>
                <Grid item xs={6}>
                    <ErrorMessageDisplay>{errorMessage}</ErrorMessageDisplay>
                </Grid>
            </Grid>
        )
    }

    return <>{content}</>
}

LoadingContainer.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.number,
    error: PropTypes.number,
}

LoadingContainer.defaultProps = {
    children: null,
    error: null,
    size: 100,
}

export default LoadingContainer

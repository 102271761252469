import React, { forwardRef } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { AgGridReact } from 'ag-grid-react'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import PropTypes from 'prop-types'
import 'ag-grid-enterprise'

const TableVariant = forwardRef((props, ref) => {
    const {
        tableType,
        agGridOptions,
        muiOptions,
        agTheme,
        gridStyle = null,
    } = props

    if (tableType == 'aggrid') {
        if (agTheme) {
            return (
                <div style={gridStyle} className={agTheme}>
                    <AgGridReact {...agGridOptions} {...props} ref={ref} />
                </div>
            )
        } else {
            return <AgGridReact {...agGridOptions} {...props} ref={ref} />
        }
    } else if (tableType == 'mui') {
        return (
            <TableContainer {...muiOptions.muiTableContainerOptions}>
                <Table {...muiOptions.muiTableComponentOptions}>
                    <TableHead>{muiOptions.muiTableHeader}</TableHead>
                    {muiOptions.muiTableRows}
                </Table>
            </TableContainer>
        )
    }
})

TableVariant.propTypes = {
    tableType: PropTypes.string,
    gridStyle: PropTypes.object,
    agGridOptions: PropTypes.shape({
        rowData: PropTypes.arrayOf(PropTypes.object),
        columnDefs: PropTypes.arrayOf(PropTypes.object),
    }),
    agTheme: PropTypes.oneOf(['ag-theme-alpine', 'ag-theme-balham']),
}

export default TableVariant

import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import TextFilter from '../../components/Filters/TextFilter'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridCustomTextSearchFilter extends AgGridBaseFilter {
    getFilter() {
        return TextFilter
    }

    isCustomFilter() {
        return true
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_TEXT_SEARCH
    }

    getCustomFilterParams() {
        return {
            debounceMs: this.filterParams?.debounceMs ?? 500,
            placeholder: this.filterParams?.placeholder ?? '',
        }
    }
}

export default AgGridCustomTextSearchFilter

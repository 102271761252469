import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    editCellContainer: {
        display: 'flex',
        width: '100%',
    },
    editContainer: {},
    controlsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        fontSize: theme.spacing(1),
    },
}));

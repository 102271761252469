import React, { createContext, useContext } from 'react'

const DEFAULT_STATE = {
    isExternalUser: true,
    userRedirectURL: '/people',
}

export const UserContext = createContext(DEFAULT_STATE)

export const UserProvider = ({ children, value }) => {
    return (
        <UserContext.Provider value={{ ...DEFAULT_STATE, ...value }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext)

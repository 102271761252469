import PropTypes from 'prop-types'

// Mandates
export const mandateBenchmarksPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    mandate_id: PropTypes.number.isRequired,
    benchmark: PropTypes.string,
    premium: PropTypes.number,
})

export const mandatePrimaryAllocationValue = PropTypes.shape({
    asia_allocation: PropTypes.number.isRequired,
    asset_class_id: PropTypes.number.isRequired,
    emea_allocation: PropTypes.number.isRequired,
    latam_allocation: PropTypes.number.isRequired,
    na_allocation: PropTypes.number.isRequired,
    primary_allocation_id: PropTypes.number.isRequired,
})

export const mandateRestrictionsLimitsPropTypes = PropTypes.shape({
    investment_type_id: PropTypes.number.isRequired,
    mandate_id: PropTypes.number.isRequired,
    mandate_restriction_limit_id: PropTypes.number.isRequired,
    mandate_restriction_limit_type_id: PropTypes.number.isRequired,
    note: PropTypes.string.isRequired,
    value: PropTypes.string,
    updatedByName: PropTypes.string,
})

export const mandatePrimaryAllocationPropTypes = PropTypes.shape({
    allocation_values: PropTypes.arrayOf(mandatePrimaryAllocationValue)
        .isRequired,
    mandate_id: PropTypes.number,
    primary_allocation_id: PropTypes.number,
    target_commitment_range_max: PropTypes.number,
    target_commitment_range_min: PropTypes.number,
    target_number_of_commitments_max: PropTypes.number,
    target_number_of_commitments_min: PropTypes.number,
    updatedByName: PropTypes.string,
    year: PropTypes.number.isRequired,
})

export const mandateProfilePropTypes = PropTypes.shape({
    primary_allocations: PropTypes.arrayOf(mandatePrimaryAllocationPropTypes),
    restrictions_and_limits: PropTypes.arrayOf(
        mandateRestrictionsLimitsPropTypes
    ),
    primary_strat_id: PropTypes.number,
    multi_asset_class: PropTypes.number,
    multi_asset_class_detailed: PropTypes.string,
    primary_investment_type_id: PropTypes.number,
    multi_investment_type: PropTypes.number,
    multi_investment_type_detail: PropTypes.string,
    discretion_id: PropTypes.number,
    benchmarks: PropTypes.arrayOf(mandateBenchmarksPropType),
})

import React from 'react'
import Highcharts from 'highcharts/highmaps'
import HighchartsReact from 'highcharts-react-official'
import { HighchartsTheme } from '../../themes'
import HighchartsConfig from '../HighchartsConfig'

HighchartsTheme(Highcharts)
HighchartsConfig(Highcharts)

function HighchartsMap(props) {
    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={'mapChart'}
            {...props}
        />
    )
}

export default HighchartsMap

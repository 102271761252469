import React, { forwardRef } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'

const BasicBreadcrumbs = forwardRef((props, ref) => {
    return (
        <Breadcrumbs {...props} ref={ref}>
            {props.children}
        </Breadcrumbs>
    )
})

export default BasicBreadcrumbs

/**
 * Convert the given number into two digits format.
 *
 * @param {number} number
 * @returns {number|string}
 */
const formatTwoDigits = (number) => (number < 10 ? '0' + number : number)

/**
 * Convert the given date object to date string format.
 *
 * @param {Object} date
 * @returns {string}
 */
export const formatDateToString = (date) => {
    if (!!date && typeof date == 'object' && !isNaN(date.getDate())) {
        const day = formatTwoDigits(date.getUTCDate())
        const month = formatTwoDigits(date.getUTCMonth() + 1)
        return `${month}/${day}/${date.getUTCFullYear()}`
    }
    return ''
}

import React from 'react'
import MenuOption from './MenuOption'
import { Grid } from '../../../../../../base'
import { APP_THEME, SSG_FONT_FAMILY } from '../../../../../../themes'

const MenuSection = ({ title, content = [] }) => {
    if (content.length <= 0) {
        // if there is no content
        return null
    }

    return (
        <Grid container direction="column">
            <p
                style={{
                    ...APP_THEME.typography.body_3,
                    fontWeight: '600',
                    padding: '10px 0',
                    fontFamily: SSG_FONT_FAMILY,
                    margin: '0',
                }}
            >
                {title}
            </p>
            {content.map((option, index) => (
                <MenuOption key={`section-${index}`} {...option} />
            ))}
        </Grid>
    )
}

export default MenuSection

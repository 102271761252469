import ExcelRow from './ExcelRow'

class ExcelContentBuilder {
    constructor() {
        this.content = []
    }

    addRow(row) {
        this.content.push(row)
    }

    addRowWithSingleCell(value) {
        let row = new ExcelRow()
        row.addCellWithValue(value)

        this.addRow(row)
    }

    addEmptyRow() {
        let row = new ExcelRow()
        this.addRow(row)
    }

    build() {
        return this.content
    }
}

export default ExcelContentBuilder

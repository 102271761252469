import React, { forwardRef } from 'react'
import AppBar from '@mui/material/AppBar'
import { COLOR_NAMES } from '../../themes'

const MaterialAppBar = forwardRef((props, ref) => {
    const { isThemed = false, children, ...rest } = props

    if (!isThemed) {
        return (
            <AppBar {...rest} ref={ref}>
                {children}
            </AppBar>
        )
    }

    return (
        <AppBar
            position="relative"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: COLOR_NAMES.white,
                minHeight: '56px',
                height: 'auto',
            }}
        >
            {children}
        </AppBar>
    )
})

export default MaterialAppBar

import PropTypes from 'prop-types'

// Secondaries
export const secondariesPropType = PropTypes.shape({
    second_id: PropTypes.number,
    code_name: PropTypes.string,
    desc_full: PropTypes.string,
    date_received: PropTypes.string,
    preliminary_due_date: PropTypes.string,
    final_due_date: PropTypes.string,
    bidders: PropTypes.number,
    source: PropTypes.string,
    seller: PropTypes.string,
    email_notify: PropTypes.number,
    interesttype_id: PropTypes.number,
    dealprocess_id: PropTypes.number,
    second_dealtype_id: PropTypes.number,
    deal_size_local: PropTypes.number,
    sre_alloc_avail_local: PropTypes.number,
    deal_size_gross_local: PropTypes.number,
    deal_size_equity_local: PropTypes.number,
    currency: PropTypes.string,
    fx_date: PropTypes.string,
    fx_rate: PropTypes.number,
    enterprise: PropTypes.number,
    ssg_dealcaptain: PropTypes.number,
    proptype_id: PropTypes.number,
    region_id: PropTypes.number,
    country_code: PropTypes.string,
    city: PropTypes.string,
    location: PropTypes.string,
    re_trx_id: PropTypes.number,
    non_srep_deal: PropTypes.number,
    in_pipeline: PropTypes.number,
    chinese_investors: PropTypes.number,
    target_ic_date: PropTypes.string,
    gp_id: PropTypes.number,
    fund_id: PropTypes.number,
    opeartor: PropTypes.string,
    leverage_pct: PropTypes.number,
    irr: PropTypes.number,
    tvpi: PropTypes.number,
    cash_on_cash_pct: PropTypes.number,
    development_exposure_pct: PropTypes.number,
    sourcing_channel_id: PropTypes.number,
    second_structure_id: PropTypes.number,
    second_risk_profile_id: PropTypes.number,
    sre_status_id: PropTypes.number,
    second_financing_id: PropTypes.number,
    seller_inst_type_id: PropTypes.number,
    seller_region_id: PropTypes.number,
    seller_reason_id: PropTypes.number,
    transaction_source_id: PropTypes.number,
    transaction_role_id: PropTypes.number,
    num_companies_override: PropTypes.number,
    num_funds_override: PropTypes.number,
    num_gps_override: PropTypes.number,
    irr_base: PropTypes.number,
    irr_low: PropTypes.number,
    irr_high: PropTypes.number,
    irr_gp: PropTypes.number,
    tvpi_base: PropTypes.number,
    tvpi_low: PropTypes.number,
    tvpi_high: PropTypes.number,
    tvpi_gp: PropTypes.number,
    created_by: PropTypes.number,
    created_date_gmt: PropTypes.string,
    modified_by: PropTypes.number,
    modified_date_gmt: PropTypes.string,
    data_source: PropTypes.string,
    is_reoc: PropTypes.number,
})

import React, {
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { SSGAppHeaderProvider } from './projects'

import { AppBar, Grid } from '../../../base'
import Layout from './Layout'

// Callback function to execute when payload mutations are observed
const payloadCallback = (update) => (mutationList, observer) => {
    for (const mutation of mutationList) {
        if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'data-payload'
        ) {
            update(mutation.target.dataset.payload)
        }
    }
}

// Callback function to execute when project mutations are observed
const projectCallback = (update) => (mutationList, observer) => {
    for (const mutation of mutationList) {
        if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'data-project'
        ) {
            update(mutation.target.dataset.project)
        }
    }
}

const SSGAppHeader = forwardRef(({ variant, ...props }) => {
    const ref = useRef()
    const [payload, setPayload] = useState(props['data-payload'])
    const [project, setProject] = useState(variant)
    const [parsedPayload, setParsedPayload] = useState([])

    // Create an observer instance linked to the callback function
    const PayloadObserver = new MutationObserver(payloadCallback(setPayload))
    const ProjectObserver = new MutationObserver(projectCallback(setProject))

    // update project on variant change (only usefull on storybook)
    useEffect(() => {
        if (variant !== project) {
            ref.current.setAttribute('data-project', variant)
        }
    }, [variant])

    useEffect(() => {
        // Start observing the target node
        PayloadObserver.observe(ref?.current, { attributes: true })
        ProjectObserver.observe(ref?.current, { attributes: true })
        return () => {
            // disconnect observer on component unmount
            PayloadObserver.disconnect()
            ProjectObserver.disconnect()
        }
    }, [ref])

    useEffect(() => {
        // parse payload if needed
        if (payload) {
            if (typeof payload === 'string' || payload instanceof String) {
                setParsedPayload(JSON.parse(decodeURIComponent(payload)))
            } else if (Array.isArray(payload)) {
                setParsedPayload(payload)
            } else {
                // not supported payload
                return null
            }
        }
    }, [payload])

    return (
        <div id="header" ref={ref} {...props}>
            <SSGAppHeaderProvider value={{ project }}>
                <AppBar isThemed>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing="16px"
                        sx={{
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            padding: '8px 24px',
                        }}
                    >
                        <Layout payload={parsedPayload} />
                    </Grid>
                </AppBar>
            </SSGAppHeaderProvider>
        </div>
    )
})

export default SSGAppHeader

import { Box, Link } from '../../base/index'
import React from 'react'
import PropTypes from 'prop-types'
import {
    formatDate,
    removeHtml,
    truncateWithSuffix,
} from '../../../helpers/formatters'

const NewsArticle = ({
    source,
    content,
    date,
    dateFormat,
    headline,
    uri,
    maxWords,
    truncateSuffix,
    marginBottom,
}) => {
    const contentNoHtml = removeHtml(content)
    const contentTruncated = truncateWithSuffix(
        contentNoHtml,
        maxWords,
        truncateSuffix
    )
    const formattedDate = formatDate(date, dateFormat)

    const linkStyle = {
        fontWeight: 'bold',
        fontSize: '12px',
    }

    const subHeaderBox = {
        color: '#999c9e',
        fontSize: '10px',
    }

    const contentBox = {
        display: 'block',
        'text-align': 'justify',
        'text-justify': 'inter-word',
        fontSize: '12px',
    }

    let subHeaderContent = ''

    if (source && formattedDate) {
        subHeaderContent = `${formattedDate}: ${source}`
    } else {
        subHeaderContent = `${formattedDate}${source}`
    }

    const mainBoxStyle = {
        marginBottom: marginBottom,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    }

    return (
        <Box sx={mainBoxStyle}>
            <Box>
                <Link customLinkStyle={linkStyle} href={uri} newTab={true}>
                    {headline}
                </Link>
            </Box>

            <Box sx={subHeaderBox}>({subHeaderContent})</Box>

            <Box sx={contentBox}>{contentTruncated}</Box>
        </Box>
    )
}

NewsArticle.propTypes = {
    content: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    source: PropTypes.string,
    date: PropTypes.string,
    maxWords: PropTypes.number,
    truncateSuffix: PropTypes.string,
    dateFormat: PropTypes.string,
    marginBottom: PropTypes.string,
}

NewsArticle.defaultProps = {
    source: '',
    date: '',
    maxWords: 20,
    truncateSuffix: '...',
    dateFormat: 'MM/dd/YYYY',
    marginBottom: '0px',
}

export default NewsArticle

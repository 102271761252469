import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';
import { SortOrderType } from 'types';

interface TableCellStyledInterface {
    children: React.ReactNode;
    className?: string;
    filterable?: boolean;
    sortDirection: false | SortOrderType;
    style?: Object;
}

interface CellContentInterface {
    children: React.ReactNode;
    sortable?: boolean;
}

export const useStyles = makeStyles((theme) => ({
    tableCell: ({ filterable }: TableCellStyledInterface) =>
        filterable
            ? {
                  paddingRight: theme.spacing(0.5),
              }
            : {},
    cellContent: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    cellChildren: ({ sortable }: CellContentInterface) =>
        sortable
            ? {}
            : {
                  flexGrow: 1,
              },
}));

export const TableCellStyled = (props: TableCellStyledInterface) => {
    const {
        children,
        sortDirection,
        className = '',
        style = {},
        // eslint-disable-next-line no-unused-vars
        filterable,
        ...other
    } = props;
    const classes = useStyles(props);

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <TableCell
            className={`${classes.tableCell} ${className}`}
            sortDirection={sortDirection}
            style={style}
            {...other}
        >
            {children}
        </TableCell>
    );
};

export const CellContent = (props: CellContentInterface) => {
    const { children } = props;
    const classes = useStyles(props);

    return <div className={classes.cellChildren}>{children}</div>;
};

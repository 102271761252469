import { Button } from '../../../../base'
import { FontAwesomeIcon, fas } from '../../../../fontAwesome'
import React from 'react'
import PropTypes from 'prop-types'
import AgGridButtonConstants from '../../constants/AgGridButtonConstants'

const ButtonToolbar = ({ buttonList, gridRef }) => {
    if (!buttonList) return null

    const togglePanel = (panel) => {
        const isOpen = gridRef?.current.api.isToolPanelShowing()

        if (!isOpen) {
            gridRef?.current.api.openToolPanel(panel)
            return
        }

        const currentPanelName = gridRef?.current.api.getOpenedToolPanel()

        if (currentPanelName === panel) {
            gridRef?.current.api.closeToolPanel(panel)
            return
        }

        gridRef?.current.api.openToolPanel(panel)
    }

    const clearAll = () => {
        clearFilters()
        clearSort()
    }

    const clearFilters = () => {
        if (!gridRef?.current) return

        gridRef?.current.api.setFilterModel(null)
    }

    const clearSort = () => {
        if (!gridRef?.current) return

        const columnDefs = gridRef?.current.columnApi.getAllColumns()

        const columnsWithSort = columnDefs.filter((column) => {
            const userProvidedColDef = column.userProvidedColDef

            return userProvidedColDef.sort
        })

        const sortModel = columnsWithSort.map((column) => {
            const userProvidedColDef = column.userProvidedColDef

            return {
                colId: column.getColId(),
                sort: userProvidedColDef.sort,
                sortIndex: userProvidedColDef.sortIndex,
            }
        })

        const sort = sortModel.length > 0 ? sortModel : null

        gridRef?.current.columnApi.applyColumnState({
            state: sort,
            defaultState: { sort: null },
        })
    }

    const exportXlsx = (exportParameters) => {
        const onClick = exportParameters.onClick

        onClick
            ? exportXlsxWithBackend(onClick)
            : exportXlsxWithAgGrid(exportParameters)
    }

    const exportXlsxWithAgGrid = (exportParameters) => {
        gridRef?.current.api.exportDataAsExcel(exportParameters)
    }

    const exportXlsxWithBackend = (onClick) => {
        const api = gridRef?.current.api
        const columnApi = gridRef?.current.columnApi

        const filterModel = getFilterModel()
        const sortModel = getSortModel()
        const numberOfRecords = api.getDisplayedRowCount()

        const visibleColumns = columnApi.getAllDisplayedColumns()
        const fieldNames = visibleColumns.map((column) => {
            const colDef = column.getColDef()

            if (colDef.exportField) return colDef.exportField
            if (colDef.field) return colDef.field

            return null
        })

        onClick({
            filterModel,
            sortModel,
            fieldNames,
            numberOfRecords,
        })
    }

    const getFilterModel = () => {
        const api = gridRef?.current.api
        const filterModel = api.getFilterModel()

        return filterModel
    }

    const getSortModel = () => {
        const columnApi = gridRef?.current.columnApi
        const columnState = columnApi.getColumnState()

        const sortModel = []

        columnState.forEach((column) => {
            if (!column.sort) return

            sortModel.push({
                sort: column.sort,
                colId: column.colId,
            })
        })

        return sortModel
    }

    const toggleFilterMenu = () => {
        const isOpen = gridRef?.current.api.isToolPanelShowing()

        if (!isOpen) {
            gridRef?.current.api.openToolPanel('filters')
            return
        }

        gridRef?.current.api.closeToolPanel('filters')
    }

    const defaultButtonProps = {
        icon: null,
        theme: 'secondary',
        label: '',
        size: 'medium',
    }

    let buttons =
        buttonList &&
        buttonList.length > 0 &&
        buttonList.map((button, index) => {
            let currentButtonProps = {}

            switch (button.type) {
                case AgGridButtonConstants.TYPE_SELECT_COLUMNS:
                    currentButtonProps = {
                        onClick: () => togglePanel('columns'),
                        theme: button.theme ?? defaultButtonProps.theme,
                        icon: button.icon ?? fas.faColumns,
                        label: button.label ?? 'Select Columns',
                        size: button.size ?? defaultButtonProps.size,
                    }
                    break
                case AgGridButtonConstants.TYPE_CLEAR_FILTERS:
                    currentButtonProps = {
                        onClick: clearFilters,
                        theme: button.theme ?? defaultButtonProps.theme,
                        icon: button.icon ?? fas.faArrowLeftRotate,
                        label: button.label ?? 'Clear Filters',
                        size: button.size ?? defaultButtonProps.size,
                    }
                    break
                case AgGridButtonConstants.TYPE_CLEAR_SORT:
                    currentButtonProps = {
                        onClick: clearSort,
                        theme: button.theme ?? defaultButtonProps.theme,
                        icon: button.icon ?? fas.faArrowLeftRotate,
                        label: button.label ?? 'Clear Sort',
                        size: button.size ?? defaultButtonProps.size,
                    }
                    break
                case AgGridButtonConstants.TYPE_CLEAR_ALL:
                    currentButtonProps = {
                        onClick: clearAll,
                        theme: button.theme ?? defaultButtonProps.theme,
                        icon: button.icon ?? fas.faArrowLeftRotate,
                        label: button.label ?? 'Clear All',
                        size: button.size ?? defaultButtonProps.size,
                    }
                    break
                case AgGridButtonConstants.TYPE_EXPORT_XLSX:
                    const defaults = {
                        prependContent: null,
                        appendContent: null,
                        fileName: 'export.xlsx',
                        fontSize: 11,
                        allColumns: false,
                        onlySelected: false,
                    }

                    const exportParameters = {
                        ...defaults,
                        ...(button.exportParams || {}),
                    }

                    currentButtonProps = {
                        onClick: () => exportXlsx(exportParameters),
                        theme: button.theme ?? defaultButtonProps.theme,
                        icon: button.icon ?? fas.faFileExcel,
                        label: button.label ?? 'Export XLSX',
                        size: button.size ?? defaultButtonProps.size,
                    }
                    break
                case AgGridButtonConstants.TYPE_SELECT_FILTERS:
                    currentButtonProps = {
                        theme: button.theme ?? 'primary',
                        onClick: () => togglePanel('filters'),
                        icon: button.icon ?? fas.faSearch,
                        label: button.label ?? 'Select Filters',
                        size: button.size ?? defaultButtonProps.size,
                    }
                    break
                default:
                    currentButtonProps = {
                        onClick: button.onClick ?? defaultButtonProps.onClick,
                        icon: button.icon ?? defaultButtonProps.icon,
                        label: button.label ?? defaultButtonProps.label,
                        theme: button.theme ?? defaultButtonProps.theme,
                        size: button.size ?? defaultButtonProps.size,
                    }
            }

            currentButtonProps.icon = currentButtonProps.icon && (
                <FontAwesomeIcon
                    icon={currentButtonProps.icon}
                    style={{ fontSize: '16px' }}
                />
            )

            const buttonProps = {
                ...defaultButtonProps,
                ...currentButtonProps,
            }

            return (
                <Button {...buttonProps} key={index}>
                    {buttonProps.label}
                </Button>
            )
        })

    const flexContainer = {
        display: 'flex',
        gap: '0.5em',
    }

    return <div style={flexContainer}>{buttons}</div>
}

export default ButtonToolbar

ButtonToolbar.propTypes = {
    buttonList: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            theme: PropTypes.oneOf([
                'primary',
                'secondary',
                'accept',
                'progress',
                'reject',
                'info',
                'link',
                'delete',
            ]),
            onClick: PropTypes.func,
            label: PropTypes.string,
            icon: PropTypes.object,
            size: PropTypes.oneOf(['small', 'medium']),
        })
    ),
    gridRef: PropTypes.object.isRequired,
}

ButtonToolbar.defaultProps = {
    buttonList: [],
}

import styled from 'styled-components'
import { Grid } from '@mui/material'

export const EntityTypeText = styled.span`
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`
export const LinkWrapper = styled(Grid)`
    display: block !important;
`
export const EntityDescText = styled.span`
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    padding-left: 0.5em;
    text-align: right;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`
export const LinkStyled = styled.a`
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    text-decoration: none;
    color: rgb(0, 120, 153);
    &:hover {
        color: #00607a;
    }
`

import React, { Component } from 'react'
import { Autocomplete } from '../../../../base'
import AgGridFilterModelBuilder from '../../utils/models/AgGridFilterModelBuilder'
import AgGridFilterConstants from '../../constants/AgGridFilterConstants'

class AutoCompleteFilter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            options: [],
            selectedValues: [],
            selectedObjects: [],
            loading: false,
        }
    }

    isFilterActive = () => {
        return this.state.selectedValues.length > 0
    }

    doesFilterPass = (params) => {
        const { valueGetter } = params
        const value = valueGetter(params)

        const selectedValues = this.state.selectedValues

        if (selectedValues.length === 0) return true

        return selectedValues.includes(value)
    }

    getModel = () => {
        const selectedValues = this.state.selectedValues
        const keysAreNumbers = selectedValues.every(
            (key) => typeof key === 'number'
        )

        if (selectedValues.length === 0) return undefined

        const modelBuilder = new AgGridFilterModelBuilder()

        const type = keysAreNumbers
            ? AgGridFilterConstants.FILTER_TYPE_NUMBER
            : AgGridFilterConstants.FILTER_TYPE_TEXT

        modelBuilder.setType(type)
        modelBuilder.setFilter(selectedValues)
        modelBuilder.setFilterType(AgGridFilterConstants.CONDITION_WHERE_IN)

        return {
            ...modelBuilder.build(),
        }
    }

    setModel = (model, param1, param2) => {
        if (model === null) {
            this.setSelectedValues([], [])

            return
        }
    }

    setSelectedValues = (selectedValues, selectedObjects) => {
        this.setState(
            {
                selectedValues,
                selectedObjects,
            },
            () => this.props.filterChangedCallback()
        )
    }

    onInputChange = (event, value) => {
        const minimumInputLength = this.props.minimumInputLength

        if (value.length < minimumInputLength) return

        this.setState({ loading: true })

        const debounce = this.props.debounceMs

        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            this.getOptions(value)
        }, debounce)
    }

    getOptions(value) {
        if (value === undefined || value === '') return

        const minimumInputLength = this.props.minimumInputLength

        if (value.length < minimumInputLength) return

        this.props.optionPromise(value).then((options) => {
            this.setState({
                loading: false,
                options,
            })
        })
    }

    onChange = (changedValue) => {
        let value = changedValue

        if (!Array.isArray(changedValue)) {
            value = [changedValue]
        }

        const uniqueValues = []

        value.forEach((valueItem) => {
            if (!this.valueIsDefined(valueItem)) return

            const valueItemKey = this.getModelKey(valueItem)

            const exists = uniqueValues.some((uniqueValue) => {
                const uniqueValueKey = this.getModelKey(uniqueValue)

                return uniqueValueKey === valueItemKey
            })

            if (exists) return

            uniqueValues.push(valueItem)
        })

        const selectedObjects = uniqueValues
        const selectedValues = this.getModelKeys(selectedObjects)

        this.setSelectedValues(selectedValues, selectedObjects)
    }

    valueIsDefined(value) {
        if (Array.isArray(value)) return value.length > 0

        return value !== '' && value !== null && value !== undefined
    }

    setToArray = (set) => {
        if (!set) return []

        return Array.from(set)
    }

    getModelKeys = (models) => {
        return models.map((model) => {
            return this.getModelKey(model)
        })
    }

    getModelKey = (model) => {
        if (this.props.keyFormatter) {
            return this.props.keyFormatter(model)
        }

        return model
    }

    render = () => {
        return (
            <Autocomplete
                isThemed={true}
                icon={this.props.icon}
                value={this.state.selectedObjects}
                loading={this.state.loading}
                loadingText={this.props.loadingText}
                multiple={this.props.multiple}
                options={this.state.options}
                onChange={this.onChange}
                onInputChange={this.onInputChange}
                getOptionLabel={this.props.labelFormatter}
                placeholder={this.props.placeholder}
                freeSolo={true}
                filterOptions={(options) => options}
                getOptionKey={this.props.keyFormatter}
                isOptionEqualToValue={(option, value) => {
                    const optionKey = this.props.keyFormatter(option)
                    const valueKey = this.props.keyFormatter(value)

                    return optionKey === valueKey
                }}
            />
        )
    }
}

export default AutoCompleteFilter

import React from 'react'
import { Grid } from '@mui/material'
import { thirdPartyProviderPropType } from './ThirdPartyProviderTypes'
import { LinkWrapper, EntityTypeText, LinkStyled } from '../styles'

function ThirdPartyProviderGridDisplay({ thirdPartyProvider }) {
    if (!thirdPartyProvider) {
        return <></>
    }

    const { id, name } = thirdPartyProvider

    return (
        <Grid container>
            <LinkWrapper item container xs={12}>
                <EntityTypeText>Third-Party Provider: </EntityTypeText>
                <LinkStyled
                    href={`/m/institution-profile-third-party/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Go to Institution Third Party Profile (ID ${id})`}
                >
                    {name}
                </LinkStyled>
            </LinkWrapper>
        </Grid>
    )
}

ThirdPartyProviderGridDisplay.propTypes = {
    thirdPartyProvider: thirdPartyProviderPropType,
}

ThirdPartyProviderGridDisplay.defaultProps = { thirdPartyProvider: null }

export default ThirdPartyProviderGridDisplay

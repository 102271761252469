import styled from 'styled-components'

export const ErrorCodeDisplay = styled.h2`
    font-size: 4em;
    font-weight: normal;
    color: rgba(51, 51, 51, 0.08);
    margin: 0;
`

export const ErrorMessageDisplay = styled.p`
    font-size: 2em;
    margin: 5px 0 0 35px;
`

export const GridStyle = { marginTop: '10rem', marginBottom: '10rem' }

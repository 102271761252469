import { FilterType } from 'types';

export const equal: FilterType = {
    value: 'equal',
    text: 'Equals',
    comparator: 'eq',
    inputValue: true,
};

export const notEqual: FilterType = {
    value: 'notEqual',
    text: 'Does Not Equal',
    comparator: 'neq',
    inputValue: true,
};

export const greater: FilterType = {
    value: 'greater',
    text: 'Greater Than',
    comparator: 'gt',
    inputValue: true,
};

export const less: FilterType = {
    value: 'less',
    text: 'Less than',
    comparator: 'lt',
    inputValue: true,
};

export const greaterOrEqual: FilterType = {
    value: 'greaterOrEqual',
    text: 'Greater Than Or Equal To',
    comparator: 'gte',
    inputValue: true,
};

export const lessOrEqual: FilterType = {
    value: 'lessOrEqual',
    text: 'Less Than Or Equal To',
    comparator: 'lte',
    inputValue: true,
};

export const contains: FilterType = {
    value: 'contains',
    text: 'Contains',
    comparator: 'contains',
    inputValue: true,
};

export const doesNotContain: FilterType = {
    value: 'doesNotContain',
    text: 'Does Not Contain',
    comparator: 'doesNotContain',
    inputValue: true,
};

export const beginsWith: FilterType = {
    value: 'beginsWith',
    text: 'Begins With',
    comparator: 'beginsWith',
    inputValue: true,
};

export const doesNotBeginWith: FilterType = {
    value: 'doesNotBeginWith',
    text: 'Does Not Begin With',
    comparator: 'doesNotBeginWith',
    inputValue: true,
};

export const endsWith: FilterType = {
    value: 'endsWith',
    text: 'Ends With',
    comparator: 'endsWith',
    inputValue: true,
};

export const doesNotEndWith: FilterType = {
    value: 'doesNotEndWith',
    text: 'Does Not End With',
    comparator: 'doesNotEndWith',
    inputValue: true,
};

export const isTrue: FilterType = {
    value: 'isTrue',
    text: 'Is True',
    comparator: 'isTrue',
    inputValue: false,
};

export const isFalse: FilterType = {
    value: 'isFalse',
    text: 'Is False',
    comparator: 'isValse',
    inputValue: false,
};

export const isNull: FilterType = {
    value: 'isNull',
    text: 'Is Null',
    comparator: 'isNull',
    inputValue: false,
};

export const isNotNull: FilterType = {
    value: 'isNotNull',
    text: 'Is Not Null',
    comparator: 'isNotNull',
    inputValue: false,
};

export const isBlank: FilterType = {
    value: 'isBlank',
    text: 'Is Blank',
    comparator: 'isBlank',
    inputValue: false,
};

export const isNotBlank: FilterType = {
    value: 'isNotBlank',
    text: 'Is Not Blank',
    comparator: 'isNotBlank',
    inputValue: false,
};

export const before: FilterType = {
    value: 'before',
    text: 'Before',
    comparator: 'before',
    inputValue: 'date',
};

export const after: FilterType = {
    value: 'after',
    text: 'After',
    comparator: 'after',
    inputValue: 'date',
};

export const onOrBefore: FilterType = {
    value: 'onOrBefore',
    text: 'On Or Before',
    comparator: 'onOrBefore',
    inputValue: 'date',
};

export const onOrAfter: FilterType = {
    value: 'onOrAfter',
    text: 'On Or After',
    comparator: 'onOrAfter',
    inputValue: 'date',
};

export const on: FilterType = {
    value: 'on',
    text: 'On',
    comparator: 'on',
    inputValue: 'date',
};

import React from 'react'
import PropTypes from 'prop-types'
import TableTitleSubtitle from './TableTitleSubtitle'
import ButtonToolbar from './ButtonToolbar'
import { Grid } from '../../../../base'

const TableHeader = ({
    title,
    subtitle,
    buttonList,
    gridRef,
    tableHeaderMarginBottom,
}) => {
    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: tableHeaderMarginBottom,
                }}
            >
                <Grid item xs={4}>
                    <TableTitleSubtitle title={title} subtitle={subtitle} />
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingTop: title ? '0.25rem' : '0px',
                    }}
                >
                    <ButtonToolbar gridRef={gridRef} buttonList={buttonList} />
                </Grid>
            </Grid>
        </>
    )
}

export default TableHeader

TableHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonList: PropTypes.arrayOf(PropTypes.object),
    gridRef: PropTypes.object.isRequired,
    tableHeaderMarginBottom: PropTypes.string,
}

TableHeader.defaultProps = {
    tableHeaderMarginBottom: '1em',
}

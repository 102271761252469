export const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

const DEFAULT_LAYOUT = {
    xs: [{ id: 0, params: {} }],
    sm: [],
    md: [],
    lg: [],
    xl: [],
}

export const spi_research = {
    xs: [{ id: 0 }, { id: 1 }, { id: 3, xs: true }, { id: 4 }, { id: 6 }],
    sm: [
        { id: 0 },
        { id: 1 },
        { id: 3, sm: true },
        { id: 4 },
        { id: 6 },
        { id: 2, align: 'center', md: 12 },
    ],
    md: undefined,
    lg: [],
    xl: [],
}

export const spa_research = {
    xs: [{ id: 0, params: {} }],
    sm: [],
    md: [],
}

export const spi_pacing = {
    xs: [{ id: 0, params: {} }],
    sm: [],
    md: [],
}

export const spi_benchmarking = {
    xs: [],
    sm: undefined,
    md: undefined,
    lg: undefined,
    xl: undefined,
}

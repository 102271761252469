import React, { forwardRef } from 'react'
import { Box } from '../../base'
import { CommentHeaderStyled, CommentsHeader, CommentsWrapper } from './styles'
import CommentsList from './CommentsList'
import CommentsForm from './CommentsForm'
import { CommentProvider } from './data-provider'

const CommentsContainer = forwardRef((props, ref) => {
    return (
        <CommentProvider value={props}>
            <Box
                sx={{
                    border: '1px solid #DEDEE0',
                    borderRadius: '0.25rem',
                    backgroundColor: '#ffffff',
                }}
                ref={ref}
            >
                <CommentsHeader>
                    <CommentHeaderStyled variant="h3">
                        {props.title ?? 'Comments'}
                    </CommentHeaderStyled>
                </CommentsHeader>
                <CommentsWrapper>
                    <CommentsForm />
                    <CommentsList />
                </CommentsWrapper>
            </Box>
        </CommentProvider>
    )
})

export default CommentsContainer

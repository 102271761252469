import { createTheme } from '@mui/material/styles'
import { createButtonVariant } from './utils'
import { fontSize } from '@mui/system'

export const COLOR_NAMES = {
    white: '#FFFFFF',
    skyBlue: '#5EB6EC',
    darkSky: '#2E8EE4',
    darkBlue: '#1A526A',
    blue: '#007899',
    honey: '#FFB72B',
    orangeHoney: '#F49A31',
    green: '#08916D',
    darkGreen: '#116E4A',
    red: '#EF353B',
    darkRed: '#CD2833',
    persianRed: '#D32F2F',
    orange: '#F47216',
    violet: '#764EFD',
    teal: '#37C2CC',
    magenta: '#EB2AA4',
    cloudy: '#EAF0FA',
    sealGray: '#66788A',
    blueGray: '#D0DAE4',
    gray: 'rgba(218, 220, 224, 0.5)',
    lightGray: '#E3E4E7',
    secondaryLightGray: '#DEDEE0',
    secondaryLightBlue: '#268FB2',
    secondaryDarkBlue: '#176680',
    black: '#333333',
    secondaryGray: '#BDBFBF',
    darkGray: '#787A7D',
    limeade: '#78A300',
    tango: '#EA7821',
    buttercup: '#F7AD21',
    tulipTree: '#E5A237',
}

export const HOVER_COLOR_NAMES = {
    secondaryGray: '#1976D20A',
    limeade: '#78A30033',
    tango: '#EA782133',
    buttercup: '#D7384833',
    blue: '#00789933',
}

export const COLOR_STONE = {
    50: '#e2f4fe',
    100: '#b5e5fc',
    200: '#85d5f8',
    300: '#57c4f1',
    400: '#38b9ea',
    500: '#2daee1',
    600: '#249fcd',
    700: '#138bb2',
    800: '#007899',
    900: '#00576c',
}

export const COLOR_SEA = {
    50: '#dceffa',
    100: '#bad5df',
    200: '#97b7c4',
    300: '#729aaa',
    400: '#578496',
    500: '#3a6f82',
    600: '#2f6172',
    700: '#224e5d',
    800: '#153b48',
    900: '#032732',
}

export const COLOR_GRAY = {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EAEAEA',
    300: '#E1E1E1',
    400: '#CACACA',
    500: '#B3B3B3',
    600: '#8E8E8E',
    700: '#6E6E6E',
    800: '#4B4B4B',
    900: '#2C2C2C',
}

export const COLOR_WHITE = '#FFFFFF'

// todo: will need to be removed/refactored after v1.0 alpha design updates
export const APP_COLORS = {
    primary: COLOR_NAMES.white,
    primary_bg: COLOR_NAMES.blue,
    primary_bg_hover: COLOR_NAMES.darkBlue,
    primary_border: COLOR_NAMES.blue,
    primary_box_shadow: COLOR_NAMES.gray,
    secondary: COLOR_NAMES.black,
    secondary_bg: COLOR_NAMES.white,
    secondary_border: COLOR_NAMES.gray,
    secondary_hover: COLOR_NAMES.blue,
    secondary_border_hover: COLOR_NAMES.blue,
    tertiary: COLOR_NAMES.black,
    tertiary_bg: COLOR_NAMES.gray,
    tertiary_bg_hover: COLOR_NAMES.lightGray,
    body: COLOR_NAMES.black,
    heading: COLOR_NAMES.black,
    success_bg: COLOR_NAMES.green,
    success_bg_hover: COLOR_NAMES.darkGreen,
    info_bg: COLOR_NAMES.skyBlue,
    info_bg_hover: COLOR_NAMES.darkSky,
    warning_bg: COLOR_NAMES.honey,
    warning_bg_hover: COLOR_NAMES.orangeHoney,
    danger_bg: COLOR_NAMES.red,
    danger_bg_hover: COLOR_NAMES.darkRed,
    icon: COLOR_NAMES.sealGray,
    secondary_icon: COLOR_NAMES.blue,
    link: COLOR_NAMES.blue,
    link_hover: COLOR_NAMES.darkBlue,
}

// todo: remove input styles from styleguide and place at component level
export const APP_INPUT = {
    border: `1px solid ${COLOR_NAMES.blueGray}`,
    borderHover: `1px solid ${COLOR_NAMES.blue}`,
    borderFocus: `1px solid ${COLOR_NAMES.blue}`,
    borderRadius: '5px',
    transition: 'border 0.2s ease-out',
    // Roboto (400) , 15px , #525F69
}

// todo: may need to refactor after v1.0 alpha design updates
export const APP_PALETTE = {
    ssg: [
        '#007899',
        '#BDBFBF',
        '#78A300',
        '#F7AD21',
        '#F7E308',
        '#268FB2',
        '#787A7D',
        '#A6CC38',
        '#F29121',
        '#F2C70D',
        '#78BFD6',
        '#999C9E',
        '#D1D926',
        '#EA7821',
        '#176680',
        '#DEDEE0',
    ],
    cerulean: {
        tint_50: '#F0FAFB',
        tint_100: '#D9F7F9',
        tint_200: '#ACEFF2',
        tint_300: '#73E1EC',
        tint_400: '#2EC834',
        tint_500: '#10A807',
        tint_600: '#0D88C0',
        tint_700: '#007899',
        tint_800: '#135375',
        tint_900: '#114359',
    },
    orange: {
        tint_50: '#F9F5E5',
        tint_100: '#FAEFBE',
        tint_200: '#F6E57F',
        tint_300: '#F1D23C',
        tint_400: '#FFB72B',
        tint_500: '#E49407',
        tint_600: '#D37005',
        tint_700: '#B25408',
        tint_800: '#91420F',
        tint_900: '#773610',
    },
    denim: {
        tint_50: '#F5FAFC',
        tint_100: '#E7F7FA',
        tint_200: '#C6EAF5',
        tint_300: '#9DD8F2',
        tint_400: '#5EB6EC',
        tint_500: '#2E8EE4',
        tint_600: '#216AD2',
        tint_700: '#2053AC',
        tint_800: '#1C407D',
        tint_900: '#113159',
    },
    shamrock: {
        tint_50: '#EBF8F7',
        tint_100: '#CFF7F1',
        tint_200: '#9DF2E0',
        tint_300: '#5DE9CC',
        tint_400: '#18D9AC',
        tint_500: '#08C486',
        tint_600: '#07AA69',
        tint_700: '#08916D',
        tint_800: '#116E4A',
        tint_900: '#10593E',
    },
    sky: {
        tint_50: '#EEF9F9',
        tint_100: '#D4F7F6',
        tint_200: '#A3F0EB',
        tint_300: '#66E5E1',
        tint_400: '#23D1D0',
        tint_500: '#37C2CC',
        tint_600: '#09999D',
        tint_700: '#0F7C7D',
        tint_800: '#116060',
        tint_900: '#104D4D',
    },
    mango: {
        tint_50: '#FAF6ED',
        tint_100: '#FBED06',
        tint_200: '#F9DCAC',
        tint_300: '#F6C26D',
        tint_400: '#F49A31',
        tint_500: '#F47216',
        tint_600: '#F35F23',
        tint_700: '#CB3814',
        tint_800: '#A62F19',
        tint_900: '#882719',
    },
    royal_blue: {
        tint_50: '#F0FAFB',
        tint_100: '#D9F7F9',
        tint_200: '#ACEFF2',
        tint_300: '#73E1EC',
        tint_400: '#2EC834',
        tint_500: '#10A807',
        tint_600: '#0D88C0',
        tint_700: '#007899',
        tint_800: '#135375',
        tint_900: '#114359',
    },
    gray: {
        tint_50: '#F0FAFB',
        tint_100: '#D9F7F9',
        tint_200: '#ACEFF2',
        tint_300: '#73E1EC',
        tint_400: '#2EC834',
        tint_500: '#10A807',
        tint_600: '#0D88C0',
        tint_700: '#007899',
        tint_800: '#135375',
        tint_900: '#114359',
    },
    cerise: {
        tint_50: '#F0FAFB',
        tint_100: '#D9F7F9',
        tint_200: '#ACEFF2',
        tint_300: '#73E1EC',
        tint_400: '#2EC834',
        tint_500: '#10A807',
        tint_600: '#0D88C0',
        tint_700: '#007899',
        tint_800: '#135375',
        tint_900: '#114359',
    },
    sunset: {
        tint_50: '#F0FAFB',
        tint_100: '#D9F7F9',
        tint_200: '#ACEFF2',
        tint_300: '#73E1EC',
        tint_400: '#2EC834',
        tint_500: '#10A807',
        tint_600: '#0D88C0',
        tint_700: '#007899',
        tint_800: '#135375',
        tint_900: '#114359',
    },
}

/**
 * todo: commented out line_height because it breaks code, will need to follow-up with Sylvia
 */
export const APP_TYPOGRAPHY = {
    heading_1: {
        font_size: '34px',
        font_family: "'Open Sans'",
        font_weight: '600',
        color: COLOR_GRAY['900'],
        // line_height: '0',
        letter_spacing: '0.25px',
    },
    heading_2: {
        font_size: '28px',
        font_family: "'Open Sans'",
        font_weight: '600',
        color: COLOR_GRAY['900'],
        // line_height: '0',
        letter_spacing: '0.25px',
    },
    heading_3: {
        font_size: '22px',
        font_family: "'Open Sans'",
        font_weight: '600',
        color: COLOR_GRAY['900'],
        // line_height: '24px',
        letter_spacing: '0.15px',
    },
    heading_4: {
        font_size: '18px',
        font_family: "'Open Sans'",
        font_weight: '600',
        color: COLOR_GRAY['900'],
        line_height: '24px',
        letter_spacing: '0.15px',
    },
    heading_5: {
        font_size: '16px',
        font_family: "'Open Sans'",
        font_weight: '600',
        color: COLOR_GRAY['900'],
        line_height: '24px',
        letter_spacing: '0.15px',
    },
    body_1: {
        font_size: '16px',
        font_family: 'Roboto',
        font_weight: '400',
        color: COLOR_GRAY['900'],
        // line_height: '24px',
        letter_spacing: '0.5px',
    },
    body_2: {
        font_size: '14px',
        font_family: 'Roboto',
        font_weight: '400',
        color: COLOR_GRAY['900'],
        // line_height: '24px',
        letter_spacing: '0.25px',
    },
    body_3: {
        font_size: '12px',
        font_family: 'Roboto',
        font_weight: '400',
        color: COLOR_GRAY['900'],
        // line_height: '24px',
        letter_spacing: '0.4px',
    },
}

// todo: determine if these are necessary
// export const APP_MEDIA_QUERIES = {
//     min_tablet: `@media screen and (min-width: ${APP_DISPLAY_SIZES.tablet}px)`,
//     min_desktop: `@media screen and (min-width: ${APP_DISPLAY_SIZES.desktop}px)`,
// };

export const APP_BREAKPOINTS = {
    // xxs is less than 304px but doesn't need to be defined
    xsmall: 304,
    small: 768,
    medium: 1280,
    large: 1768,
    xlarge: 2160,
}

// DEFAULT THEME
export const SSG_FONT_FAMILY = '"Roboto","Helvetica","Arial",sans-serif'
const SSG_TYPOGRAPHY = {
    body_1: {
        fontFamily: SSG_FONT_FAMILY,
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0em',
    },
}

export const APP_THEME = createTheme({
    palette: {
        primary: {
            main: APP_COLORS.primary_bg,
        },
        secondary: {
            main: APP_COLORS.secondary_bg,
        },
    },
    overrides: {
        MuiAvatar: {
            colorDefault: {
                backgroundColor: COLOR_SEA['900'],
            },
        },
        MuiBreadcrumbs: {
            separator: {
                color: COLOR_SEA['900'],
            },
        },
        MuiLink: {
            root: {
                color: COLOR_STONE['800'],
            },
        },
        MuiSvgIcon: {
            root: {
                color: COLOR_SEA['900'],
            },
        },
        MuiTypography: {
            root: {
                color: COLOR_SEA['900'],
            },
        },
    },
    props: {
        /**
         * Add component props to globally override/set
         */
    },
    typography: {
        h1: {
            fontSize: APP_TYPOGRAPHY.heading_1.font_size,
            fontFamily: APP_TYPOGRAPHY.heading_1.font_family,
            fontWeight: APP_TYPOGRAPHY.heading_1.font_weight,
            color: APP_TYPOGRAPHY.heading_1.color,
            lineHeight: APP_TYPOGRAPHY.heading_1.line_height,
            letterSpacing: APP_TYPOGRAPHY.heading_1.letter_spacing,
        },
        h2: {
            fontSize: APP_TYPOGRAPHY.heading_2.font_size,
            fontFamily: APP_TYPOGRAPHY.heading_2.font_family,
            fontWeight: APP_TYPOGRAPHY.heading_2.font_weight,
            color: APP_TYPOGRAPHY.heading_2.color,
            lineHeight: APP_TYPOGRAPHY.heading_2.line_height,
            letterSpacing: APP_TYPOGRAPHY.heading_2.letter_spacing,
        },
        h3: {
            fontSize: APP_TYPOGRAPHY.heading_3.font_size,
            fontFamily: APP_TYPOGRAPHY.heading_3.font_family,
            fontWeight: APP_TYPOGRAPHY.heading_3.font_weight,
            color: APP_TYPOGRAPHY.heading_3.color,
            lineHeight: APP_TYPOGRAPHY.heading_3.line_height,
            letterSpacing: APP_TYPOGRAPHY.heading_3.letter_spacing,
        },
        h4: {
            fontSize: APP_TYPOGRAPHY.heading_4.font_size,
            fontFamily: APP_TYPOGRAPHY.heading_4.font_family,
            fontWeight: APP_TYPOGRAPHY.heading_4.font_weight,
            color: APP_TYPOGRAPHY.heading_4.color,
            lineHeight: APP_TYPOGRAPHY.heading_4.line_height,
            letterSpacing: APP_TYPOGRAPHY.heading_4.letter_spacing,
        },
        h5: {
            fontSize: APP_TYPOGRAPHY.heading_5.font_size,
            fontFamily: APP_TYPOGRAPHY.heading_5.font_family,
            fontWeight: APP_TYPOGRAPHY.heading_5.font_weight,
            color: APP_TYPOGRAPHY.heading_5.color,
            lineHeight: APP_TYPOGRAPHY.heading_5.line_height,
            letterSpacing: APP_TYPOGRAPHY.heading_5.letter_spacing,
        },
        body_1: {
            fontSize: APP_TYPOGRAPHY.body_1.font_size,
            fontFamily: APP_TYPOGRAPHY.body_1.font_family,
            fontWeight: APP_TYPOGRAPHY.body_1.font_weight,
            color: APP_TYPOGRAPHY.body_1.color,
            lineHeight: APP_TYPOGRAPHY.body_1.line_height,
            letterSpacing: APP_TYPOGRAPHY.body_1.letter_spacing,
        },
        body_2: {
            fontSize: APP_TYPOGRAPHY.body_2.font_size,
            fontFamily: APP_TYPOGRAPHY.body_2.font_family,
            fontWeight: APP_TYPOGRAPHY.body_2.font_weight,
            color: APP_TYPOGRAPHY.body_2.color,
            lineHeight: APP_TYPOGRAPHY.body_2.line_height,
            letterSpacing: APP_TYPOGRAPHY.body_2.letter_spacing,
        },
        body_3: {
            fontSize: APP_TYPOGRAPHY.body_3.font_size,
            fontFamily: APP_TYPOGRAPHY.body_3.font_family,
            fontWeight: APP_TYPOGRAPHY.body_3.font_weight,
            color: APP_TYPOGRAPHY.body_3.color,
            lineHeight: APP_TYPOGRAPHY.body_3.line_height,
            letterSpacing: APP_TYPOGRAPHY.body_3.letter_spacing,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    height: ownerState.size === 'small' ? '30px' : '38px',
                    minWidth: ownerState.size === 'small' ? '64px' : '88px',
                }),
            },

            variants: [
                {
                    props: { variant: 'primary' },
                    style: createButtonVariant(
                        COLOR_NAMES.blue,
                        COLOR_NAMES.white,
                        null,
                        APP_PALETTE.ssg[14],
                        APP_TYPOGRAPHY.body_3.font_size,
                        SSG_FONT_FAMILY
                    ),
                },
                {
                    props: { variant: 'secondary' },
                    style: createButtonVariant(
                        COLOR_NAMES.white,
                        COLOR_NAMES.black,
                        COLOR_NAMES.secondaryGray,
                        HOVER_COLOR_NAMES.secondaryGray,
                        APP_TYPOGRAPHY.body_3.font_size,
                        SSG_FONT_FAMILY
                    ),
                },
                {
                    props: { variant: 'accept' },
                    style: createButtonVariant(
                        COLOR_NAMES.white,
                        COLOR_NAMES.limeade,
                        COLOR_NAMES.limeade,
                        HOVER_COLOR_NAMES.limeade,
                        APP_TYPOGRAPHY.body_3.font_size,
                        SSG_FONT_FAMILY
                    ),
                },
                {
                    props: { variant: 'progress' },
                    style: createButtonVariant(
                        COLOR_NAMES.white,
                        COLOR_NAMES.tango,
                        COLOR_NAMES.tango,
                        HOVER_COLOR_NAMES.tango,
                        APP_TYPOGRAPHY.body_3.font_size,
                        SSG_FONT_FAMILY
                    ),
                },
                {
                    props: { variant: 'reject' },
                    style: createButtonVariant(
                        COLOR_NAMES.white,
                        COLOR_NAMES.buttercup,
                        COLOR_NAMES.buttercup,
                        HOVER_COLOR_NAMES.buttercup,
                        APP_TYPOGRAPHY.body_3.font_size,
                        SSG_FONT_FAMILY
                    ),
                },
                {
                    props: { variant: 'info' },
                    style: createButtonVariant(
                        COLOR_NAMES.white,
                        COLOR_NAMES.blue,
                        COLOR_NAMES.blue,
                        HOVER_COLOR_NAMES.blue,
                        APP_TYPOGRAPHY.body_3.font_size,
                        SSG_FONT_FAMILY
                    ),
                },
                {
                    props: { variant: 'delete' },
                    style: createButtonVariant(
                        COLOR_NAMES.buttercup,
                        COLOR_NAMES.white,
                        null,
                        COLOR_NAMES.tulipTree,
                        APP_TYPOGRAPHY.body_3.font_size,
                        SSG_FONT_FAMILY
                    ),
                },
                {
                    props: { variant: 'link' },
                    style: {
                        backgroundColor: COLOR_NAMES.white,
                        color: COLOR_NAMES.blue,
                        textTransform: 'none',
                        '&:hover': {
                            textDecorationLine: 'underline',
                            backgroundColor: 'transparent',
                        },
                    },
                },
                {
                    props: { variant: 'grid-link' },
                    style: {
                        minWidth: 'auto',
                        height: 'auto',
                        padding: '0',
                        fontSize: '12px',
                        backgroundColor: 'transparent',
                        color: COLOR_NAMES.blue,
                        textTransform: 'none',
                        '&:hover': {
                            textDecorationLine: 'underline',
                            backgroundColor: 'transparent',
                        },
                        '& .MuiTouchRipple-root': {
                            display: 'none',
                        },
                    },
                },
                {
                    props: { disabled: true },
                    style: {
                        backgroundColor: COLOR_NAMES.secondaryLightGray,
                        color: COLOR_NAMES.secondaryGray,
                        border: 'none',
                    },
                },
            ],
        },

        MuiTab: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    const { disabled } = ownerState
                    let defaultStyle = {
                        fontSize: '12px',
                        textTransform: 'capitalize',
                    }

                    if (ownerState.variant === 'ssg') {
                        defaultStyle = {
                            ...defaultStyle,
                            borderTopWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: COLOR_NAMES.secondaryLightGray,
                            borderStyle: 'solid',

                            '&:last-child': {
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px',
                                borderRightWidth: 1,
                            },
                            '&:first-child': {
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px',
                            },
                            '&.Mui-selected': {
                                color: COLOR_NAMES.blue,
                                backgroundColor: 'rgba(0, 120, 153, 0.20)',
                            },
                            '&.Mui-disabled': {
                                color: COLOR_NAMES.secondaryLightGray,
                                backgroundColor: 'white',
                            },
                        }
                    } else if (ownerState.variant === 'isThemed') {
                        defaultStyle = {
                            ...defaultStyle,
                            '&.MuiButtonBase-root': {
                                padding: '0',
                                display: 'flex',
                                alignItems: 'baseline',
                                paddingRight: '48px',
                                paddingLeft: '4px',
                            },
                            '&.Mui-selected': {
                                color: COLOR_NAMES.blue,
                            },
                            '&.Mui-disabled': {
                                color: COLOR_NAMES.secondaryLightGray,
                                backgroundColor: 'white',
                            },
                        }
                    }
                    return defaultStyle
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: ({ ownerState }) => {
                    if (ownerState.variant === 'isThemed') {
                        return {
                            height: 'fit-content',
                            borderBottom: `1px solid ${COLOR_NAMES.secondaryLightGray}`,
                        }
                    }
                },
                indicator: ({ ownerState }) => {
                    if (ownerState.variant === 'ssg') {
                        return {
                            display: 'none',
                        }
                    }
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    const styles = {
                        ...SSG_TYPOGRAPHY.body_1,
                        color: COLOR_NAMES.black,
                    }

                    if (ownerState['tek-label-for'] === 'radiogroup') {
                        styles.paddingBottom = '8px'
                        styles.display = 'inline-block'
                    }

                    return styles
                },
            },
        },

        MuiFormControlLabel: {
            styleOverrides: {
                label: ({ ownerState }) => {
                    const styles = {
                        ...SSG_TYPOGRAPHY.body_1,
                        color: COLOR_NAMES.black,
                    }

                    if (ownerState['tek-label-for'] === 'switch') {
                        styles.marginLeft = '0'
                        styles.marginRight = '8px'
                    }

                    if (ownerState['tek-label-for'] === 'checkbox') {
                        styles.display = 'flex'
                        styles.alignItems = 'center'
                        styles.gap = '4px'
                    }

                    return styles
                },
                root: ({ ownerState }) => {
                    if (
                        ['radio', 'switch', 'checkbox'].includes(
                            ownerState['tek-label-for']
                        )
                    ) {
                        return {
                            marginLeft: '0',
                            marginRight: '16px',
                        }
                    }
                },
            },
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    color: COLOR_NAMES.secondaryGray,
                    margin: '8px 4px 8px 0',
                    padding: '0',
                    '&:hover': {
                        color: COLOR_NAMES.blue,
                    },
                    '& .MuiSvgIcon-root': {
                        width: '18px',
                        height: '18px',
                    },
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: COLOR_NAMES.secondaryGray,
                    margin: '8px 4px 8px 0',
                    padding: '0',
                    '&:hover': {
                        color: COLOR_NAMES.blue,
                        backgroundColor: 'transparent',
                    },
                    '& .MuiSvgIcon-root': {
                        width: '16px',
                        height: '16px',
                    },
                },
            },
        },

        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 'unset',
                    height: 'unset',
                    padding: '0',

                    '& .MuiSwitch-switchBase': {
                        padding: '4px',

                        // Checked.
                        '&.Mui-checked': {
                            transform: 'translateX(24px)',

                            '& .MuiSwitch-thumb': {
                                backgroundColor: COLOR_NAMES.white,
                            },
                            '& + .MuiSwitch-track': {
                                borderColor: 'transparent',
                                opacity: '1',
                            },
                        },

                        // Disabled.
                        '&.Mui-disabled': {
                            '& .MuiSwitch-thumb': {
                                backgroundColor: COLOR_NAMES.white,
                            },
                            '& + .MuiSwitch-track': {
                                backgroundColor: COLOR_NAMES.secondaryLightGray,
                                opacity: '1',
                                borderColor: 'transparent',
                            },
                        },
                    },

                    '& .MuiSwitch-thumb': {
                        height: '18px',
                        width: '18px',
                        backgroundColor: COLOR_NAMES.blue,
                    },

                    '& .MuiSwitch-track': {
                        borderRadius: '13px',
                        border: `1px solid ${COLOR_NAMES.secondaryGray}`,
                        backgroundColor: COLOR_NAMES.white,
                        width: '48px',
                        height: '24px',
                        opacity: '1',
                    },

                    // Small.
                    '&.MuiSwitch-sizeSmall': {
                        '& .MuiSwitch-switchBase': {
                            padding: '2px',
                            '&.Mui-checked': {
                                transform: 'translateX(12px)',
                            },
                        },
                        '& .MuiSwitch-thumb': {
                            height: '10px',
                            width: '10px',
                        },
                        '& .MuiSwitch-track': {
                            width: '24px',
                            height: '12px',
                        },
                    },
                },
            },
        },

        MuiSlider: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.variant === 'slider') {
                        return {
                            '& .MuiSlider-thumb': {
                                height: 20,
                                width: 20,
                                backgroundColor: COLOR_NAMES.secondaryLightBlue,
                            },
                            '& .MuiSlider-thumb.Mui-active': {
                                backgroundColor: COLOR_NAMES.secondaryDarkBlue,
                            },
                            '& .MuiSlider-track': {
                                border: 'none',
                                height: 16,
                                opacity: 0.6,
                                backgroundColor: COLOR_NAMES.secondaryLightBlue,
                            },
                            '& .MuiSlider-rail': {
                                border: 'none',
                                height: 16,
                                backgroundColor: '#FAFAFA',
                            },
                        }
                    }
                    if (ownerState.variant === 'slider-bar') {
                        return {
                            height: 2,
                            '& .MuiSlider-thumb': {
                                height: 32,
                                width: 32,
                                backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'><path d='M16.5 5.25L16.5 18M7.5 5.25L7.5 18M12 5.25L12 18' stroke='%23787A7D' stroke-linecap='round'/></svg>")`,
                                backgroundColor: COLOR_NAMES.white,
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                borderRadius: '4px',
                                border: `1px solid ${COLOR_NAMES.secondaryLightGray}`,
                            },
                            '& .MuiSlider-track': {
                                border: 'none',
                            },
                            '& .MuiSlider-rail': {
                                color: COLOR_NAMES.secondaryLightGray,
                                opacity: 1,
                            },
                            '& .MuiSlider-mark': {
                                borderRadius: 0,
                                backgroundColor: COLOR_NAMES.white,
                                width: 4,
                            },
                        }
                    }
                    return {}
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    minHeight: '40px',
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                clearIndicator: {
                    color: COLOR_NAMES.white,
                },
                listbox: {
                    fontSize: SSG_TYPOGRAPHY.body_1.fontSize,
                },
                noOptions: {
                    fontSize: SSG_TYPOGRAPHY.body_1.fontSize,
                    padding: '10px',
                },
                root: {
                    '.MuiAutocomplete-hasClearIcon': {
                        fontSize: SSG_TYPOGRAPHY.body_1.fontSize,
                    },
                    '& .MuiInputBase-sizeSmall': {
                        padding: 0,
                    },
                    '& .MuiInputBase-adornedEnd': {
                        paddingRight: '16px !important',
                        '&:hover svg': {
                            color: COLOR_NAMES.blue,
                        },
                        '&.Mui-focused svg': {
                            color: COLOR_NAMES.blue,
                        },
                        '& .MuiAutocomplete-clearIndicator': {
                            '& svg': {
                                fontSize: '16px',
                            },
                        },
                    },
                    '.MuiAutocomplete-paper': {
                        fontSize: SSG_TYPOGRAPHY.body_1.fontSize,
                    },
                    '& .MuiAutocomplete-tag': {
                        borderRadius: '4px',
                        backgroundColor: COLOR_NAMES.blue,
                        color: COLOR_NAMES.white,
                        fontSize: SSG_TYPOGRAPHY.body_1.fontSize,
                    },
                    '& .MuiChip-root .MuiChip-deleteIcon': {
                        color: `${COLOR_NAMES.white} !important`,
                        fontSize: SSG_TYPOGRAPHY.body_1.fontSize,
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    width: '100%',
                },
                input: ({ theme }) => ({
                    padding: theme.spacing(1, 1, 1, 0),
                    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                    transition: theme.transitions.create('width'),
                    width: '100%',
                    fontSize: '14px',
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }),
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        fontSize: SSG_TYPOGRAPHY.body_1.fontSize,
                        '&:hover fieldset': {
                            borderColor: COLOR_NAMES.blue,
                            border: `1px solid ${COLOR_NAMES.blue}`,
                        },
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${COLOR_NAMES.blue}`,
                            },
                        },
                    },
                },
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },

        MuiList: {
            styleOverrides: {
                root: {
                    borderRight: '1px solid #d0dae4',
                    paddingRight: '39px !important',
                    marignTop: '10px',
                },
            },
        },

        MuiBox: {
            variants: [
                {
                    props: { variant: 'modal-container' },
                    style: (props) => {
                        const { size } = props
                        const modalSizes = {
                            sm: '400px',
                            md: '580px',
                            lg: '865px',
                            xl: '1150px',
                            auto: 'auto',
                        }

                        return {
                            width: modalSizes[size] || '100%',
                            minWidth: '400px',
                            maxWidth: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: `${COLOR_NAMES.white}`,
                            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.22)',
                            borderRadius: '4px',
                        }
                    },
                },
                {
                    props: { variant: 'modal-header-container' },
                    style: {
                        padding: '32px 24px 24px 24px',
                        borderBottom: `1px solid ${COLOR_NAMES.secondaryLightGray}`,
                    },
                },
                {
                    props: { variant: 'modal-title-container' },
                    style: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    },
                },
                {
                    props: { variant: 'modal-body-container' },
                    style: {
                        overflowY: 'auto',
                        maxHeight: '60vh',
                    },
                },
            ],
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ ownerState }) => {
                    if (ownerState.isThemed) {
                        return {
                            background: COLOR_NAMES.black,
                            color: COLOR_NAMES.white,
                            fontSize: APP_TYPOGRAPHY.body_2.font_size,
                            fontWeight: 'normal',
                            lineHeight: '16px',
                            padding: '12px',
                            maxWidth: '480px',
                        }
                    }
                },
                arrow: ({ ownerState }) => {
                    if (ownerState.isThemed) {
                        return {
                            color: COLOR_NAMES.black,
                        }
                    }
                },
            },
        },
    },
})

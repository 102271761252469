import React from 'react'
import Grid from '../../../../../../base/Grid'
import { institutionIntermediaryPropType } from './InstitutionIntermediaryPropTypeTypes'
import { LinkWrapper, EntityTypeText, LinkStyled } from '../styles'

function InstitutionIntermediaryGridDisplay({ intermediaryProvider }) {
    if (!intermediaryProvider) {
        return null
    }

    const { id, name } = intermediaryProvider

    return (
        <Grid container>
            <LinkWrapper item container xs={12}>
                <EntityTypeText>Intermediary: </EntityTypeText>
                <LinkStyled
                    href={`/m/institution-profile-intermediary/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Go to Institution Intermediary Profile (ID ${id})`}
                >
                    {name}
                </LinkStyled>
            </LinkWrapper>
        </Grid>
    )
}

InstitutionIntermediaryGridDisplay.propTypes = {
    intermediaryProvider: institutionIntermediaryPropType,
}

InstitutionIntermediaryGridDisplay.defaultProps = { intermediaryProvider: null }

export default InstitutionIntermediaryGridDisplay

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch as MaterialSwitch } from '@mui/material'

const Switch = forwardRef((props, ref) => {
    const { checked, disabled, onChange, label, value, size, isThemed } = props

    if (!isThemed) {
        return <MaterialSwitch {...props} ref={ref} />
    }

    return (
        <FormControlLabel
            tek-label-for="switch"
            labelPlacement="start"
            ref={ref}
            value={value}
            label={label}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            control={<MaterialSwitch size={size} />}
        />
    )
})

Switch.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.node,
    value: PropTypes.string,
    size: PropTypes.oneOf(['medium', 'small']),
    isThemed: PropTypes.bool,
}

Switch.defaultProps = {
    checked: undefined,
    disabled: undefined,
    onChange: undefined,
    label: undefined,
    value: undefined,
    size: 'medium',
    isThemed: false,
}

export default Switch

import React, { forwardRef, useState, useEffect } from 'react'
import { Autocomplete as MaterialAutocomplete } from '@mui/material'
import { TextField } from '../../TextField'
import { getInputProps } from '../util'
import { useDebounce } from '../../../../helpers/hooks/debounce'

const AutocompleteApi = forwardRef((props, ref) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [mounted, setMounted] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [searchLoading, setSearchLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('Loading...')
    const debouncedValue = useDebounce(searchTerm, 500)

    const {
        minLength = 2,
        disabled = false,
        error,
        helperText,
        freeSolo = false,
        groupBy,
        multiple = false,
        noResultsText = 'No results found.',
        onChange,
        optionLabel = 'label',
        optionValue = 'id',
        placeholder = 'Search',
        onValueChange,
        apiCall,
        value,
        onResults = (response) => {
            return response.data
        },
        getOptionLabel = (option) => option[optionLabel] ?? option,
        isOptionEqualToValue = (option, value) =>
            value ? option[optionValue] === value[optionValue] : false,
    } = props

    const callApi = async () => {
        try {
            const data = onResults(
                await apiCall(encodeURIComponent(searchTerm))
            )

            if (!data.length) {
                setLoadingText(noResultsText)
            } else {
                setSearchResults(data)
                setSearchLoading(false)
            }
        } catch (error) {
            setLoadingText("Couldn't get any data from the given resource")
            setSearchLoading(false)
            return
        }
    }

    useEffect(() => {
        if (!searchTerm || searchTerm.length === 0) {
            setSearchResults([])
            setSearchLoading(false)
        }

        if (mounted) {
            // Don't search if debounce hasn't finished or if null or blank value
            if (
                debouncedValue !== searchTerm ||
                debouncedValue === '' ||
                debouncedValue === null
            ) {
                return
            }

            // Make an Api call when the search term is more than 2 characters
            if (searchTerm.length >= minLength) {
                setSearchLoading(true)
                setLoadingText('Loading...')
                setSearchResults([])
                callApi()
            }
        } else {
            setMounted(true)
        }
    }, [searchTerm, debouncedValue])

    return (
        <MaterialAutocomplete
            disabled={disabled}
            freeSolo={freeSolo}
            fullWidth
            groupBy={groupBy}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            loading={searchLoading}
            loadingText={loadingText}
            multiple={!!multiple}
            onChange={(_, newValue) => {
                setSearchTerm(newValue)
                onChange(newValue)
                if (onValueChange) {
                    onValueChange(newValue)
                }
            }}
            onInputChange={(event, term) => {
                if (event) {
                    setSearchTerm(term)
                    if (!multiple) {
                        onChange(term)
                    }
                }
            }}
            options={searchResults ?? []}
            size={'small'}
            componentsProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'flip',
                            enabled: false,
                        },
                        {
                            name: 'preventOverflow',
                            enabled: false,
                        },
                    ],
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...getInputProps(params, error)}
                    ref={ref}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                />
            )}
            value={value}
        />
    )
})

export default AutocompleteApi

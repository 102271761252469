import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'

const Loader = ({ className, display, size, align, style }) => {
    return (
        <div
            className={className}
            style={{
                color: 'rgb(0, 120, 153, 0.6)',
                margin: '10px',
                padding: '4px',
                textAlign: align,
                alignContent: align,
                justifyContent: align,
                overflow: 'hidden',
                display,
                ...style,
            }}
        >
            <CircularProgress color="primary" size={size} thickness={2} />
        </div>
    )
}

Loader.propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center']),
    className: PropTypes.string,
    display: PropTypes.oneOf(['inline', 'inline-block', 'inherit', 'flex']),
    size: PropTypes.number,
    style: PropTypes.object,
}

Loader.defaultProps = {
    className: 'w-100',
    align: 'center',
    display: 'inherit',
    size: 100,
    style: {},
}

export default Loader

export const SERVER_SIDE_AND_ROW_DATA_UNDEFINED_ERROR = `Both rowData and serverSideApi are undefined. This component requires one of them to be defined.
        
            When passing rowData, it is an array of objects. And pagination is done client side.
            
            When passing serverSideApi, pagination is done server side. serverSideApi is a function that takes in the following parameters:
                page: Page requested by the grid,
                itemsPerPage: Number of items per page requested by the grid,
                filterModel: Filter model requested by the grid (see https://www.ag-grid.com/react-data-grid/server-side-model-filtering/),
                sortModel: Sort model requested by the grid (see https://www.ag-grid.com/react-data-grid/server-side-model-sorting/),
                successCallback: Callback function to be called when the data is successfully retrieved from the server. It takes in the following parameters:
                    data: Array of objects matching the ColumnDefs,
                    total: Total number of rows matching the filterModel (Or total number of rows in the database if no filterModel is provided),
                failCallback: Callback function to be called when the data is not successfully retrieved from the server. It takes in no parameters.
        `

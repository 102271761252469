import { FilterTypeValue, FilterInterface, FilterType } from 'types';
import { filterTypes } from 'data';
import { nonenumerable } from 'helpers';

export class Filter implements FilterInterface {
    /**
     * The filter type
     */
    type: FilterType;

    /**
     * The filter value
     */
    value: string;

    constructor(type: FilterTypeValue = 'equal', value: string = '') {
        if (Object.hasOwnProperty.call(filterTypes, type)) {
            this.type = filterTypes[type];
        } else {
            this.type = filterTypes.equal;
        }
        this.value = value;

        nonenumerable(this, 'updateType');
        nonenumerable(this, 'updateValue');
    }

    /**
     * Updates the type of filter
     * @param newType
     */
    updateType = (newType: FilterTypeValue): Filter =>
        new Filter(newType, this.value);

    /**
     * Updates the value of the filter
     * @param newValue
     */
    updateValue = (newValue: string): Filter =>
        new Filter(this.type.value, newValue);
}

const AgGridButtonConstants = {
    TYPE_SELECT_COLUMNS: 'selectColumns',
    TYPE_CLEAR_ALL: 'clearAll',
    TYPE_CLEAR_FILTERS: 'clearFilters',
    TYPE_CLEAR_SORT: 'clearSort',
    TYPE_EXPORT_XLSX: 'exportXlsx',
    TYPE_SELECT_FILTERS: 'selectFilters',
}

export default AgGridButtonConstants

import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridCustomTreeListFilter extends AgGridBaseFilter {
    getFilter() {
        return AgGridFilterConstants.FILTER_AG_SET
    }

    isCustomFilter() {
        return true
    }

    correctOptionsTypePassed({ isPromise, isArray, isFunction }) {
        const correctOptionsTypePassed = !isPromise && (isArray || isFunction)

        if (!correctOptionsTypePassed) {
            console.error(
                'Incorrect options passed to AgGridCustomTreeListFilter, options must be an array or a function'
            )
        }

        return correctOptionsTypePassed
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_AG_SET
    }

    getCustomFilterParams() {
        if (
            !this.filterParamsHasTreeStructure &&
            !this.filterParamsHasTreeListPathGetter()
        ) {
            throw new Error(
                `To use the filter ${AgGridFilterConstants.FILTER_TREE_LIST} you must provide either a treeStructure or a treeListPathGetter`
            )
        }

        return {
            values: this.getOptions(),
            keyCreator: this.getKeyCreator(),
            treeListFormatter: this.getTreeListFormatter(),
            treeListPathGetter: this.getTreeListPathGetter(),
            treeList: true,
            treeData: true,
        }
    }

    filterParamsHasTreeStructure() {
        return (
            this.filterParams.treeStructure !== undefined &&
            this.filterParams.treeStructure !== null
        )
    }

    filterParamsHasTreeListPathGetter() {
        return (
            this.filterParams.treeListPathGetter !== undefined &&
            this.filterParams.treeListPathGetter !== null
        )
    }

    getOptions() {
        return typeof this.filterParams.options === 'function'
            ? this.filterParams.options()
            : this.filterParams.options
    }

    getKeyCreator() {
        return this.filterParams.keyCreator ?? this.keyFormatter
    }

    getTreeListFormatter() {
        const treeListFormatter = this.filterParams.treeListFormatter
            ? this.filterParams.treeListFormatter
            : this.filterParams.labelFormatter

        return treeListFormatter ?? this.labelFormatter
    }

    getTreeListPathGetter() {
        return this.filterParamsHasTreeListPathGetter()
            ? this.filterParams.treeListPathGetter
            : this.treeListPathGetter(this.filterParams.treeStructure)
    }

    treeListPathGetter(treeStructure) {
        return (value) => {
            const pathKeys = treeStructure.map((key) => {
                if (typeof key === 'function') {
                    return key(value)
                }

                return value[key]
            })

            return pathKeys
        }
    }
}

export default AgGridCustomTreeListFilter

/**
 * AgGridCustomTreeListFilter Params
 * To use pass AgGridFilterConstants.FILTER_TREE_LIST to the filter prop of the column definition
 *
 * Filter Params:
 * options: Required array or function to get the options for the filter.
 *
 * treeStructure: Optional array or function to get the tree structure for the filter. The root level must be the first
 * element of the array. Either a function or a string can be passed, if a string is passed, we will use the value of
 * the property with that name as the key for the tree structure. If a function is passed, it will inject the object
 * in question, and the function must return the desired value.
 *
 * If the treeStructure is not provided, we will try to use the
 * treeListPathGetter to get the tree structure.
 *
 * treeListPathGetter: Optional function to get the tree structure for the filter. If not provided, we will try to use the
 * treeStructure to get the tree structure.
 * See https://www.ag-grid.com/react-data-grid/filter-set-tree-list/ for more information.
 *
 * keyCreator: Optional function to format the value of the option. If not provided, we will search for the
 * key property in the option, if not found, we will use the option itself as the value.
 *
 * treeListFormatter: Optional function to format the label of the option. If not provided, we will search for the
 * label property in the option, if not found, we will use the option itself as the label.
 *
 */

import ResearchLogo from '../../../../../assets/images/SPI_Research.svg'
import PacingLogo from '../../../../../assets/images/SPI_Pacing.svg'
import ReportingLogo from '../../../../../assets/images/SPI_Reporting.svg'
import BenchmarkingLogo from '../../../../../assets/images/spi_benchmarking.svg'
import { Tabs } from '../../../../base'

// WIP this values will change overtime
export default {
    default: {
        appLogo: ResearchLogo,
        tabs: {
            TabsComponent: Tabs,
            content: [
                { title: 'Research', href: '/research' },
                { title: 'Screening', href: '/research' },
                { title: 'Tools', href: '/research' },
                { title: 'Deals', href: '/research' },
                { title: 'Management', href: '/research' },
            ],
        },
        searchInput: {
            placeholder: 'Search Profiles, GPs, Funds and more...',
            // filter options
            filters: {
                // WIP this should come from the API
                filterOptions: [
                    { label: 'All', value: 'all' },
                    { label: 'Company', value: 'company' },
                    { label: 'Clients', value: 'clients' },
                ],
                // FiltersComponent component that holds the filter options
                // FilterComponent component that render a option,
                // onInputChange: (value) => search(value), here can be the request to the search
            },
        },
    },
    spi_research: {
        appLogo: ResearchLogo,
        tabs: {
            TabsComponent: (props) => (
                <Tabs
                    {...props}
                    tabOptions={{ sx: { height: '56px' } }}
                    tabPanelStyle={{ sx: { display: 'none' } }}
                />
            ),
            content: [
                { title: 'Research', href: '/research' },
                { title: 'Screening', href: '/research' },
                { title: 'Tools', href: '/research' },
                { title: 'Deals', href: '/research' },
                { title: 'Management', href: '/research' },
            ],
        },
        searchInput: {
            placeholder: 'Search Profiles, GPs, Funds and more...',
            filters: {
                filterOptions: [
                    { label: 'All', value: 'all' },
                    { label: 'Company', value: 'company' },
                    { label: 'Clients', value: 'clients' },
                    { label: 'Co-Investment', value: 'coinvs' },
                    { label: 'Fund', value: 'fund' },
                    { label: 'GP', value: 'gp' },
                    { label: 'Institutions', value: 'institutions' },
                    { label: 'Third-Party Providers', value: 'third' },
                    { label: 'Mandates', value: 'mandates' },
                    { label: 'Person', value: 'person' },
                    { label: 'Secondary', value: 'secondary' },
                ],
            },
        },
    },
    spi_pacing: {
        appLogo: PacingLogo,
        tabs: {
            content: [
                { title: 'Existing Commitments', href: '/research' },
                { title: 'Target Allocation', href: '/research' },
                { title: 'Underwriting', href: '/research' },
                { title: 'Pacing Analysis', href: '/research' },
                { title: 'Manage Pacings', href: '/research' },
                { title: 'Manage Users', href: '/research' },
                { title: 'Training', href: '/research' },
            ],
        },
        searchInput: {
            show: false,
            filters: {},
        },
    },
    spi_reporting: {
        appLogo: ReportingLogo,
        tabs: {},
        searchInput: {
            show: false,
            filters: {},
        },
    },
    spi_benchmarking: {
        appLogo: BenchmarkingLogo,
    },
}

export const createButtonVariant = (
    bgColor,
    color,
    borderColor,
    hoverColor,
    fontSize,
    fontFamily
) => {
    return {
        color,
        fontFamily,
        fontSize,
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: hoverColor,
        },
    }
}

import React from 'react'
import { Grid, Link } from '../../../../../base'
import { useFunction, useRedirect } from '../../projects'

const HeaderLogo = (props) => {
    const { href, onClick, title, url, ...rest } = props
    const redirect = useRedirect()
    const { execute } = useFunction(onClick)
    const handeClick = (event) => {
        if (onClick) {
            return execute(event, props)
        }
        return redirect(href)
    }
    return (
        <Grid item sx={{ maxHeight: '56px' }} {...rest}>
            <Link
                component="button"
                onClick={handeClick}
                title={title}
                display="flex"
            >
                <img src={url} height="28px" alt={title} />
            </Link>
        </Grid>
    )
}

export default HeaderLogo

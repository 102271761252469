import React, { useState, useEffect } from 'react'
import { Grid, Tabs as TechintTabs } from '../../../../../base'
import { useFunction, useRedirect } from '../../projects'

const Tabs = ({ content = [], selected, ...props }) => {
    const redirect = useRedirect()
    if (content.length <= 0) {
        // if there is no content
        return null
    }

    const tabObject = content.map(({ href, onClick, ...tab }) => ({
        tabLabel: tab.title,
        tabPanelContent: null,
        disabled: tab.divider,
        sx: tab.divider && { flex: 1, maxWidth: 'none' },
        ...tab,
    }))

    const onChange = (_event, newValue) => {
        _event
        _event.preventDefault()
        const selectTab = content[newValue]
        if (selectTab.onClick) {
            const { execute } = useFunction(selectTab.onClick)
            execute(_event, selectTab)
        } else if (selectTab.href) {
            redirect(selectTab.href)
        }
    }

    return (
        <Grid item xs={true} sx={{ maxHeight: '56px' }} {...props}>
            <TechintTabs
                tabObject={tabObject}
                handleInitialChange={onChange}
                initialValue={selected}
                tabPanelOptions={{ style: { display: 'none' } }}
            />
        </Grid>
    )
}

export default Tabs

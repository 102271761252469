import React from 'react'
import { MenuItem as MaterialMenuItem } from '@mui/material'

function MenuItem(props) {
    return (
        <MaterialMenuItem {...props.menuItemOptions}>
            {' '}
            {props.children}
        </MaterialMenuItem>
    )
}

export default MenuItem

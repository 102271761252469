import React from 'react'
import { FontAwesomeIcon, fas } from '../../../fontAwesome'
import Grid from '../../../base/Grid'
import './SelectedFile.scss'

const SelectedFile = (props) => {
    const { name, onDelete } = props
    return (
        <Grid container className={'selected-file-wrapper'}>
            <Grid item xs={7}>
                <span className={'selected-file-name'}>{name}</span>
            </Grid>
            <Grid item xs={1} className={'selected-file-trash'}>
                <FontAwesomeIcon
                    icon={fas.faTrash}
                    size={'1x'}
                    onClick={onDelete}
                />
            </Grid>
        </Grid>
    )
}

export default SelectedFile

import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material'
import styled from 'styled-components'
import { COLOR_NAMES, COLOR_STONE } from '../../themes'

export const DiaglogPaperStyle = {
    padding: '24px',
}

export const DiaglogContentStyled = styled(DialogContent)`
    padding: 0;
`

export const DialogActionsStyled = styled(DialogActions)`
    padding: 0;
`

export const DialogContentTextStyled = styled(DialogContentText)`
    font-size: 16px;
    color: ${COLOR_NAMES.darkGray};
    padding: 0;
    margin-bottom: 12px;
`

export const DialogTitleStyled = styled(DialogTitle)`
    padding: 0;
    font-size: 16px;
    margin-bottom: 1rem;
`

import PropTypes from 'prop-types'

// Institution Intermediary Provider
export const institutionIntermediaryPropType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    second_risk_profile_id: PropTypes.number,
    intermediary_rank_id: PropTypes.number,
    coverage_team_id: PropTypes.number,
    region_id: PropTypes.number,
    institution_parents_id: PropTypes.number,
    website: PropTypes.string,
    description: PropTypes.string,
    created_by: PropTypes.number,
    created_date_gmt: PropTypes.string,
    modified_by: PropTypes.number,
    modified_date_gmt: PropTypes.string,
})

import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridDefaultDateFilter extends AgGridBaseFilter {
    getFilter() {
        return AgGridFilterConstants.FILTER_AG_DATE
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_AG_DATE
    }
}

export default AgGridDefaultDateFilter

/**
 * AgGridDefaultDateFilter Params
 * To use pass AgGridFilterConstants.FILTER_AG_DATE to the filter prop of the column definition
 *
 * This is AgGrids default date filter.
 * https://www.ag-grid.com/react-data-grid/filter-date/
 */

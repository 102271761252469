import PropTypes from 'prop-types'

// Funds
export const fundPropType = PropTypes.shape({
    FundID: PropTypes.number,
    GeneralPartnerID: PropTypes.number,
    Fund_Name: PropTypes.string,
    Fund_Name_Chinese: PropTypes.string,
    Status: PropTypes.string,
    main_fund_id: PropTypes.number,
    closed_ended: PropTypes.bool,
    asset_class_id: PropTypes.number,
    Vintage: PropTypes.number,
    Fund_Size_Local: PropTypes.number,
    target_ic_date: PropTypes.string,
    ic_vote_not_required: PropTypes.number,
    Strategy: PropTypes.string,
    IndustryID: PropTypes.number,
    Currency: PropTypes.string,
    Hard_Cap: PropTypes.number,
    Target_First_Close_Date: PropTypes.string,
    Target_First_Close: PropTypes.number,
    Target_Final_Close_Date: PropTypes.string,
    Target_Final_Close: PropTypes.number,
    Report_Date: PropTypes.string,
    Report_Source: PropTypes.string,
    Materials_Received: PropTypes.string,
    PPM_Date_Received: PropTypes.string,
    Source: PropTypes.string,
    Target_Investment_Range_Low: PropTypes.number,
    Target_Investment_Range_High: PropTypes.number,
    Target_Company_Value_Range_Low: PropTypes.number,
    Target_Company_Value_Range_High: PropTypes.number,
    target_gross_ret_pct_min: PropTypes.number,
    target_gross_ret_pct_max: PropTypes.number,
    target_net_ret_pct_min: PropTypes.number,
    target_net_ret_pct_max: PropTypes.number,
    target_re_leverage_pct_min: PropTypes.number,
    target_re_leverage_pct_max: PropTypes.number,
    cash_yld_pct_min: PropTypes.number,
    cash_yld_pct_max: PropTypes.number,
    Exchange_Rate: PropTypes.number,
    Exchange_Rate_Date: PropTypes.string,
    Fund_Size_Date: PropTypes.string,
    in_pipeline: PropTypes.number,
    fund_fam_id: PropTypes.number,
    seq_id: PropTypes.number,
    sub_seq_id: PropTypes.string,
    micro_fam_id: PropTypes.number,
    primary_market_map_id: PropTypes.number,
    primed_fund_summary_by: PropTypes.number,
    primed_fund_summary_date_gmt: PropTypes.string,
    investment_review_complete_by: PropTypes.number,
    investment_review_date_gmt: PropTypes.string,
    fund_summary_date: PropTypes.string,
    chinese_investors: PropTypes.bool,
    odd_materials_link: PropTypes.string,
    legal_materials_link: PropTypes.string,
    Creation_Date: PropTypes.string,
    modified_date_gmt: PropTypes.string,
    preq_fund_id: PropTypes.number,
    aida_fund_id: PropTypes.number,
    impact: PropTypes.number,
    thematic: PropTypes.number,
    diverse_manager: PropTypes.number,
    esg_policy: PropTypes.bool,
    un_pri_signatory: PropTypes.bool,
    sequence: PropTypes.number,
    fund_type_id: PropTypes.number,
    clients_funds_held_by_ssg: PropTypes.string,
    clients_funds_not_held_by_ssg: PropTypes.string,
    processing_stage_id: PropTypes.number,
    pipeline_comments: PropTypes.string,
    materials_confirmed_at: PropTypes.number,
    materials_confirmed_by: PropTypes.number,
})

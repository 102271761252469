import React, { useCallback } from 'react';
import { Select } from '@material-ui/core';
import { InputTypeProps } from './types';

type OptionsType = Array<{
    value: any;
    text: React.ReactNode;
}>;

interface SelectTypeProps extends InputTypeProps {
    options?: OptionsType;
}

const SelectType = ({ value, onChange, options = [] }: SelectTypeProps) => {
    const handleChange = useCallback(({ target: { value: newValue } }) => {
        onChange(newValue);
    }, []);

    return (
        <Select native value={value} onChange={handleChange} fullWidth>
            {options.map((option) => (
                <option value={option.value} key={option.value}>
                    {option.text}
                </option>
            ))}
        </Select>
    );
};

export default SelectType;

import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '../TabPanel/TabPanel'
import Box from '@mui/material/Box'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const BasicTabs = (props) => {
    const {
        tabObject,
        tabsOptions,
        tabOptions,
        tabPanelOptions,
        handleInitialChange,
        initialValue,
        tabPanelStyle,
        disabled = false,
        variant = 'text',
    } = props

    const [value, setValue] = React.useState(0)

    const handleChange = (_event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                {...tabsOptions}
                value={initialValue || value}
                onChange={handleInitialChange || handleChange}
                aria-label="basic tabs example"
                variant={variant}
            >
                {tabObject.map(({ tabLabel, ...item }, index) => {
                    return (
                        <Tab
                            {...tabOptions}
                            key={index}
                            label={tabLabel}
                            {...a11yProps({ index })}
                            disabled={disabled}
                            variant={variant}
                            {...item}
                        />
                    )
                })}
            </Tabs>
            {tabObject.map((item, index) => {
                return (
                    <TabPanel
                        tabPanelStyle={tabPanelStyle}
                        {...tabPanelOptions}
                        key={index}
                        value={initialValue || value}
                        index={index}
                    >
                        {item.tabPanelContent}
                    </TabPanel>
                )
            })}
        </Box>
    )
}
export default BasicTabs

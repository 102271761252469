const removeHtml = (text) => {
    //This will match all html tags in a string
    //< matches a < character
    //[^>] matches any character that is not a > character
    //* matches the previous item (i.e. [^>]) 0 or more times
    //> matches a > character
    //? makes the regex non-greedy (i.e. it will stop at the first > character it finds)
    //g means global (i.e. it will match all occurrences of the regex)
    //m means multiline (i.e. it will match across multiple lines)

    return text.replace(/<[^>]*>?/gm, '')
}

export default removeHtml

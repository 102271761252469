import React from 'react'
import { fas, FontAwesomeIcon } from '../../../fontAwesome'
import { COLOR_NAMES } from '../../../themes'

export const getInputProps = (params, error, icon) => {
    const inputProps = {
        ...params,
    }

    inputProps.InputProps = {
        ...params.InputProps,
        endAdornment: (
            <div
                style={{
                    position: 'absolute',
                    right: '16px',
                }}
            >
                <FontAwesomeIcon
                    icon={fas[icon ?? 'faSearch']}
                    color={!!error ? COLOR_NAMES.persianRed : ''}
                />
            </div>
        ),
    }

    return inputProps
}

import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

function AppFooter({ children }) {
    return (
        <footer>
            <Box
                sx={{
                    minHeight: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {children}
            </Box>
        </footer>
    )
}

AppFooter.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default AppFooter

import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Pagination from '@material-ui/lab/Pagination';
import { SetPageFunction, SetPerPageFunction, Size } from 'types';
import { useStyles } from './styles';

interface Props {
    disablePerPage?: boolean;
    end?: number;
    loading?: boolean;
    noResultsText?: string;
    page?: number;
    pages?: number;
    perPage?: number;
    perPageOptions?: number[];
    setPage: SetPageFunction;
    setPerPage: SetPerPageFunction;
    size?: Size;
    start?: number;
    total?: number;
}

export const PaginationFooter = ({
    disablePerPage = false,
    end = 0,
    loading = false,
    noResultsText = 'No Results',
    page = 1,
    pages = 0,
    perPage = 10,
    perPageOptions = [5, 10, 25, 100],
    setPage = (_) => _,
    setPerPage = (_) => _,
    size = 'small',
    start = 0,
    total = 0,
}: Props) => {
    const classes = useStyles();

    if (total === 0 || loading) {
        return (
            <div className={classes.noResults}>
                {loading ? (
                    <Fragment>
                        Loading{' '}
                        <CircularProgress
                            color='primary'
                            size='12px'
                            thickness={4}
                        />
                    </Fragment>
                ) : (
                    noResultsText
                )}
            </div>
        );
    }

    const handlePageChange = (_, value) => {
        setPage(value);
    };

    const handlePerPageChange = ({ target: { value } }) => setPerPage(value);

    return (
        <Box
            display='flex'
            alignItems='center'
            flexDirection='row'
            flexWrap='nowrap'
        >
            <Box>
                <Pagination
                    count={pages}
                    page={page}
                    onChange={handlePageChange}
                    size={size}
                    disabled={loading}
                />
            </Box>
            <Box flexGrow={1}>
                {start} - {end} (of {total})
            </Box>
            {!disablePerPage && (
                <Box>
                    Per Page:{' '}
                    <Select
                        value={perPage}
                        onChange={handlePerPageChange}
                        disabled={loading}
                        data-testid='pagination-footer-select'
                    >
                        {perPageOptions.map((option) => (
                            <MenuItem value={option} key={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            )}
        </Box>
    );
};

import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon, fas } from '../../fontAwesome'
import './swal.css'
import { getThemeElements, renderStrongText } from './themes'
import { Typography } from '../../base'

const RNSwal = withReactContent(Swal)

const Toast = RNSwal.mixin({
    toast: true,
    position: 'top-end',
    showCloseButton: true,
    showConfirmButton: false,
    closeButtonHtml: <FontAwesomeIcon icon={fas.faTimes} />,
})

export const showToast = (text = '', theme = 'info', displayTime = 5000) => {
    const { background, iconHtml, color } = getThemeElements(theme)

    return Toast.fire({
        iconHtml,
        background,
        customClass: {
            htmlContainer: 'swal2-container-margin',
            icon: 'swal2-icon-margin',
        },
        width: '422px',
        padding: '6px 1rem',
        color,
        html: (
            <Typography
                sx={{
                    fontSize: '14px',
                }}
            >
                {renderStrongText(theme)}
                {text}
            </Typography>
        ),
        closeButtonHtml: (
            <FontAwesomeIcon
                style={{ color, fontSize: '18px' }}
                icon={fas.faClose}
            />
        ),
        timer: displayTime,
    })
}

import * as filters from './filterTypes';

const {
    equal,
    notEqual,
    greater,
    less,
    greaterOrEqual,
    lessOrEqual,
    contains,
    doesNotContain,
    beginsWith,
    doesNotBeginWith,
    endsWith,
    doesNotEndWith,
    isTrue,
    isFalse,
    isNull,
    isNotNull,
    isBlank,
    isNotBlank,
    before,
    after,
    onOrBefore,
    onOrAfter,
    on,
} = filters;

/**
 * All filters available for each filter type
 */
export const filtersByType = {
    text: {
        equal,
        notEqual,
        contains,
        doesNotContain,
        isBlank,
        isNotBlank,
        beginsWith,
        endsWith,
        doesNotBeginWith,
        doesNotEndWith,
    },
    date: {
        equal,
        notEqual,
        before,
        after,
        onOrBefore,
        onOrAfter,
        isBlank,
        isNotBlank,
        on,
    },
    number: {
        equal,
        notEqual,
        greater,
        less,
        greaterOrEqual,
        lessOrEqual,
        contains,
        doesNotContain,
        beginsWith,
        endsWith,
        isNull,
        isNotNull,
    },
    boolean: {
        isTrue,
        isFalse,
        isNull,
        isNotNull,
    },
};

export const defaultFilters = {
    boolean: 'isTrue',
};

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Button } from '../../base'
import {
    CommentBoxButtonWrapper,
    CommentBoxWrapper,
    StyledAvatar,
} from './styles'
import { CommentItemPropType } from './types'
import { useComment } from './data-provider'

const CommentsForm = () => {
    const {
        loading,
        parentComment,
        setParentComment,
        comment,
        setComment,
        submitted,
        setSubmitted,
        userInitials,
        handleSaveComment,
    } = useComment()

    const disableButtons = loading
    const showError = !comment && submitted
    const isUpdate = !!parentComment?.id

    const [isInputFocused, setIsInputFocused] = useState(false)

    useEffect(() => {
        setComment(parentComment?.comment || '')
        setSubmitted(false)
    }, [parentComment])

    const handleSave = () => {
        handleSaveComment()
    }

    const handleCancel = () => {
        setSubmitted(false)
        setComment('')
        setParentComment(null)
    }

    const shouldRenderActionButtons = () => {
        return isInputFocused || comment
    }

    return (
        <div>
            <CommentBoxWrapper>
                <StyledAvatar>{userInitials}</StyledAvatar>
                <TextField
                    id="institution-profile-comment"
                    label={(isUpdate ? 'Update' : 'Add') + ' comment...'}
                    variant="outlined"
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    required
                    error={showError}
                    helperText={showError ? 'Required' : ''}
                    inputProps={{
                        style: {
                            height: 52,
                            width: 322,
                            padding: '2px 2px 2px 20px',
                            fontSize: '12px',
                        },
                    }}
                    InputLabelProps={{
                        required: false,
                        style: { fontSize: '12px' },
                    }}
                />
            </CommentBoxWrapper>
            {shouldRenderActionButtons() && (
                <CommentBoxButtonWrapper>
                    <Button
                        disabled={disableButtons}
                        onClick={handleCancel}
                        theme="secondary"
                        size="small"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={disableButtons}
                        onClick={handleSave}
                        theme="primary"
                        size="small"
                    >
                        {isUpdate ? 'Update' : 'Save'}
                    </Button>
                </CommentBoxButtonWrapper>
            )}
        </div>
    )
}

CommentsForm.propTypes = {
    loading: PropTypes.bool,
    comment: CommentItemPropType,
    setComment: PropTypes.func,
    parentComment: CommentItemPropType,
    setParentComment: PropTypes.func,
    submitted: PropTypes.bool,
    setSubmitted: PropTypes.func,
    userInitials: PropTypes.string,
    handleSaveComment: PropTypes.func,
}

CommentsForm.defaultProps = {
    loading: false,
    comment: '',
    setComment: () => {},
    parentComment: null,
    setParentComment: () => {},
    submitted: false,
    setSubmitted: () => {},
    userInitials: '',
}

export default CommentsForm

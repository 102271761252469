import React from 'react'
import styled from 'styled-components'
import { Box, Toolbar, Typography, Stack } from '../../../../base'

const HeaderTwoStyled = styled(Typography)`
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-size: 24px !important;
    font-weight: 600;
    color: #333333;
    line-height: 32px;
`

const TableTitleSubtitle = ({ title, subtitle }) => {
    if (!title) return null

    return (
        <Box sx={{ flexGrow: 1, marginTop: '0px', marginBottom: '5px' }}>
            <Toolbar disableGutters>
                <Stack sx={{ flexGrow: 1 }}>
                    <HeaderTwoStyled
                        variant="h2"
                        sx={{ paddingTop: '0.25rem' }}
                    >
                        {title}
                    </HeaderTwoStyled>
                    {subtitle && (
                        <Typography sx={{ fontSize: '12px' }}>
                            {subtitle}
                        </Typography>
                    )}
                </Stack>
            </Toolbar>
        </Box>
    )
}

export default TableTitleSubtitle

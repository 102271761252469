import React, { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormWrapper } from './style'

const Form = forwardRef((props, ref) => {
    const { handleSubmit, onSubmit } = useFormContext()
    const { children } = props

    return (
        <FormWrapper
            ref={ref}
            onSubmit={handleSubmit((data) => onSubmit(data))}
        >
            {children}
        </FormWrapper>
    )
})

export default Form

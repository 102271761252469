import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'

import { Search, SearchIconWrapper } from './style'
import { FontAwesomeIcon, fas } from '../../../../../../fontAwesome'
import { IconButton, InputBase } from '../../../../../../base'

const SearchComponent = ({
    placeholder,
    searchTerm,
    onChangeSearchTerm,
    toggleSearchPage,
}) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    if (isSmall) {
        return (
            <IconButton onClick={toggleSearchPage}>
                <FontAwesomeIcon
                    style={{ fontSize: '16px' }}
                    icon={fas.faSearch}
                />
            </IconButton>
        )
    }

    return (
        <Search
            sx={{
                border: 1,
                fill: '#444',
                color: '#444',
            }}
        >
            <SearchIconWrapper>
                <FontAwesomeIcon icon={fas.faSearch} />
            </SearchIconWrapper>
            <InputBase
                placeholder={placeholder}
                aria-label="search"
                value={searchTerm}
                onChange={onChangeSearchTerm}
                aria-describedby="searchModal"
            />
        </Search>
    )
}

export default SearchComponent

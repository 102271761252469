import React, { forwardRef, useContext } from 'react'
import AppDrawer from '../AppDrawer'
import HeaderNavTabs from '../HeaderNavTabs'
import { Grid, IconButton, Divider, Avatar, Link } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { mockNavLinks, mockAppLinks, mockHelpLinks } from '../AppHeader-Data'
import UserProfile from '../UserProfile'
import SearchPageView from '../SeachContent/SearchPageView'
import QuickAddMenu from './../MenuButtonDropdown/QuickAddMenu'
import HelpMenu from './../MenuButtonDropdown/HelpMenu'
import { useAppHeader, useUser } from '../data-provider'
import { Search, SearchIconWrapper, StyledInputBase } from './style'

const LargeScreenView = forwardRef((props, ref) => {
    const { companyLogo } = useAppHeader()
    const {
        addMenuObject,
        searchTerm,
        setSearchTerm,
        searchResults,
        resultsFilter,
        setResultsFilter,
        updateResultSwitch,
        setUpdateResultSwitch,
        handleCloseSearch,
        open,
        openSearch,
        setOpenSearch,
        openProfile,
        anchorEl,
        anchorProfileEl,
        handleClick,
        id,
        handleClose,
        navLinks,
        appLinks,
        helpLinks,
        handleProfileMenuOpen,
        user,
        initials,
        loading,
        isComponentVisible,
        setIsComponentVisible,
        handleHelpMenuOpen,
        isHelpOpen,
        anchorHelpEl,
    } = props

    const { isExternalUser } = useUser()

    const onInputChange = (event) => {
        if (!openSearch) setOpenSearch(true)
        if (!isComponentVisible) {
            setIsComponentVisible(true)
        }
        setSearchTerm(event.target.value)
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={7} container alignItems="center">
                <Grid item xs={1}>
                    <AppDrawer
                        navLinks={navLinks || mockNavLinks}
                        appLinks={appLinks || mockAppLinks}
                        helpLinks={helpLinks || mockHelpLinks}
                        handleCloseSearch={handleCloseSearch}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Link href={'/'} title="Home">
                        <img
                            src={companyLogo}
                            style={{ width: '141px', height: '50px' }}
                            alt="SPI Logo"
                        />
                    </Link>
                </Grid>
                <Grid item xs={9}>
                    <HeaderNavTabs
                        props={props}
                        navLinks={navLinks || mockNavLinks}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                xs={5}
                container
                alignItems="center"
                justifyContent="flex-end"
            >
                <Grid item xs={8} ref={ref}>
                    <Search
                        sx={{
                            border: 1,
                            fill: '#444',
                            color: '#444',
                        }}
                    >
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search Profiles, GPs, Funds and more..."
                            aria-label="search"
                            value={searchTerm || ''}
                            onChange={onInputChange}
                            aria-describedby="searchModal"
                        />
                    </Search>
                    {openSearch && (
                        <SearchPageView
                            setOpenSearch={setOpenSearch}
                            handleCloseSearch={handleCloseSearch}
                            searchResults={searchResults}
                            resultsFilter={resultsFilter}
                            setResultsFilter={setResultsFilter}
                            updateResultSwitch={updateResultSwitch}
                            setUpdateResultSwitch={setUpdateResultSwitch}
                            loading={loading}
                            screenSize={'large'}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={4}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        item
                        xs={4}
                        onMouseLeave={() => {
                            handleClick(false)
                        }}
                    >
                        {!isExternalUser && (
                            <QuickAddMenu
                                id={id}
                                addMenuObject={addMenuObject}
                                open={open}
                                anchorEl={anchorEl}
                                handleClick={handleClick}
                            ></QuickAddMenu>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        onMouseLeave={() => {
                            handleHelpMenuOpen(false)
                        }}
                    >
                        <HelpMenu
                            id={id}
                            helpLinks={helpLinks || mockHelpLinks}
                            open={isHelpOpen}
                            anchorEl={anchorHelpEl}
                            handleOpen={handleHelpMenuOpen}
                        ></HelpMenu>
                    </Grid>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Grid
                        item
                        xs={3}
                        sx={{
                            mr: 0.25,
                            direction: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            sx={{
                                color: '#444',
                                width: 48,
                                height: 48,
                                marginRight: '0',
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: '14px',
                                }}
                            >
                                {initials}
                            </Avatar>
                        </IconButton>
                        <UserProfile
                            id={id}
                            anchorProfileEl={anchorProfileEl}
                            openProfile={openProfile}
                            handleClose={handleClose}
                            user={user}
                            initials={initials}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})

LargeScreenView.propTypes = {}

export default LargeScreenView

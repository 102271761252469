/* eslint-disable react/jsx-props-no-spreading, no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    addFilterActions: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    filterPopoverActions: {
        display: 'grid',
        gridColumnGap: theme.spacing(1),
        width: '100%',
        gridTemplateColumns: '1fr 1fr',
        paddingBottom: theme.spacing(1),
    },
    filterPopoverContent: {
        padding: theme.spacing(1),
        width: '362px',
        paddingBottom: 0,
        paddingTop: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
    filterPopoverInternalContainer: {
        display: 'grid',
        justifyItems: 'center',
        gridRowGap: theme.spacing(1),
        maxHeight: '50vh',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    filterOperatorSelect: {},
    singleFilter: {
        textAlign: 'left',
        width: '320px',
        display: 'flex',
        border: '1px dashed black',
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
    },
    deleteIconContainer: {
        paddingRight: theme.spacing(2),
        visibility: ({ visible }: { visible?: boolean }) =>
            visible ? 'inherit' : 'hidden',
    },
    filterInputsContainer: {
        width: '100%',
    },
}));

interface DeleteIconContainerInterface {
    children: React.ReactNode;
    visible?: boolean;
}

export const DeleteIconContainer = (props: DeleteIconContainerInterface) => {
    const { children } = props;
    const classes = useStyles(props);

    return <div className={classes.deleteIconContainer}>{children}</div>;
};

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Modal, Typography, IconButton } from '@mui/material'
import Box from '../Box'
import { FontAwesomeIcon, fas } from '../../fontAwesome'
import { COLOR_NAMES } from '../../themes/SsgTheme'

const BasicModal = forwardRef((props, ref) => {
    const {
        open,
        onClose,
        title,
        subTitle,
        children,
        isThemed,
        size = 'md',
    } = props

    if (!isThemed) {
        return (
            <Modal
                open={open}
                onClose={onClose}
                ref={ref}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                {...props}
            >
                {children}
            </Modal>
        )
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            ref={ref}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            {...props}
        >
            <Box variant="modal-container" size={size}>
                {/* Modal Header */}
                <Box variant="modal-header-container">
                    <Box variant="modal-title-container">
                        <Typography
                            variant="h5"
                            sx={{ color: COLOR_NAMES.black }}
                        >
                            {title}
                        </Typography>
                        <IconButton
                            onClick={onClose}
                            sx={{ padding: '0px 2px' }}
                        >
                            <FontAwesomeIcon
                                icon={fas.faXmark}
                                style={{ color: COLOR_NAMES.black }}
                            />
                        </IconButton>
                    </Box>
                    {subTitle && (
                        <Box sx={{ paddingTop: '24px' }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '12px',
                                    color: COLOR_NAMES.black,
                                }}
                            >
                                {subTitle}
                            </Typography>
                        </Box>
                    )}
                </Box>

                {/* Modal Body */}
                <Box sx={{ padding: '32px 24px' }}>
                    <Box variant="modal-body-container">{children}</Box>
                </Box>
            </Box>
        </Modal>
    )
})

BasicModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    isThemed: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'auto']),
}

BasicModal.defaultProps = {
    open: undefined,
    onClose: undefined,
    title: undefined,
    subTitle: undefined,
    isThemed: false,
    size: 'md',
}

export default BasicModal

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Button from '../../base/Button/Button'
import TooltipDialog from '../TooltipDialog/TooltipDialog'

const ButtonTooltipDialog = forwardRef((props, ref) => {
    const {
        theme = 'primary',
        text = 'Open',
        title,
        children,
        buttons,
        size,
    } = props

    return (
        <TooltipDialog
            title={title}
            size={size}
            buttons={buttons}
            children={children}
            ref={ref}
            renderTrigger={(handleOpen) => (
                <Button onClick={handleOpen} theme={theme}>
                    {text}
                </Button>
            )}
        />
    )
})

ButtonTooltipDialog.propTypes = {
    theme: PropTypes.oneOf([
        'primary',
        'secondary',
        'accept',
        'progress',
        'reject',
        'info',
        'link',
        'delete',
        'grid-link',
    ]),
    text: PropTypes.element,
    title: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    buttons: PropTypes.element,
}

export default ButtonTooltipDialog

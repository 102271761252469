import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    noDataTd: {
        backgroundColor: theme.palette.grey[300],
        lineHeight: theme.spacing(1),
        textAlign: 'center',
    },
    loaderCell: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        overflow: 'hidden',
    },
}));

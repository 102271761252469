import styled from 'styled-components'
import { Typography } from '../../base'

export const pageSizeDropdownStyle = {
    '& .MuiInputBase-input': {
        padding: '5px 50px 5px 15px',
        backgroundColor: 'white',
    },
}

export const pageSizeWrapper = {
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
}

export const pageSizeSpan = {
    paddingRight: '10px',
    fontWeight: '600',
    color: '#333333',
}

export const PaginationDiv = styled.div`
    height: 3rem;
    display: flex;
    align-items: center;
`

export const PaginationContainer = styled(Typography)`
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-size: 12px;
    color: #333333;
    padding-right: 10px;
    font-weight: 400;
`

export const ActionButton = styled.span`
    font-size: 12px;
    color: ${(props) => (props.disabled ? '#DEDEE0' : '#333333')};
    padding-right: 10px;
    font-weight: 400;

    &:hover {
        cursor: ${(props) => (props.disabled ? '' : 'pointer')};
    }
`

import { useState } from 'react';
import {
    FiltersType,
    FieldFiltersInterface,
    SetFiltersFunction,
    ClearFiltersFunction,
} from 'types';

type FiltersReturn = [FiltersType, SetFiltersFunction, ClearFiltersFunction];

/**
 * Use Filters
 * @param defaultFilters  A default set of filters to use
 */
export const useFilters = (defaultFilters: FiltersType = {}): FiltersReturn => {
    const [filters, setAllFilters] = useState(defaultFilters);

    const setFilters = (field: string, fieldFilters: FieldFiltersInterface) => {
        const newFilters = {
            ...filters,
            [field]: fieldFilters,
        };
        if (fieldFilters === null || fieldFilters.filters.length === 0) {
            delete newFilters[field];
        }
        // @ts-ignore
        setAllFilters(newFilters);
    };

    const clearFilters = () => {
        setAllFilters({});
    };

    return [filters, setFilters, clearFilters];
};

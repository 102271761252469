import React, { useContext } from 'react';
import { MaterialDataGridContext } from 'components/MaterialDataGrid';
import { MaterialDataGridTableHeaderNoContext } from './MaterialDataGridTableHeaderNoContext';
import { HeaderProps } from './types';

export const HeaderWithContext = ({ children, ...props }: HeaderProps) => {
    const dataGrid = useContext(MaterialDataGridContext);

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        // @ts-ignore
        <MaterialDataGridTableHeaderNoContext dataGrid={dataGrid} {...props}>
            {children}
        </MaterialDataGridTableHeaderNoContext>
    );
    /* eslint-enable react/jsx-props-no-spreading */
};

import React from 'react'
import { Tooltip as MaterialTooltip } from '@mui/material'

function Tooltip(props) {
    const {
        isThemed,
        title,
        placement,
        children,
    } = props;

    if (!isThemed) {
        return <MaterialTooltip {...props} />
    }

    return (
        <MaterialTooltip
            isThemed={isThemed}
            title={title}
            placement={placement}
            arrow
        >
            { children }
        </MaterialTooltip>
    );
}

export default Tooltip

import React, { Component } from 'react'
import DropdownTextField from '../../../../base/TextField/variants/DropdownTextField'
import AgGridFilterModelBuilder from '../../utils/models/AgGridFilterModelBuilder'
import AgGridFilterConstants from '../../constants/AgGridFilterConstants'

class BooleanDropDownFilter extends Component {
    constructor(props) {
        super(props)

        const trueLabel = props.trueLabel ?? 'Yes'
        const falseLabel = props.falseLabel ?? 'No'
        const emptyLabel = props.emptyLabel ?? ''

        this.emptyLabel = emptyLabel

        this.state = {
            options: [
                {
                    value: true,
                    label: trueLabel,
                },
                {
                    value: false,
                    label: falseLabel,
                },
            ],
            selectedValue: '',
        }
    }

    // Return true if the filter is active. If active then 1) the grid will show the filter icon in the column
    // header and 2) the filter will be included in the filtering of the data.
    isFilterActive = () => {
        const filterActive =
            this.state.selectedValue !== null &&
            this.state.selectedValue !== undefined &&
            this.state.selectedValue !== ''

        return filterActive
    }

    // The grid will ask each active filter, in turn, whether each row in the grid passes. If any
    // filter fails, then the row will be excluded from the final set. A params object is supplied
    // containing attributes of node (the rowNode the grid creates that wraps the data) and data (the data
    // object that you provided to the grid for that row).
    doesFilterPass = (params) => {
        const filterPassed =
            this.state.selectedValue === null ||
            this.state.selectedValue === undefined ||
            this.state.selectedValue === '' ||
            params.value === this.state.selectedValue

        return this.state.selectedValue === params.value
    }

    // Gets the filter state. If filter is not active, then should return null/undefined.
    // The grid calls getModel() on all active filters when gridApi.getFilterModel() is called.
    getModel = () => {
        if (this.state.selectedValue === '') return undefined

        const modelBuilder = new AgGridFilterModelBuilder()

        modelBuilder.setFilterType(AgGridFilterConstants.CONDITION_EQUALS)
        modelBuilder.setType(AgGridFilterConstants.FILTER_TYPE_BOOLEAN)
        modelBuilder.setFilter(this.state.selectedValue)

        return modelBuilder.build()
    }

    setModel = (model, param1, param2) => {
        if (model === null) {
            this.setSelectedValue('')
            return
        }
    }

    setSelectedValue = (selectedValue) => {
        this.setState(
            {
                selectedValue,
            },
            () => this.props.filterChangedCallback()
        )
    }

    onChange = (event) => {
        this.setSelectedValue(event.target.value)
    }

    render = () => {
        return (
            <DropdownTextField
                {...this.props}
                displayEmpty
                emptyLabel={this.emptyLabel}
                value={this.state.selectedValue}
                onChange={this.onChange}
                options={this.state.options}
            />
        )
    }
}

export default BooleanDropDownFilter

import React, { useState } from 'react'
import {
    MenuItem,
    MenuList,
    Tabs,
    Tab,
    Box,
    Typography,
    Link,
    ImageList,
    ImageListItem,
    Popper,
    Paper,
} from '@mui/material'
import { COLOR_NAMES } from '../../../themes'

// Component to render the navigation bar menu dropdowns
function HeaderNavTabs({ navLinks }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [value, setValue] = useState(0)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuOpen = (index, event) => {
        setAnchorEl({ [index]: event.currentTarget })
        setValue(index)
    }

    function renderTabs() {
        return navLinks.map((item, index) => (
            <Tab
                key={index}
                label={item.heading}
                onMouseEnter={(event) => handleMenuOpen(index, event)}
                sx={{
                    zIndex: '100',
                    padding: '25px',
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    color: '#333333',
                    lineHeight: '16px',
                }}
            />
        ))
    }

    function mapLinks() {
        return navLinks.map(
            (item, index) =>
                item.subMenus && (
                    <Popper
                        open={(anchorEl && Boolean(anchorEl[index])) || false}
                        anchorEl={anchorEl && anchorEl[index]}
                        sx={{ zIndex: 1305 }}
                        placement={'bottom-start'}
                        key={index}
                    >
                        <Paper>
                            <MenuList
                                key={index}
                                id={item.heading}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{ width: '100%' }}
                            >
                                <ImageList
                                    // Based on the number of subMenuLists length adjust the row height and column numbers
                                    cols={
                                        item.subMenus.length > 2
                                            ? 3
                                            : item.subMenus.length
                                    }
                                    rowHeight={
                                        item.subMenus.length > 1 &&
                                        item.heading === 'Tools'
                                            ? 300
                                            : 'auto'
                                    }
                                    gap={0}
                                >
                                    {item.subMenus.map(
                                        (subHeading, subHeadingIndex) => {
                                            if (subHeading) {
                                                return (
                                                    <ImageListItem
                                                        key={subHeadingIndex}
                                                    >
                                                        <Box
                                                            sx={{
                                                                padding:
                                                                    '5px 10px',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        '14px !important',
                                                                    fontWeight:
                                                                        '600 !important',
                                                                    paddingLeft:
                                                                        '6px',
                                                                    color: COLOR_NAMES.black,
                                                                }}
                                                            >
                                                                {
                                                                    subHeading.heading
                                                                }
                                                            </Typography>

                                                            {subHeading.links.map(
                                                                (
                                                                    link,
                                                                    linkIndex
                                                                ) => {
                                                                    return (
                                                                        link && (
                                                                            <Link
                                                                                key={
                                                                                    linkIndex
                                                                                }
                                                                                href={
                                                                                    link.url
                                                                                }
                                                                                underline="none"
                                                                            >
                                                                                <MenuItem
                                                                                    onClick={
                                                                                        handleClose
                                                                                    }
                                                                                    sx={{
                                                                                        color: '#333333',
                                                                                        fontWeight:
                                                                                            '300',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                        width:
                                                                                            item
                                                                                                .subMenus
                                                                                                .length >
                                                                                            1
                                                                                                ? '250px'
                                                                                                : '100%',
                                                                                        whiteSpace:
                                                                                            'break-spaces',
                                                                                        padding:
                                                                                            '6px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        link.heading
                                                                                    }
                                                                                </MenuItem>
                                                                            </Link>
                                                                        )
                                                                    )
                                                                }
                                                            )}
                                                        </Box>
                                                    </ImageListItem>
                                                )
                                            }
                                        }
                                    )}
                                </ImageList>
                            </MenuList>
                        </Paper>
                    </Popper>
                )
        )
    }

    return (
        <Tabs
            value={value}
            onMouseLeave={handleClose}
            sx={{
                '& button:hover': {
                    color: '#007899',
                    borderBottom: 'solid 4px #007899',
                    paddingBottom: '21px !important',
                },
            }}
            TabIndicatorProps={{ sx: { height: 4 } }}
        >
            {renderTabs()}
            {mapLinks()}
        </Tabs>
    )
}

export default HeaderNavTabs

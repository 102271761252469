// Do not remove this style.
// Required because `disablePortal` was removed in the Popper component.
// Without the disablePortal enabled the Popper component and its children
// are not wrapped into the SSGTheme provider.
export const TYPOGRAPHY_STYLE = {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0em',
}

import React from 'react'
import { useState } from 'react'
import {
    Box,
    Drawer,
    Slide,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    IconButton,
    useMediaQuery,
    Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import AppsRoundedIcon from '@mui/icons-material/AppsRounded'
import SidebarDropdown from './MenuButtonDropdown/SidebarDropdown'
import { COLOR_NAMES } from '../../../themes'
import { useAppHeader } from './data-provider'

const AppDrawer = (props) => {
    const { appLogo } = useAppHeader()
    const { navLinks, appLinks, helpLinks, handleCloseSearch } = props
    const [selectedDrawerNavOption, setSelectedDrawerNavOption] = useState(1)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const appLength = navLinks.length + appLinks.length
    const helpLength = appLength + helpLinks.length
    const NavLinksJsx = []
    const AppLinksJsxMobile = []
    const helpLinksJsx = []

    const largeScreens = useMediaQuery('(min-width:1290px)')
    const mediumScreens = useMediaQuery(
        '(min-width: 750px) and (max-width: 1289px)'
    )

    const handleDrawer = () => {
        handleCloseSearch()
        setIsDrawerOpen(!isDrawerOpen)
    }

    if (appLinks.length) {
        // Sets top level app link
        AppLinksJsxMobile.push(
            <Slide
                direction="right"
                in={selectedDrawerNavOption === 1}
                mountOnEnter
                unmountOnExit
                timeout={100}
                key="appLinks_slide"
            >
                <List
                    component="nav"
                    sx={{ marginTop: mediumScreens ? '130px' : 'none' }}
                >
                    <ListItem
                        button
                        onClick={() => setSelectedDrawerNavOption(appLength)}
                    >
                        <AppsRoundedIcon />
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '14px' }}
                            sx={{ paddingLeft: '0.5rem' }}
                        >
                            Apps
                        </ListItemText>
                    </ListItem>
                </List>
            </Slide>
        )

        AppLinksJsxMobile.push(
            <SidebarDropdown
                selectDrawer={selectedDrawerNavOption}
                setSelectDrawer={setSelectedDrawerNavOption}
                length={appLength}
                dropdownOptions={appLinks}
                mediumScreens={mediumScreens}
                menuType={'app'}
                key="appLinks_sidebarDropdown"
            />
        )

        helpLinksJsx.push(
            <Slide
                direction="right"
                in={selectedDrawerNavOption === 1}
                mountOnEnter
                unmountOnExit
                timeout={100}
                key="help_menu"
            >
                <List component="nav">
                    <ListItem
                        button
                        onClick={() => setSelectedDrawerNavOption(helpLength)}
                    >
                        <HelpRoundedIcon sx={{ color: '#444' }} />
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '14px' }}
                            sx={{ paddingLeft: '0.5rem' }}
                        >
                            Help
                        </ListItemText>
                    </ListItem>
                </List>
            </Slide>
        )

        helpLinksJsx.push(
            <SidebarDropdown
                selectDrawer={selectedDrawerNavOption}
                setSelectDrawer={setSelectedDrawerNavOption}
                length={helpLength}
                dropdownOptions={helpLinks}
                mediumScreens={mediumScreens}
                menuType={'help'}
                key="help_sidebarDropdown"
            />
        )
    }

    return (
        <>
            <IconButton
                size="large"
                edge="end"
                aria-label="logo"
                onClick={handleDrawer}
                sx={{ marginLeft: '0.5rem' }}
            >
                {largeScreens ? <AppsRoundedIcon /> : <MenuIcon />}
            </IconButton>
            <Drawer
                anchor={'left'}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                PaperProps={{
                    style: { minWidth: '200px' },
                }}
            >
                {navLinks.map((item, index) => {
                    // Sets top level nav links on mobile
                    NavLinksJsx.push(
                        <Slide
                            direction="right"
                            in={selectedDrawerNavOption === 1}
                            mountOnEnter
                            unmountOnExit
                            timeout={100}
                            key={'menu item heading ' + index}
                        >
                            <List
                                component="nav"
                                sx={{
                                    marginTop: index === 0 ? '75px' : 'none',
                                }}
                            >
                                <ListItem
                                    button
                                    onClick={() =>
                                        setSelectedDrawerNavOption(index + 2)
                                    }
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: '14px',
                                        }}
                                    >
                                        {item.heading}
                                    </ListItemText>
                                    <ChevronRightRoundedIcon />
                                </ListItem>
                            </List>
                        </Slide>
                    )

                    NavLinksJsx.push(
                        <Slide
                            direction="right"
                            in={selectedDrawerNavOption === index + 2}
                            mountOnEnter
                            unmountOnExit
                            timeout={100}
                            key={'menu item list ' + index}
                        >
                            <List component="nav">
                                <ListItem
                                    button
                                    onClick={() =>
                                        setSelectedDrawerNavOption(1)
                                    }
                                    sx={{ marginTop: '60px' }}
                                >
                                    <ChevronLeftRoundedIcon />
                                    <ListItemText>Main Menu</ListItemText>
                                </ListItem>
                                <div>
                                    {/* Sets sub menu nav links */}
                                    {item.subMenus &&
                                        item.subMenus.map(
                                            (subMenu, subMenuIndex) => {
                                                if (
                                                    subMenu !== false &&
                                                    !subMenu.hiddenOnMobile
                                                ) {
                                                    // We apply a Box wrapper for all sub menus aside from the first one
                                                    if (subMenuIndex === 0) {
                                                        return (
                                                            <div
                                                                key={
                                                                    subMenu.heading
                                                                }
                                                            >
                                                                {subMenu.heading && (
                                                                    <ListSubheaderStyled>
                                                                        {
                                                                            subMenu.heading
                                                                        }
                                                                    </ListSubheaderStyled>
                                                                )}
                                                                {subMenu.links.map(
                                                                    (
                                                                        subMenuLink
                                                                    ) => {
                                                                        if (
                                                                            subMenuLink &&
                                                                            !subMenuLink.hiddenOnMobile
                                                                        ) {
                                                                            return (
                                                                                <ListItem
                                                                                    key={
                                                                                        subMenuLink.heading
                                                                                    }
                                                                                    button
                                                                                    component="a"
                                                                                    href={
                                                                                        subMenuLink.url
                                                                                    }
                                                                                >
                                                                                    <ListItemText
                                                                                        primaryTypographyProps={{
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            subMenuLink.heading
                                                                                        }
                                                                                    </ListItemText>
                                                                                </ListItem>
                                                                            )
                                                                        }
                                                                    }
                                                                )}
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <Box
                                                                mt={2}
                                                                key={
                                                                    subMenu.heading
                                                                }
                                                            >
                                                                {subMenu.heading && (
                                                                    <ListSubheaderStyled>
                                                                        {
                                                                            subMenu.heading
                                                                        }
                                                                    </ListSubheaderStyled>
                                                                )}
                                                                {subMenu.links.map(
                                                                    (
                                                                        subMenuLink
                                                                    ) => {
                                                                        if (
                                                                            subMenuLink &&
                                                                            !subMenuLink.hiddenOnMobile
                                                                        ) {
                                                                            return (
                                                                                <ListItem
                                                                                    key={
                                                                                        subMenuLink.heading
                                                                                    }
                                                                                    button
                                                                                    component="a"
                                                                                    href={
                                                                                        subMenuLink.url
                                                                                    }
                                                                                >
                                                                                    <ListItemText
                                                                                        primaryTypographyProps={{
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            subMenuLink.heading
                                                                                        }
                                                                                    </ListItemText>
                                                                                </ListItem>
                                                                            )
                                                                        }
                                                                    }
                                                                )}
                                                            </Box>
                                                        )
                                                    }
                                                }
                                            }
                                        )}
                                </div>
                            </List>
                        </Slide>
                    )
                })}
                {largeScreens ? (
                    <List component="nav">
                        <Typography
                            variant="h5"
                            style={{
                                margin: '20px',
                                paddingTop: largeScreens ? '60px' : '120px',
                                fontfamily: 'Roboto',
                                color: '#17274d',
                                fontWeight: '300',
                                fontSize: '21px',
                            }}
                        >
                            StepStone Apps
                        </Typography>
                        <div>
                            {appLinks.map((appLink) => {
                                return (
                                    <ListItem
                                        key={appLink.heading || appLink.title}
                                        button
                                        component="a"
                                        href={appLink.url || appLink.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {!appLogo && (
                                            <img
                                                src={appLogo}
                                                width="30"
                                                height="30"
                                                alt={
                                                    appLink.heading ||
                                                    appLink.title
                                                }
                                            />
                                        )}
                                        <ListItemText
                                            sx={{
                                                paddingLeft: '1rem',
                                                color: COLOR_NAMES.black,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: '300',
                                                    fontfamily: 'Roboto',
                                                }}
                                            >
                                                {appLink.heading ||
                                                    appLink.title}
                                            </span>
                                        </ListItemText>
                                    </ListItem>
                                )
                            })}
                        </div>
                    </List>
                ) : (
                    <>
                        {!mediumScreens && NavLinksJsx}
                        {AppLinksJsxMobile}
                        {helpLinksJsx}
                    </>
                )}
            </Drawer>
        </>
    )
}

const ListSubheaderStyled = styled(ListSubheader)`
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
`

export default AppDrawer

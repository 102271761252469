import React, { useContext, useState } from 'react'
import { ListItemButton, Grid } from '@mui/material'
import { FilterList } from '../styles'
import { useUser } from '../data-provider'
import { APP_THEME, COLOR_NAMES } from '../../../../themes'

const SearchFilters = (props) => {
    const {
        resultsFilter,
        setResultsFilter,
        updateResultSwitch,
        setUpdateResultSwitch,
    } = props

    const labels = {
        All: 'All',
        Company: 'Company',
        Clients: 'Clients',
        'Co-Investment': 'Co-Investment',
        Fund: 'Fund',
        GP: 'GP',
        Institutions: 'Institutions',
        'Third-Party-Providers': 'Third-Party Providers',
        Intermediaries: 'Intermediaries',
        Mandates: 'Mandates',
        Person: 'Person',
        Secondary: 'Secondary',
    }

    const { isExternalUser } = useUser()

    const [selectedIndex, setSelectedIndex] = useState(0)

    const handleFilterChange = (event, index) => {
        setUpdateResultSwitch(!updateResultSwitch)
        setSelectedIndex(index)
        setResultsFilter(event.currentTarget.getAttribute('value'))
    }

    return (
        <Grid container direction="column" alignItems="flex-end">
            <FilterList>
                {Object.keys(labels).map((key, index) => {
                    const filterLabel = (
                        <ListItemButton
                            selected={selectedIndex === index}
                            key={index}
                            button
                            value={key}
                            onClick={(event) =>
                                handleFilterChange(event, index)
                            }
                            sx={APP_THEME.typography.h5}
                        >
                            <span
                                style={{
                                    color:
                                        resultsFilter === key
                                            ? COLOR_NAMES.blue
                                            : COLOR_NAMES.black,
                                }}
                            >
                                {labels[key]}
                            </span>
                        </ListItemButton>
                    )

                    // Check if user is external and render specific labels
                    if (
                        [
                            'Clients',
                            'Co-Investment',
                            'Institutions',
                            'Third-Party-Providers',
                            'Mandates',
                            'Person',
                            'Secondary',
                        ].includes(key)
                    ) {
                        return !isExternalUser && filterLabel
                    } else {
                        return filterLabel
                    }
                })}
            </FilterList>
        </Grid>
    )
}

export default SearchFilters

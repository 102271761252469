import BooleanDropDownFilter from '../../components/Filters/BooleanDropdownFilter'
import AgGridFilterConstants from '../../constants/AgGridFilterConstants'
import AgGridBaseFilter from './AgGridBaseFilter'

class AgGridCustomBooleanDropdownFilter extends AgGridBaseFilter {
    getFilter() {
        return BooleanDropDownFilter
    }

    isCustomFilter() {
        return true
    }

    getFilterType() {
        return AgGridFilterConstants.FILTER_BOOLEAN_DROPDOWN
    }
}

export default AgGridCustomBooleanDropdownFilter

import React from 'react'
import { Grid } from '@mui/material'
import { coInvestmentPropType } from './CoinvestTypes'
import {
    EntityTypeText,
    LinkWrapper,
    EntityDescText,
    LinkStyled,
} from '../styles'

function CoinvestGridDisplay({ coinvest }) {
    if (!coinvest) {
        return <></>
    }

    const {
        date_received: dateReceived,
        coinvest_id: coinvestId,
        project_name: projectName,
    } = coinvest

    const date = new Date(dateReceived)
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    })

    const [{ value: month }, , { value: day }, , { value: year }] =
        dateTimeFormat.formatToParts(date)
    const dateFormatted = `${year}-${month}-${day}`

    return (
        <Grid container>
            <LinkWrapper item container xs={9}>
                <EntityTypeText>Co-Investment: </EntityTypeText>
                <LinkStyled
                    href={`/coinvest_profile.php?id=${coinvestId}`}
                    title={`Go to Coinvest Profile (Coinvest ID ${coinvestId})`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {projectName}
                </LinkStyled>
            </LinkWrapper>
            <Grid item container xs={3} justifyContent="flex-end">
                <EntityDescText> (Received {dateFormatted})</EntityDescText>
            </Grid>
        </Grid>
    )
}

CoinvestGridDisplay.propTypes = {
    coinvest: coInvestmentPropType.isRequired,
}

export default CoinvestGridDisplay

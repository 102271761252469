import AgGridFilterConstants from '../constants/AgGridFilterConstants'

class AgGridConditionalOptionBuilder {
    constructor() {
        this.defaultType = null
        this.defaultFilter = null
        this.optionList = []
    }

    reset() {
        this.label = ''
        this.key = ''
        this.condition = ''
        this.filter = ''
        this.type = ''
    }

    setLabel(label) {
        this.label = label
        this.key = label
        return this
    }

    setCondition(condition) {
        this.validateCondition(condition)

        this.condition = condition
        return this
    }

    setFilter(filter) {
        this.filter = filter
        return this
    }

    setType(type) {
        this.validateType(type)
        this.type = type
        return this
    }

    buildOption() {
        this.optionList.push({
            label: this.label,
            key: this.key,
            type: this.defaultType ?? this.type,
            condition: this.condition,
            filter: this.defaultFilter ?? this.filter,
        })

        this.reset()
    }

    getOptionList() {
        return this.optionList
    }

    setDefaultType(defaultType) {
        this.validateType(defaultType)

        this.defaultType = defaultType
        return this
    }

    setDefaultFilter(defaultFilter) {
        this.defaultFilter = defaultFilter
        return this
    }

    validateType(type) {
        switch (type) {
            case AgGridFilterConstants.FILTER_TYPE_BOOLEAN:
            case AgGridFilterConstants.FILTER_TYPE_DATE:
            case AgGridFilterConstants.FILTER_TYPE_NUMBER:
            case AgGridFilterConstants.FILTER_TYPE_TEXT:
                break
            default:
                throw new Error(
                    `AgGridConditionalOptionBuilder: Filter type ${type} not supported. Use AgGridFilterConstants.FILTER_TYPE_[TYPE] If you think this is a mistake, please contact the development team.`
                )
        }
    }

    validateCondition(condition) {
        switch (condition) {
            case AgGridFilterConstants.CONDITION_EQUALS:
            case AgGridFilterConstants.CONDITION_NOT_EQUALS:
            case AgGridFilterConstants.CONDITION_GREATER_THAN:
            case AgGridFilterConstants.CONDITION_GREATER_THAN_OR_EQUAL:
            case AgGridFilterConstants.CONDITION_LESS_THAN:
            case AgGridFilterConstants.CONDITION_LESS_THAN_OR_EQUAL:
            case AgGridFilterConstants.CONDITION_WHERE_IN:
            case AgGridFilterConstants.CONDITION_BLANK:
            case AgGridFilterConstants.CONDITION_NOT_BLANK:
            case AgGridFilterConstants.CONDITION_IN_RANGE:
            case AgGridFilterConstants.CONDITION_STARTS_WITH:
            case AgGridFilterConstants.CONDITION_ENDS_WITH:
            case AgGridFilterConstants.CONDITION_CONTAINS:
            case AgGridFilterConstants.CONDITION_NOT_CONTAINS:
                break
            default:
                throw new Error(
                    `AgGridConditionalOptionBuilder: Filter condition ${condition} not supported. Use AgGridFilterConstants.CONDITION_[CONDITION] If you think this is a mistake, please contact the development team.`
                )
        }
    }
}

export default AgGridConditionalOptionBuilder

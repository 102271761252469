import React, { cloneElement } from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import LoadingContainer from '../layouts/LoadingContainer'
import { Typography } from '../../base'
import {
    BoxSectionStyle,
    ButtonSpanStyle,
    HeaderDivStyle,
    HeaderSpanStyle,
    MainContainerStyle,
} from './style'

export default function Section({ actionBar, children, loading, title }) {
    const actionItems = actionBar.map((component, index) => {
        const { disabled } = component.props
        return cloneElement(component, {
            disabled: disabled || loading,
            key: index,
        })
    })

    return (
        <Box sx={MainContainerStyle}>
            <Box sx={HeaderDivStyle}>
                {title && (
                    <Typography variant="h3" sx={HeaderSpanStyle}>
                        {title}
                    </Typography>
                )}
                <span style={ButtonSpanStyle}>{actionItems}</span>
            </Box>
            {children && (
                <Box sx={BoxSectionStyle}>
                    <LoadingContainer loading={loading}>
                        {children}
                    </LoadingContainer>
                </Box>
            )}
        </Box>
    )
}

Section.defaultProps = {
    loading: false,
    actionBar: [],
    children: undefined,
}

Section.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    actionBar: PropTypes.arrayOf(PropTypes.element),
    children: PropTypes.node,
}

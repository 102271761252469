import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

const formatDate = (date, dateFormat = 'MM-dd-yyyy') => {
    if (!date) return ''

    let dateObject

    try {
        dateObject = new Date(date)
    } catch (e) {
        console.error(`formatDate: Error parsing date: ${date}, error: ${e}`)
        return ''
    }
    const isValidDate =
        dateObject instanceof Date && !isNaN(dateObject.getTime())

    if (!isValidDate) return ''

    const timeZone = 'America/Los_Angeles'

    const zonedDate = zonedTimeToUtc(dateObject, timeZone)

    // date-fns uses a different date format than dayjs,
    //This is the error you get if you don't do this:
    //Use `dd` instead of `DD` (in `MM/DD/yyyy`) for formatting days of the month to the input;
    //see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md

    // The /g flag is a global search for all instances of the character. e.g /D/g will find all instances of D in the string.
    const dateFnsDateFormat = dateFormat
        .replace(/D/g, 'd') //Lowercase all D's
        .replace(/Y/g, 'y') //Lowercase all Y's

    return format(zonedDate, dateFnsDateFormat)
}

export default formatDate

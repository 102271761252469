import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../base/Button/Button'
import IconButton from '../../base/IconButton/IconButton'
import Dialog from '../../base/Dialog/Dialog'
import DialogTitle from '../../base/DialogTitle/DialogTitle'
import DialogActions from '../../base/DialogActions/DialogActions'
import DialogContent from '../../base/DialogContent/DialogContent'
import DialogContentText from '../../base/DialogContentText/DialogContentText'
import Box from '../../base/Box/Box'
import { FontAwesomeIcon, fas } from '../../fontAwesome'
import { COLOR_NAMES, COLOR_STONE } from '../../themes/SsgTheme'
import { DialogTitleStyle } from './styles'

export const dialogSizes = {
    sm: '400px',
    md: '580px',
    lg: '865px',
    xl: '1150px',
}

const TooltipDialog = forwardRef((props, ref) => {
    const {
        title,
        children,
        buttons,
        icon,
        size = 'md',
        renderTrigger = null,
    } = props

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <>
            {renderTrigger ? (
                renderTrigger(handleOpen)
            ) : (
                <IconButton
                    onClick={handleOpen}
                    sx={{
                        padding: '0px',
                        marginLeft: '4px',
                        verticalAlign: 'sub',
                    }}
                >
                    <FontAwesomeIcon
                        icon={fas[icon || 'faQuestionCircle']}
                        color={COLOR_NAMES.blue}
                        style={{ fontSize: '14px' }}
                    />
                </IconButton>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                PaperProps={{ style: { width: dialogSizes[size] } }}
                maxWidth={false}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItem: 'center',
                        padding: '32px 24px 24px 24px',
                        borderBottom: `1px solid ${COLOR_NAMES.secondaryLightGray}`,
                    }}
                >
                    <DialogTitle sx={DialogTitleStyle}>{title}</DialogTitle>
                    <IconButton
                        onClick={handleClose}
                        sx={{ padding: '0px 2px' }}
                    >
                        <FontAwesomeIcon
                            icon={fas.faXmark}
                            style={{ color: COLOR_NAMES.black }}
                        />
                    </IconButton>
                </Box>

                <DialogContent
                    dividers={scroll === 'paper'}
                    sx={{ pt: '32px' }}
                >
                    <DialogContentText ref={ref}>{children}</DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '32px 24px 32px 24px' }}>
                    {buttons ? (
                        buttons
                    ) : (
                        <Button
                            onClick={handleClose}
                            sx={{
                                color: COLOR_STONE[50],
                                backgroundColor: COLOR_STONE[800],
                                minWidth: '88px',
                                minHeight: '38px',
                                fontSize: '12px',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: COLOR_STONE[900],
                                },
                            }}
                        >
                            Close
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
})

TooltipDialog.propTypes = {
    title: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    icon: PropTypes.string,
    buttons: PropTypes.element,
}

TooltipDialog.defaultProps = {
    title: '',
    size: 'md',
    icon: 'faQuestionCircle',
    buttons: undefined,
}

export default TooltipDialog

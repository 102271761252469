import { COLOR_NAMES } from '../../themes'

export const MainContainerStyle = {
    border: `1px solid ${COLOR_NAMES.secondaryLightGray}`,
    borderRadius: '0.25rem',
    backgroundColor: 'white',
    padding: '0px',
}

export const HeaderDivStyle = {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    padding: '9px 24px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${COLOR_NAMES.secondaryLightGray}`,
}

export const HeaderSpanStyle = {
    fontFamily: '"Open Sans", Roboto, sans-serif',
    fontWeight: '600',
    color: '#333333',
    lineHeight: '24px',
    fontSize: '16px',
}

export const ButtonSpanStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: '1',
    gap: '8px',
}

export const BoxSectionStyle = {
    padding: '24px',
    border: 'none',
}

import React from 'react'
import MenuItemBase from '../../../../../../base/MenuItemBase/MenuItemBase'
import { APP_THEME, SSG_FONT_FAMILY } from '../../../../../../themes'
import { useFunction, useRedirect } from '../../../projects'

const MenuOption = (props) => {
    const { text, href, onClick } = props
    const redirect = useRedirect()
    const { execute } = useFunction(onClick)
    const handeClick = (event) => {
        if (onClick) {
            return execute(event, props)
        }
        return redirect(href)
    }
    return (
        <MenuItemBase
            onClick={handeClick}
            sx={{
                ...APP_THEME.typography.body_3,
                fontFamily: SSG_FONT_FAMILY,
                padding: '10px 0',
                textWrap: 'wrap',
            }}
        >
            {text}
        </MenuItemBase>
    )
}

export default MenuOption

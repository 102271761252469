import React from 'react'
import { Grid } from '@mui/material'
import { clientPropTypes } from './ClientTypes'
import { LinkWrapper, EntityTypeText, LinkStyled } from '../styles'

function ClientGridDisplay({ client, includeFullName }) {
    if (!client) {
        return <></>
    }

    const {
        Client_Name: clientName,
        Client_FullName: fullName,
        ClientID: clientId,
        resource_uri: resourceUri,
    } = client || {}

    const displayName = includeFullName
        ? `${fullName} (${clientName})`
        : clientName

    return (
        <Grid container>
            <LinkWrapper item container xs={12}>
                <EntityTypeText>Client: </EntityTypeText>
                <LinkStyled
                    href={resourceUri}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Go to Client Profile (Client ID ${clientId})`}
                >
                    {displayName}
                </LinkStyled>
            </LinkWrapper>
        </Grid>
    )
}

ClientGridDisplay.propTypes = {
    client: clientPropTypes,
}

ClientGridDisplay.defaultProps = {
    client: null,
}

export default ClientGridDisplay

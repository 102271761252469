import AgGridFilterFactory from './filters/AgGridFilterFactory'
import { fas, icon } from '../../../fontAwesome'

const iconObject = fas.faCircleInfo
const renderedIcon = icon(iconObject, {
    attributes: {
        height: 20,
        width: 20,
    },
})

const svg = renderedIcon.html.join('')

export default class AgGridColumnDefinitionBuilder {
    constructor(columnDefs) {
        this.columnDefinitions = columnDefs
    }

    build() {
        return this.columnDefinitions.map((columnDefinition) => {
            let newColumnDefinition = columnDefinition

            newColumnDefinition =
                this.buildBaseColumnDefinition(newColumnDefinition)

            if (columnDefinition.filter) {
                const filterStrategy =
                    AgGridFilterFactory.makeFilter(newColumnDefinition)

                newColumnDefinition = filterStrategy.build()
            }

            return newColumnDefinition
        })
    }

    buildBaseColumnDefinition(newColumnDefinition) {
        return {
            ...newColumnDefinition,
            headerComponentParams: {
                template: this.getTemplate(newColumnDefinition),
            },
        }
    }

    getTemplate(newColumnDefinition) {
        if (newColumnDefinition.template) return newColumnDefinition.template

        const hasTooltip = newColumnDefinition.headerTooltip !== undefined
        const tooTipIconHtml = hasTooltip ? `&nbsp ${svg}` : ''

        return `
            <div class="ag-cell-label-container" role="presentation">
                <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                    ${tooTipIconHtml}
                </div>
            </div>
        `
    }
}

import React from 'react';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import { MaterialDataGridInterface, Size } from 'types';
import { ContextPagination } from './ContextPagination';
import { NoContextPagination } from './NoContextPagination';

interface Props {
    appendRows?: React.ReactNode[];
    colSpan: number;
    dataGrid?: MaterialDataGridInterface;
    disablePerPage?: boolean;
    loading?: boolean;
    noResultsText?: string;
    prependRows?: React.ReactNode[];
    size?: Size;
    noContext?: boolean;
}

export const MaterialDataGridPagination = ({
    appendRows = [],
    colSpan,
    dataGrid,
    disablePerPage = false,
    loading = false,
    noResultsText = 'No Results',
    prependRows = [],
    size = 'small',
    noContext = false,
}: Props) => (
    <TableFooter>
        {prependRows}
        <TableRow>
            <TableCell colSpan={colSpan}>
                {noContext ? (
                    <NoContextPagination
                        disablePerPage={disablePerPage}
                        loading={loading}
                        noResultsText={noResultsText}
                        size={size}
                        dataGrid={dataGrid}
                    />
                ) : (
                    <ContextPagination
                        disablePerPage={disablePerPage}
                        loading={loading}
                        noResultsText={noResultsText}
                        size={size}
                    />
                )}
            </TableCell>
        </TableRow>
        {appendRows}
    </TableFooter>
);
